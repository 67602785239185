export const playerProfile = {
  player: {
    id: "ddfbc667-95d8-49fc-8e77-a8e4e452d820",
    status: "A",
    position: "IF",
    primary_position: "1B",
    first_name: "Christopher",
    last_name: "Davis",
    preferred_name: "Chris",
    jersey_number: "19",
    full_name: "Chris Davis",
    height: "75",
    weight: "230",
    throw_hand: "R",
    bat_hand: "L",
    college: "Navarro College",
    high_school: "Longview (TX)",
    birthdate: "1986-03-17",
    birthstate: "TX",
    birthcountry: "USA",
    birthcity: "Longview",
    pro_debut: "2008-06-26",
    updated: "2020-01-31T21:37:27+00:00",
    reference: "448801",
    draft: {
      team_id: "d99f919b-1534-4516-8e8a-9cd106c6d8cd",
      year: 2006,
      round: 5,
      pick: 12,
    },
    team: {
      name: "Orioles",
      market: "Baltimore",
      abbr: "BAL",
      id: "75729d34-bca7-4a0f-b3df-6f26c6ad3719",
    },
    seasons: [
      {
        id: "bd17502c-7dff-4f86-980b-df765f895163",
        year: 2020,
        type: "PRE",
        totals: {
          statistics: {
            hitting: {
              overall: {
                ab: 13,
                lob: 4,
                rbi: 9,
                abhr: 4.333,
                abk: 6.5,
                bip: 10,
                babip: 0.4,
                bbk: 4.0,
                bbpa: 0.348,
                iso: 0.693,
                obp: 0.652,
                ops: 1.883,
                seca: 1.308,
                slg: 1.231,
                xbh: 3,
                pitch_count: 59,
                lob_risp_2out: 2,
                team_lob: 3,
                ab_risp: 5,
                hit_risp: 3,
                rbi_2out: 4,
                linedrive: 3,
                groundball: 2,
                popup: 0,
                flyball: 8,
                ap: 23,
                avg: ".538",
                gofo: 0.2,
                onbase: {
                  s: 4,
                  d: 0,
                  t: 0,
                  hr: 3,
                  tb: 16,
                  bb: 8,
                  ibb: 0,
                  hbp: 0,
                  fc: 0,
                  roe: 0,
                  // Hardcoding with the real number until the API is updated with the current season's stats
                  //   h: 7,
                  h: 6,
                  cycle: 0,
                },
                runs: { total: 7 },
                outcome: {
                  klook: 1,
                  kswing: 7,
                  ktotal: 8,
                  ball: 36,
                  iball: 0,
                  dirtball: 0,
                  foul: 2,
                },
                outs: {
                  po: 0,
                  fo: 5,
                  fidp: 0,
                  lo: 0,
                  lidp: 0,
                  go: 1,
                  gidp: 0,
                  klook: 0,
                  kswing: 2,
                  ktotal: 2,
                  sacfly: 2,
                  sachit: 0,
                },
                steal: { caught: 0, stolen: 0, pct: 0, pickoff: 0 },
                pitches: { count: 59, btotal: 36, ktotal: 23 },
                games: { start: 8, play: 8, finish: 0, complete: 0 },
              },
            },
            fielding: {
              overall: {
                po: 33,
                a: 3,
                dp: 7,
                tp: 0,
                error: 1,
                tc: 37,
                fpct: 0.973,
                c_wp: 0,
                pb: 0,
                rf: 4.5,
                inn_1: 117,
                inn_2: 39.0,
                steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                errors: { throwing: 0, fielding: 1, interference: 0, total: 1 },
                assists: { outfield: 0, total: 3 },
                games: { start: 8, play: 8, finish: 0, complete: 0 },
              },
              positions: [
                {
                  po: 33,
                  a: 3,
                  dp: 7,
                  tp: 0,
                  error: 1,
                  tc: 37,
                  fpct: 0.973,
                  c_wp: 0,
                  pb: 0,
                  rf: 4.5,
                  inn_1: 117,
                  inn_2: 39.0,
                  position: "1B",
                  steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                  errors: {
                    throwing: 0,
                    fielding: 1,
                    interference: 0,
                    total: 1,
                  },
                  assists: { outfield: 0, total: 3 },
                  games: { start: 8, play: 8, finish: 0, complete: 0 },
                },
              ],
            },
          },
          splits: {
            hitting: {
              overall: [
                {
                  total: [
                    {
                      ab: 13,
                      runs: 7,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 3,
                      rbi: 9,
                      bb: 8,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.652,
                      slg: 1.231,
                      ops: 1.883,
                      h: 7,
                      ktotal: 2,
                      avg: ".538",
                    },
                  ],
                  day_night: [
                    {
                      value: "day",
                      ab: 13,
                      runs: 7,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 3,
                      rbi: 9,
                      bb: 8,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.652,
                      slg: 1.231,
                      ops: 1.883,
                      h: 7,
                      ktotal: 2,
                      avg: ".538",
                    },
                  ],
                  venue: [
                    {
                      id: "40fe11bc-5323-4306-a375-c706a43eab6e",
                      name: "FITTEAM Ballpark of the Palm Beaches",
                      surface: "grass",
                      ab: 1,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.0,
                      ops: 0.333,
                      h: 0,
                      ktotal: 0,
                      avg: ".000",
                    },
                    {
                      id: "b23440a8-786c-4914-8161-daaf94e101f3",
                      name: "Ed Smith Stadium",
                      surface: "grass",
                      ab: 12,
                      runs: 7,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 3,
                      rbi: 8,
                      bb: 7,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.7,
                      slg: 1.333,
                      ops: 2.033,
                      h: 7,
                      ktotal: 2,
                      avg: ".583",
                    },
                  ],
                  surface: [
                    {
                      value: "grass",
                      ab: 13,
                      runs: 7,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 3,
                      rbi: 9,
                      bb: 8,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.652,
                      slg: 1.231,
                      ops: 1.883,
                      h: 7,
                      ktotal: 2,
                      avg: ".538",
                    },
                  ],
                  month: [
                    {
                      value: "3",
                      ab: 6,
                      runs: 2,
                      s: 2,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 4,
                      bb: 4,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.5,
                      slg: 0.333,
                      ops: 0.833,
                      h: 2,
                      ktotal: 1,
                      avg: ".333",
                    },
                    {
                      value: "2",
                      ab: 7,
                      runs: 5,
                      s: 2,
                      d: 0,
                      t: 0,
                      hr: 3,
                      rbi: 5,
                      bb: 4,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.818,
                      slg: 2.0,
                      ops: 2.818,
                      h: 5,
                      ktotal: 1,
                      avg: ".714",
                    },
                  ],
                  home_away: [
                    {
                      value: "away",
                      ab: 1,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.0,
                      ops: 0.333,
                      h: 0,
                      ktotal: 0,
                      avg: ".000",
                    },
                    {
                      value: "home",
                      ab: 12,
                      runs: 7,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 3,
                      rbi: 8,
                      bb: 7,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.7,
                      slg: 1.333,
                      ops: 2.033,
                      h: 7,
                      ktotal: 2,
                      avg: ".583",
                    },
                  ],
                  pitcher_hand: [
                    {
                      value: "L",
                      ab: 3,
                      runs: 3,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 4,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.667,
                      slg: 1.667,
                      ops: 2.334,
                      h: 2,
                      ktotal: 1,
                      avg: ".667",
                    },
                    {
                      value: "R",
                      ab: 10,
                      runs: 4,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 5,
                      bb: 6,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.647,
                      slg: 1.1,
                      ops: 1.747,
                      h: 5,
                      ktotal: 1,
                      avg: ".500",
                    },
                  ],
                  opponent: [
                    {
                      id: "03556285-bdbb-4576-a06d-42f71f46ddc5",
                      name: "Marlins",
                      market: "Miami",
                      abbr: "MIA",
                      ab: 2,
                      runs: 1,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 3,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.667,
                      slg: 2.0,
                      ops: 2.667,
                      h: 1,
                      ktotal: 1,
                      avg: ".500",
                    },
                    {
                      id: "a09ec676-f887-43dc-bbb3-cf4bbaee9a18",
                      name: "Yankees",
                      market: "New York",
                      abbr: "NYY",
                      ab: 1,
                      runs: 1,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 1.0,
                      slg: 1.0,
                      ops: 2.0,
                      h: 1,
                      ktotal: 0,
                      avg: "1.000",
                    },
                    {
                      id: "bdc11650-6f74-49c4-875e-778aeb7632d9",
                      name: "Rays",
                      market: "Tampa Bay",
                      abbr: "TB",
                      ab: 2,
                      runs: 2,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 2,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.6,
                      slg: 2.0,
                      ops: 2.6,
                      h: 1,
                      ktotal: 0,
                      avg: ".500",
                    },
                    {
                      id: "93941372-eb4c-4c40-aced-fe3267174393",
                      name: "Red Sox",
                      market: "Boston",
                      abbr: "BOS",
                      ab: 1,
                      runs: 1,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 1.0,
                      slg: 1.0,
                      ops: 2.0,
                      h: 1,
                      ktotal: 0,
                      avg: "1.000",
                    },
                    {
                      id: "481dfe7e-5dab-46ab-a49f-9dcc2b6e2cfd",
                      name: "Pirates",
                      market: "Pittsburgh",
                      abbr: "PIT",
                      ab: 3,
                      runs: 2,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.667,
                      slg: 1.667,
                      ops: 2.334,
                      h: 2,
                      ktotal: 0,
                      avg: ".667",
                    },
                    {
                      id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                      name: "Twins",
                      market: "Minnesota",
                      abbr: "MIN",
                      ab: 3,
                      runs: 0,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.333,
                      ops: 0.666,
                      h: 1,
                      ktotal: 1,
                      avg: ".333",
                    },
                    {
                      id: "d89bed32-3aee-4407-99e3-4103641b999a",
                      name: "Nationals",
                      market: "Washington",
                      abbr: "WSH",
                      ab: 1,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.0,
                      ops: 0.333,
                      h: 0,
                      ktotal: 0,
                      avg: ".000",
                    },
                  ],
                },
              ],
            },
          },
        },
        teams: [
          {
            name: "Orioles",
            market: "Baltimore",
            abbr: "BAL",
            id: "75729d34-bca7-4a0f-b3df-6f26c6ad3719",
            statistics: {
              hitting: {
                overall: {
                  ab: 13,
                  lob: 4,
                  rbi: 9,
                  abhr: 4.333,
                  abk: 6.5,
                  bip: 10,
                  babip: 0.4,
                  bbk: 4.0,
                  bbpa: 0.348,
                  iso: 0.693,
                  obp: 0.652,
                  ops: 1.883,
                  seca: 1.308,
                  slg: 1.231,
                  xbh: 3,
                  pitch_count: 59,
                  lob_risp_2out: 2,
                  team_lob: 3,
                  ab_risp: 5,
                  hit_risp: 3,
                  rbi_2out: 4,
                  linedrive: 3,
                  groundball: 2,
                  popup: 0,
                  flyball: 8,
                  ap: 23,
                  avg: ".538",
                  gofo: 0.2,
                  onbase: {
                    s: 4,
                    d: 0,
                    t: 0,
                    hr: 3,
                    tb: 16,
                    bb: 8,
                    ibb: 0,
                    hbp: 0,
                    fc: 0,
                    roe: 0,
                    h: 7,
                    cycle: 0,
                  },
                  runs: { total: 7 },
                  outcome: {
                    klook: 1,
                    kswing: 7,
                    ktotal: 8,
                    ball: 36,
                    iball: 0,
                    dirtball: 0,
                    foul: 2,
                  },
                  outs: {
                    po: 0,
                    fo: 5,
                    fidp: 0,
                    lo: 0,
                    lidp: 0,
                    go: 1,
                    gidp: 0,
                    klook: 0,
                    kswing: 2,
                    ktotal: 2,
                    sacfly: 2,
                    sachit: 0,
                  },
                  steal: { caught: 0, stolen: 0, pct: 0, pickoff: 0 },
                  pitches: { count: 59, btotal: 36, ktotal: 23 },
                  games: { start: 8, play: 8, finish: 0, complete: 0 },
                },
              },
              fielding: {
                overall: {
                  po: 33,
                  a: 3,
                  dp: 7,
                  tp: 0,
                  error: 1,
                  tc: 37,
                  fpct: 0.973,
                  c_wp: 0,
                  pb: 0,
                  rf: 4.5,
                  inn_1: 117,
                  inn_2: 39.0,
                  steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                  errors: {
                    throwing: 0,
                    fielding: 1,
                    interference: 0,
                    total: 1,
                  },
                  assists: { outfield: 0, total: 3 },
                  games: { start: 8, play: 8, finish: 0, complete: 0 },
                },
                positions: [
                  {
                    po: 33,
                    a: 3,
                    dp: 7,
                    tp: 0,
                    error: 1,
                    tc: 37,
                    fpct: 0.973,
                    c_wp: 0,
                    pb: 0,
                    rf: 4.5,
                    inn_1: 117,
                    inn_2: 39.0,
                    position: "1B",
                    steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                    errors: {
                      throwing: 0,
                      fielding: 1,
                      interference: 0,
                      total: 1,
                    },
                    assists: { outfield: 0, total: 3 },
                    games: { start: 8, play: 8, finish: 0, complete: 0 },
                  },
                ],
              },
            },
            splits: {
              hitting: {
                overall: [
                  {
                    total: [
                      {
                        ab: 13,
                        runs: 7,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 3,
                        rbi: 9,
                        bb: 8,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.652,
                        slg: 1.231,
                        ops: 1.883,
                        h: 7,
                        ktotal: 2,
                        avg: ".538",
                      },
                    ],
                    day_night: [
                      {
                        value: "day",
                        ab: 13,
                        runs: 7,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 3,
                        rbi: 9,
                        bb: 8,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.652,
                        slg: 1.231,
                        ops: 1.883,
                        h: 7,
                        ktotal: 2,
                        avg: ".538",
                      },
                    ],
                    venue: [
                      {
                        id: "40fe11bc-5323-4306-a375-c706a43eab6e",
                        name: "FITTEAM Ballpark of the Palm Beaches",
                        surface: "grass",
                        ab: 1,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.0,
                        ops: 0.333,
                        h: 0,
                        ktotal: 0,
                        avg: ".000",
                      },
                      {
                        id: "b23440a8-786c-4914-8161-daaf94e101f3",
                        name: "Ed Smith Stadium",
                        surface: "grass",
                        ab: 12,
                        runs: 7,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 3,
                        rbi: 8,
                        bb: 7,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.7,
                        slg: 1.333,
                        ops: 2.033,
                        h: 7,
                        ktotal: 2,
                        avg: ".583",
                      },
                    ],
                    surface: [
                      {
                        value: "grass",
                        ab: 13,
                        runs: 7,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 3,
                        rbi: 9,
                        bb: 8,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.652,
                        slg: 1.231,
                        ops: 1.883,
                        h: 7,
                        ktotal: 2,
                        avg: ".538",
                      },
                    ],
                    month: [
                      {
                        value: "3",
                        ab: 6,
                        runs: 2,
                        s: 2,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 4,
                        bb: 4,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.5,
                        slg: 0.333,
                        ops: 0.833,
                        h: 2,
                        ktotal: 1,
                        avg: ".333",
                      },
                      {
                        value: "2",
                        ab: 7,
                        runs: 5,
                        s: 2,
                        d: 0,
                        t: 0,
                        hr: 3,
                        rbi: 5,
                        bb: 4,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.818,
                        slg: 2.0,
                        ops: 2.818,
                        h: 5,
                        ktotal: 1,
                        avg: ".714",
                      },
                    ],
                    home_away: [
                      {
                        value: "away",
                        ab: 1,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.0,
                        ops: 0.333,
                        h: 0,
                        ktotal: 0,
                        avg: ".000",
                      },
                      {
                        value: "home",
                        ab: 12,
                        runs: 7,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 3,
                        rbi: 8,
                        bb: 7,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.7,
                        slg: 1.333,
                        ops: 2.033,
                        h: 7,
                        ktotal: 2,
                        avg: ".583",
                      },
                    ],
                    pitcher_hand: [
                      {
                        value: "L",
                        ab: 3,
                        runs: 3,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 4,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.667,
                        slg: 1.667,
                        ops: 2.334,
                        h: 2,
                        ktotal: 1,
                        avg: ".667",
                      },
                      {
                        value: "R",
                        ab: 10,
                        runs: 4,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 5,
                        bb: 6,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.647,
                        slg: 1.1,
                        ops: 1.747,
                        h: 5,
                        ktotal: 1,
                        avg: ".500",
                      },
                    ],
                    opponent: [
                      {
                        id: "03556285-bdbb-4576-a06d-42f71f46ddc5",
                        name: "Marlins",
                        market: "Miami",
                        abbr: "MIA",
                        ab: 2,
                        runs: 1,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 3,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.667,
                        slg: 2.0,
                        ops: 2.667,
                        h: 1,
                        ktotal: 1,
                        avg: ".500",
                      },
                      {
                        id: "a09ec676-f887-43dc-bbb3-cf4bbaee9a18",
                        name: "Yankees",
                        market: "New York",
                        abbr: "NYY",
                        ab: 1,
                        runs: 1,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 1.0,
                        slg: 1.0,
                        ops: 2.0,
                        h: 1,
                        ktotal: 0,
                        avg: "1.000",
                      },
                      {
                        id: "bdc11650-6f74-49c4-875e-778aeb7632d9",
                        name: "Rays",
                        market: "Tampa Bay",
                        abbr: "TB",
                        ab: 2,
                        runs: 2,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 2,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.6,
                        slg: 2.0,
                        ops: 2.6,
                        h: 1,
                        ktotal: 0,
                        avg: ".500",
                      },
                      {
                        id: "93941372-eb4c-4c40-aced-fe3267174393",
                        name: "Red Sox",
                        market: "Boston",
                        abbr: "BOS",
                        ab: 1,
                        runs: 1,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 1.0,
                        slg: 1.0,
                        ops: 2.0,
                        h: 1,
                        ktotal: 0,
                        avg: "1.000",
                      },
                      {
                        id: "481dfe7e-5dab-46ab-a49f-9dcc2b6e2cfd",
                        name: "Pirates",
                        market: "Pittsburgh",
                        abbr: "PIT",
                        ab: 3,
                        runs: 2,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.667,
                        slg: 1.667,
                        ops: 2.334,
                        h: 2,
                        ktotal: 0,
                        avg: ".667",
                      },
                      {
                        id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                        name: "Twins",
                        market: "Minnesota",
                        abbr: "MIN",
                        ab: 3,
                        runs: 0,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.333,
                        ops: 0.666,
                        h: 1,
                        ktotal: 1,
                        avg: ".333",
                      },
                      {
                        id: "d89bed32-3aee-4407-99e3-4103641b999a",
                        name: "Nationals",
                        market: "Washington",
                        abbr: "WSH",
                        ab: 1,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.0,
                        ops: 0.333,
                        h: 0,
                        ktotal: 0,
                        avg: ".000",
                      },
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
      {
        id: "7f533168-b22c-4d8f-9593-0dacb2f0e84a",
        year: 2019,
        type: "REG",
        totals: {
          statistics: {
            hitting: {
              overall: {
                ab: 307,
                lob: 125,
                rbi: 36,
                abhr: 25.583,
                abk: 2.209,
                bip: 159,
                babip: 0.27,
                bbk: 0.281,
                bbpa: 0.111,
                iso: 0.147,
                obp: 0.276,
                ops: 0.602,
                seca: 0.274,
                slg: 0.326,
                xbh: 21,
                pitch_count: 1524,
                lob_risp_2out: 32,
                team_lob: 62,
                ab_risp: 65,
                hit_risp: 15,
                rbi_2out: 15,
                linedrive: 48,
                groundball: 67,
                popup: 5,
                flyball: 51,
                ap: 352,
                avg: ".179",
                gofo: 1.07,
                onbase: {
                  s: 34,
                  d: 9,
                  t: 0,
                  hr: 12,
                  tb: 100,
                  bb: 38,
                  ibb: 1,
                  hbp: 3,
                  fc: 5,
                  roe: 2,
                  h: 55,
                  cycle: 0,
                },
                runs: { total: 26 },
                outcome: {
                  klook: 307,
                  kswing: 233,
                  ktotal: 540,
                  ball: 569,
                  iball: 0,
                  dirtball: 31,
                  foul: 210,
                },
                outs: {
                  po: 5,
                  fo: 33,
                  fidp: 0,
                  lo: 21,
                  lidp: 0,
                  go: 63,
                  gidp: 6,
                  klook: 60,
                  kswing: 79,
                  ktotal: 139,
                  sacfly: 3,
                  sachit: 0,
                },
                steal: { caught: 0, stolen: 0, pct: 0, pickoff: 0 },
                pitches: { count: 1524, btotal: 603, ktotal: 921 },
                games: { start: 86, play: 105, finish: 19, complete: 80 },
              },
            },
            pitching: {
              overall: {
                oba: 0.4,
                lob: 1,
                era: 9.0,
                k9: 9.0,
                whip: 2.0,
                kbb: 0.0,
                pitch_count: 22,
                wp: 0,
                bk: 0,
                ip_1: 3,
                ip_2: 1.0,
                bf: 5,
                gofo: 1.0,
                babip: 0.333,
                bf_ip: 5.0,
                gbfb: 1.0,
                oab: 5,
                slg: 1.2,
                obp: 0.4,
                onbase: {
                  s: 0,
                  d: 1,
                  t: 0,
                  hr: 1,
                  tb: 6,
                  bb: 0,
                  ibb: 0,
                  hbp: 0,
                  fc: 0,
                  roe: 0,
                  h: 2,
                  h9: 18.0,
                  hr9: 9.0,
                },
                runs: {
                  total: 1,
                  unearned: 0,
                  earned: 1,
                  ir: 0,
                  ira: 0,
                  bqr: 0,
                  bqra: 0,
                },
                outcome: {
                  klook: 1,
                  kswing: 1,
                  ktotal: 2,
                  ball: 6,
                  iball: 0,
                  dirtball: 0,
                  foul: 10,
                },
                outs: {
                  po: 0,
                  fo: 0,
                  fidp: 0,
                  lo: 1,
                  lidp: 0,
                  go: 1,
                  gidp: 0,
                  klook: 0,
                  kswing: 1,
                  ktotal: 1,
                  sacfly: 0,
                  sachit: 0,
                },
                steal: { caught: 0, stolen: 0, pickoff: 0 },
                pitches: {
                  count: 22,
                  btotal: 6,
                  ktotal: 16,
                  per_ip: 22.0,
                  per_bf: 4.4,
                },
                in_play: { linedrive: 2, groundball: 1, popup: 0, flyball: 1 },
                games: {
                  start: 0,
                  play: 1,
                  finish: 1,
                  svo: 0,
                  qstart: 0,
                  shutout: 0,
                  complete: 0,
                  win: 0,
                  loss: 0,
                  save: 0,
                  hold: 0,
                  blown_save: 0,
                  team_win: 0,
                  team_loss: 0,
                },
              },
              bullpen: {
                oba: 0.4,
                lob: 1,
                era: 9.0,
                k9: 9.0,
                whip: 2.0,
                kbb: 0.0,
                pitch_count: 22,
                wp: 0,
                bk: 0,
                ip_1: 3,
                ip_2: 1.0,
                bf: 5,
                gofo: 1.0,
                babip: 0.333,
                bf_ip: 5.0,
                gbfb: 1.0,
                oab: 5,
                slg: 1.2,
                obp: 0.4,
                onbase: {
                  s: 0,
                  d: 1,
                  t: 0,
                  hr: 1,
                  tb: 6,
                  bb: 0,
                  ibb: 0,
                  hbp: 0,
                  fc: 0,
                  roe: 0,
                  h: 2,
                  h9: 18.0,
                  hr9: 9.0,
                },
                runs: {
                  total: 1,
                  unearned: 0,
                  earned: 1,
                  ir: 0,
                  ira: 0,
                  bqr: 0,
                  bqra: 0,
                },
                outcome: {
                  klook: 1,
                  kswing: 1,
                  ktotal: 2,
                  ball: 6,
                  iball: 0,
                  dirtball: 0,
                  foul: 10,
                },
                outs: {
                  po: 0,
                  fo: 0,
                  fidp: 0,
                  lo: 1,
                  lidp: 0,
                  go: 1,
                  gidp: 0,
                  klook: 0,
                  kswing: 1,
                  ktotal: 1,
                  sacfly: 0,
                  sachit: 0,
                },
                steal: { caught: 0, stolen: 0, pickoff: 0 },
                pitches: {
                  count: 22,
                  btotal: 6,
                  ktotal: 16,
                  per_ip: 22.0,
                  per_bf: 4.4,
                },
                in_play: { linedrive: 2, groundball: 1, popup: 0, flyball: 1 },
                games: {
                  start: 0,
                  play: 1,
                  finish: 1,
                  svo: 0,
                  qstart: 0,
                  shutout: 0,
                  complete: 0,
                  win: 0,
                  loss: 0,
                  save: 0,
                  hold: 0,
                  blown_save: 0,
                  team_win: 0,
                  team_loss: 0,
                },
              },
            },
            fielding: {
              overall: {
                po: 672,
                a: 37,
                dp: 86,
                tp: 0,
                error: 5,
                tc: 714,
                fpct: 0.993,
                c_wp: 0,
                pb: 0,
                rf: 7.162,
                inn_1: 2335,
                inn_2: 778.1,
                steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                errors: { throwing: 1, fielding: 4, interference: 0, total: 5 },
                assists: { outfield: 0, total: 37 },
                games: { start: 86, play: 99, finish: 13, complete: 0 },
              },
              positions: [
                {
                  po: 0,
                  a: 0,
                  dp: 0,
                  tp: 0,
                  error: 0,
                  tc: 0,
                  fpct: 0.0,
                  c_wp: 0,
                  pb: 0,
                  rf: 0.0,
                  inn_1: 3,
                  inn_2: 1.0,
                  position: "P",
                  steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                  errors: {
                    throwing: 0,
                    fielding: 0,
                    interference: 0,
                    total: 0,
                  },
                  assists: { outfield: 0, total: 0 },
                  games: { start: 0, play: 1, finish: 1, complete: 0 },
                },
                {
                  po: 671,
                  a: 37,
                  dp: 86,
                  tp: 0,
                  error: 4,
                  tc: 712,
                  fpct: 0.994,
                  c_wp: 0,
                  pb: 0,
                  rf: 7.299,
                  inn_1: 2308,
                  inn_2: 769.1,
                  position: "1B",
                  steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                  errors: {
                    throwing: 1,
                    fielding: 3,
                    interference: 0,
                    total: 4,
                  },
                  assists: { outfield: 0, total: 37 },
                  games: { start: 85, play: 97, finish: 12, complete: 0 },
                },
                {
                  po: 1,
                  a: 0,
                  dp: 0,
                  tp: 0,
                  error: 1,
                  tc: 2,
                  fpct: 0.5,
                  c_wp: 0,
                  pb: 0,
                  rf: 1.0,
                  inn_1: 24,
                  inn_2: 8.0,
                  position: "RF",
                  steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                  errors: {
                    throwing: 0,
                    fielding: 1,
                    interference: 0,
                    total: 1,
                  },
                  assists: { outfield: 0, total: 0 },
                  games: { start: 1, play: 1, finish: 0, complete: 0 },
                },
              ],
            },
          },
          splits: {
            hitting: {
              overall: [
                {
                  total: [
                    {
                      ab: 307,
                      runs: 26,
                      s: 34,
                      d: 9,
                      t: 0,
                      hr: 12,
                      rbi: 36,
                      bb: 38,
                      ibb: 1,
                      hbp: 3,
                      sb: 0,
                      cs: 0,
                      obp: 0.276,
                      slg: 0.326,
                      ops: 0.602,
                      h: 55,
                      ktotal: 139,
                      avg: ".179",
                    },
                  ],
                  day_night: [
                    {
                      value: "night",
                      ab: 197,
                      runs: 15,
                      s: 29,
                      d: 3,
                      t: 0,
                      hr: 7,
                      rbi: 23,
                      bb: 20,
                      ibb: 1,
                      hbp: 2,
                      sb: 0,
                      cs: 0,
                      obp: 0.279,
                      slg: 0.32,
                      ops: 0.599,
                      h: 39,
                      ktotal: 83,
                      avg: ".198",
                    },
                    {
                      value: "day",
                      ab: 110,
                      runs: 11,
                      s: 5,
                      d: 6,
                      t: 0,
                      hr: 5,
                      rbi: 13,
                      bb: 18,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.269,
                      slg: 0.336,
                      ops: 0.605,
                      h: 16,
                      ktotal: 56,
                      avg: ".145",
                    },
                  ],
                  venue: [
                    {
                      id: "434aa63c-1049-4a54-a6bc-3790c7772ee1",
                      name: "Oriole Park at Camden Yards",
                      market: "Baltimore",
                      surface: "grass",
                      ab: 160,
                      runs: 12,
                      s: 18,
                      d: 4,
                      t: 0,
                      hr: 5,
                      rbi: 17,
                      bb: 20,
                      ibb: 0,
                      hbp: 2,
                      sb: 0,
                      cs: 0,
                      obp: 0.265,
                      slg: 0.288,
                      ops: 0.553,
                      h: 27,
                      ktotal: 64,
                      avg: ".169",
                    },
                    {
                      id: "302f8dcd-eed6-4b83-8609-81548d51e955",
                      name: "Target Field",
                      market: "Minnesota",
                      surface: "grass",
                      ab: 6,
                      runs: 1,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 0,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.286,
                      slg: 0.667,
                      ops: 0.953,
                      h: 1,
                      ktotal: 3,
                      avg: ".167",
                    },
                    {
                      id: "4a550fc1-c03d-4294-b63a-30ff197bedc4",
                      name: "Guaranteed Rate Field",
                      market: "Chicago",
                      surface: "grass",
                      ab: 9,
                      runs: 1,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 2,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.333,
                      ops: 0.666,
                      h: 3,
                      ktotal: 2,
                      avg: ".333",
                    },
                    {
                      id: "60732da9-ad03-4feb-9a36-aee3e98c7a2b",
                      name: "Angel Stadium of Anaheim",
                      market: "Los Angeles",
                      surface: "grass",
                      ab: 3,
                      runs: 1,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 0.0,
                      ops: 0.25,
                      h: 0,
                      ktotal: 2,
                      avg: ".000",
                    },
                    {
                      id: "f1c03dac-3c0f-437c-a325-8d5702cd321a",
                      name: "T-Mobile Park",
                      market: "Seattle",
                      surface: "grass",
                      ab: 4,
                      runs: 0,
                      s: 2,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.5,
                      slg: 0.5,
                      ops: 1.0,
                      h: 2,
                      ktotal: 1,
                      avg: ".500",
                    },
                    {
                      id: "84d72338-2173-4a90-9d25-99adc6c86f4b",
                      name: "Rogers Centre",
                      market: "Toronto",
                      surface: "turf",
                      ab: 24,
                      runs: 2,
                      s: 3,
                      d: 1,
                      t: 0,
                      hr: 2,
                      rbi: 5,
                      bb: 5,
                      ibb: 1,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.4,
                      slg: 0.542,
                      ops: 0.942,
                      h: 6,
                      ktotal: 11,
                      avg: ".250",
                    },
                    {
                      id: "3aaaf4af-0f8c-49c1-8bf1-1780bb5a5f5c",
                      name: "Tropicana Field",
                      market: "Tampa Bay",
                      surface: "turf",
                      ab: 9,
                      runs: 3,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.5,
                      slg: 0.778,
                      ops: 1.278,
                      h: 4,
                      ktotal: 4,
                      avg: ".444",
                    },
                    {
                      id: "f0600f04-1386-4c61-a412-804960c46cb1",
                      name: "Minute Maid Park",
                      market: "Houston",
                      surface: "grass",
                      ab: 3,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.0,
                      slg: 0.0,
                      ops: 0.0,
                      h: 0,
                      ktotal: 2,
                      avg: ".000",
                    },
                    {
                      id: "bb062fb1-e268-412e-b3d2-2ddb6acc1822",
                      name: "Nationals Park",
                      market: "Washington",
                      surface: "grass",
                      ab: 4,
                      runs: 1,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 2,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 1.0,
                      ops: 1.25,
                      h: 1,
                      ktotal: 2,
                      avg: ".250",
                    },
                    {
                      id: "85dd5cd6-7324-4b52-a1cc-68f816d0cdce",
                      name: "Coors Field",
                      market: "Colorado",
                      surface: "grass",
                      ab: 1,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.0,
                      slg: 0.0,
                      ops: 0.0,
                      h: 0,
                      ktotal: 1,
                      avg: ".000",
                    },
                    {
                      id: "706e9828-6687-4ac8-a409-3fb972e8bae9",
                      name: "Yankee Stadium",
                      market: "New York",
                      surface: "grass",
                      ab: 15,
                      runs: 0,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.125,
                      slg: 0.067,
                      ops: 0.192,
                      h: 1,
                      ktotal: 9,
                      avg: ".067",
                    },
                    {
                      id: "0ab45d79-5475-4308-9f94-74b0c185ee6f",
                      name: "PETCO Park",
                      market: "San Diego",
                      surface: "grass",
                      ab: 6,
                      runs: 1,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.167,
                      slg: 0.667,
                      ops: 0.834,
                      h: 1,
                      ktotal: 4,
                      avg: ".167",
                    },
                    {
                      id: "3f47c1c6-b059-4fa2-9d85-5d37b7000992",
                      name: "Globe Life Park in Arlington",
                      market: "Texas",
                      surface: "grass",
                      ab: 11,
                      runs: 0,
                      s: 0,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.091,
                      slg: 0.182,
                      ops: 0.273,
                      h: 1,
                      ktotal: 4,
                      avg: ".091",
                    },
                    {
                      id: "b27b0dc5-c2c5-401d-b8b9-a6a2dbc225e5",
                      name: "Fenway Park",
                      market: "Boston",
                      surface: "grass",
                      ab: 20,
                      runs: 3,
                      s: 2,
                      d: 3,
                      t: 0,
                      hr: 1,
                      rbi: 6,
                      bb: 6,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.462,
                      slg: 0.6,
                      ops: 1.062,
                      h: 6,
                      ktotal: 6,
                      avg: ".300",
                    },
                    {
                      id: "6fca95c9-7f2c-4acb-a9f3-02ef96340d2a",
                      name: "Kauffman Stadium",
                      market: "Kansas City",
                      surface: "grass",
                      ab: 2,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.5,
                      slg: 0.0,
                      ops: 0.5,
                      h: 0,
                      ktotal: 2,
                      avg: ".000",
                    },
                    {
                      id: "bf05de0d-7ced-4a19-8e17-2bbd985f8a92",
                      name: "Chase Field",
                      market: "Arizona",
                      surface: "turf",
                      ab: 9,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.0,
                      slg: 0.0,
                      ops: 0.0,
                      h: 0,
                      ktotal: 8,
                      avg: ".000",
                    },
                    {
                      id: "2b0ccd49-4d87-4996-ac4d-27ffc7ee4c16",
                      name: "Progressive Field",
                      market: "Cleveland",
                      surface: "grass",
                      ab: 16,
                      runs: 0,
                      s: 2,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.176,
                      slg: 0.125,
                      ops: 0.301,
                      h: 2,
                      ktotal: 11,
                      avg: ".125",
                    },
                    {
                      id: "48cbd35a-d932-4a75-beab-067bfbacfc26",
                      name: "RingCentral Coliseum",
                      market: "Oakland",
                      surface: "grass",
                      ab: 5,
                      runs: 1,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.167,
                      slg: 0.0,
                      ops: 0.167,
                      h: 0,
                      ktotal: 3,
                      avg: ".000",
                    },
                  ],
                  surface: [
                    {
                      value: "grass",
                      ab: 265,
                      runs: 21,
                      s: 28,
                      d: 8,
                      t: 0,
                      hr: 9,
                      rbi: 30,
                      bb: 32,
                      ibb: 0,
                      hbp: 3,
                      sb: 0,
                      cs: 0,
                      obp: 0.264,
                      slg: 0.302,
                      ops: 0.566,
                      h: 45,
                      ktotal: 116,
                      avg: ".170",
                    },
                    {
                      value: "turf",
                      ab: 42,
                      runs: 5,
                      s: 6,
                      d: 1,
                      t: 0,
                      hr: 3,
                      rbi: 6,
                      bb: 6,
                      ibb: 1,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.347,
                      slg: 0.476,
                      ops: 0.823,
                      h: 10,
                      ktotal: 23,
                      avg: ".238",
                    },
                  ],
                  month: [
                    {
                      value: "8",
                      ab: 45,
                      runs: 5,
                      s: 3,
                      d: 1,
                      t: 0,
                      hr: 2,
                      rbi: 7,
                      bb: 11,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.293,
                      slg: 0.289,
                      ops: 0.582,
                      h: 6,
                      ktotal: 20,
                      avg: ".133",
                    },
                    {
                      value: "3",
                      ab: 6,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.143,
                      slg: 0.0,
                      ops: 0.143,
                      h: 0,
                      ktotal: 3,
                      avg: ".000",
                    },
                    {
                      value: "6",
                      ab: 49,
                      runs: 2,
                      s: 7,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 4,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.241,
                      slg: 0.184,
                      ops: 0.425,
                      h: 8,
                      ktotal: 21,
                      avg: ".163",
                    },
                    {
                      value: "9",
                      ab: 30,
                      runs: 3,
                      s: 4,
                      d: 1,
                      t: 0,
                      hr: 2,
                      rbi: 2,
                      bb: 6,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.361,
                      slg: 0.467,
                      ops: 0.828,
                      h: 7,
                      ktotal: 14,
                      avg: ".233",
                    },
                    {
                      value: "7",
                      ab: 54,
                      runs: 5,
                      s: 7,
                      d: 2,
                      t: 0,
                      hr: 3,
                      rbi: 9,
                      bb: 5,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.283,
                      slg: 0.426,
                      ops: 0.709,
                      h: 12,
                      ktotal: 27,
                      avg: ".222",
                    },
                    {
                      value: "5",
                      ab: 61,
                      runs: 5,
                      s: 7,
                      d: 1,
                      t: 0,
                      hr: 2,
                      rbi: 4,
                      bb: 7,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.261,
                      slg: 0.279,
                      ops: 0.54,
                      h: 10,
                      ktotal: 29,
                      avg: ".164",
                    },
                    {
                      value: "4",
                      ab: 62,
                      runs: 6,
                      s: 6,
                      d: 3,
                      t: 0,
                      hr: 3,
                      rbi: 13,
                      bb: 4,
                      ibb: 1,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.265,
                      slg: 0.387,
                      ops: 0.652,
                      h: 12,
                      ktotal: 25,
                      avg: ".194",
                    },
                  ],
                  home_away: [
                    {
                      value: "away",
                      ab: 147,
                      runs: 14,
                      s: 16,
                      d: 5,
                      t: 0,
                      hr: 7,
                      rbi: 19,
                      bb: 18,
                      ibb: 1,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.287,
                      slg: 0.367,
                      ops: 0.654,
                      h: 28,
                      ktotal: 75,
                      avg: ".190",
                    },
                    {
                      value: "home",
                      ab: 160,
                      runs: 12,
                      s: 18,
                      d: 4,
                      t: 0,
                      hr: 5,
                      rbi: 17,
                      bb: 20,
                      ibb: 0,
                      hbp: 2,
                      sb: 0,
                      cs: 0,
                      obp: 0.265,
                      slg: 0.288,
                      ops: 0.553,
                      h: 27,
                      ktotal: 64,
                      avg: ".169",
                    },
                  ],
                  pitcher_hand: [
                    {
                      value: "L",
                      ab: 61,
                      runs: 4,
                      s: 6,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 3,
                      bb: 7,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.232,
                      slg: 0.23,
                      ops: 0.462,
                      h: 8,
                      ktotal: 30,
                      avg: ".131",
                    },
                    {
                      value: "R",
                      ab: 246,
                      runs: 22,
                      s: 28,
                      d: 9,
                      t: 0,
                      hr: 10,
                      rbi: 33,
                      bb: 31,
                      ibb: 1,
                      hbp: 2,
                      sb: 0,
                      cs: 0,
                      obp: 0.286,
                      slg: 0.35,
                      ops: 0.636,
                      h: 47,
                      ktotal: 109,
                      avg: ".191",
                    },
                  ],
                  opponent: [
                    {
                      id: "a09ec676-f887-43dc-bbb3-cf4bbaee9a18",
                      name: "Yankees",
                      market: "New York",
                      abbr: "NYY",
                      ab: 38,
                      runs: 1,
                      s: 1,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 2,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.119,
                      slg: 0.079,
                      ops: 0.198,
                      h: 2,
                      ktotal: 21,
                      avg: ".053",
                    },
                    {
                      id: "29dd9a87-5bcc-4774-80c3-7f50d985068b",
                      name: "Rockies",
                      market: "Colorado",
                      abbr: "COL",
                      ab: 1,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.0,
                      slg: 0.0,
                      ops: 0.0,
                      h: 0,
                      ktotal: 1,
                      avg: ".000",
                    },
                    {
                      id: "93941372-eb4c-4c40-aced-fe3267174393",
                      name: "Red Sox",
                      market: "Boston",
                      abbr: "BOS",
                      ab: 44,
                      runs: 4,
                      s: 7,
                      d: 4,
                      t: 0,
                      hr: 1,
                      rbi: 9,
                      bb: 7,
                      ibb: 0,
                      hbp: 2,
                      sb: 0,
                      cs: 0,
                      obp: 0.396,
                      slg: 0.432,
                      ops: 0.828,
                      h: 12,
                      ktotal: 15,
                      avg: ".273",
                    },
                    {
                      id: "d52d5339-cbdd-43f3-9dfa-a42fd588b9a3",
                      name: "Padres",
                      market: "San Diego",
                      abbr: "SD",
                      ab: 9,
                      runs: 1,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.3,
                      slg: 0.556,
                      ops: 0.856,
                      h: 2,
                      ktotal: 5,
                      avg: ".222",
                    },
                    {
                      id: "ef64da7f-cfaf-4300-87b0-9313386b977c",
                      name: "Dodgers",
                      market: "Los Angeles",
                      abbr: "LAD",
                      ab: 2,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.0,
                      slg: 0.0,
                      ops: 0.0,
                      h: 0,
                      ktotal: 1,
                      avg: ".000",
                    },
                    {
                      id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                      name: "Twins",
                      market: "Minnesota",
                      abbr: "MIN",
                      ab: 14,
                      runs: 2,
                      s: 1,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 0,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.267,
                      slg: 0.5,
                      ops: 0.767,
                      h: 3,
                      ktotal: 6,
                      avg: ".214",
                    },
                    {
                      id: "bdc11650-6f74-49c4-875e-778aeb7632d9",
                      name: "Rays",
                      market: "Tampa Bay",
                      abbr: "TB",
                      ab: 31,
                      runs: 3,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 2,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.2,
                      slg: 0.226,
                      ops: 0.426,
                      h: 4,
                      ktotal: 8,
                      avg: ".129",
                    },
                    {
                      id: "d99f919b-1534-4516-8e8a-9cd106c6d8cd",
                      name: "Rangers",
                      market: "Texas",
                      abbr: "TEX",
                      ab: 14,
                      runs: 0,
                      s: 0,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.133,
                      slg: 0.143,
                      ops: 0.276,
                      h: 1,
                      ktotal: 5,
                      avg: ".071",
                    },
                    {
                      id: "25507be1-6a68-4267-bd82-e097d94b359b",
                      name: "Diamondbacks",
                      market: "Arizona",
                      abbr: "ARI",
                      ab: 9,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.0,
                      slg: 0.0,
                      ops: 0.0,
                      h: 0,
                      ktotal: 8,
                      avg: ".000",
                    },
                    {
                      id: "80715d0d-0d2a-450f-a970-1b9a3b18c7e7",
                      name: "Indians",
                      market: "Cleveland",
                      abbr: "CLE",
                      ab: 23,
                      runs: 0,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.167,
                      slg: 0.13,
                      ops: 0.297,
                      h: 3,
                      ktotal: 15,
                      avg: ".130",
                    },
                    {
                      id: "d89bed32-3aee-4407-99e3-4103641b999a",
                      name: "Nationals",
                      market: "Washington",
                      abbr: "WSH",
                      ab: 11,
                      runs: 1,
                      s: 2,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 3,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.545,
                      ops: 0.878,
                      h: 3,
                      ktotal: 5,
                      avg: ".273",
                    },
                    {
                      id: "833a51a9-0d84-410f-bd77-da08c3e5e26e",
                      name: "Royals",
                      market: "Kansas City",
                      abbr: "KC",
                      ab: 2,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.5,
                      slg: 0.0,
                      ops: 0.5,
                      h: 0,
                      ktotal: 2,
                      avg: ".000",
                    },
                    {
                      id: "1d678440-b4b1-4954-9b39-70afb3ebbcfa",
                      name: "Blue Jays",
                      market: "Toronto",
                      abbr: "TOR",
                      ab: 47,
                      runs: 6,
                      s: 7,
                      d: 1,
                      t: 0,
                      hr: 3,
                      rbi: 8,
                      bb: 11,
                      ibb: 1,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.39,
                      slg: 0.447,
                      ops: 0.837,
                      h: 11,
                      ktotal: 21,
                      avg: ".234",
                    },
                    {
                      id: "4f735188-37c8-473d-ae32-1f7e34ccf892",
                      name: "Angels",
                      market: "Los Angeles",
                      abbr: "LAA",
                      ab: 13,
                      runs: 3,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 3,
                      bb: 4,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.412,
                      slg: 0.692,
                      ops: 1.104,
                      h: 3,
                      ktotal: 5,
                      avg: ".231",
                    },
                    {
                      id: "eb21dadd-8f10-4095-8bf3-dfb3b779f107",
                      name: "Astros",
                      market: "Houston",
                      abbr: "HOU",
                      ab: 12,
                      runs: 0,
                      s: 0,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.143,
                      slg: 0.167,
                      ops: 0.31,
                      h: 1,
                      ktotal: 9,
                      avg: ".083",
                    },
                    {
                      id: "27a59d3b-ff7c-48ea-b016-4798f560f5e1",
                      name: "Athletics",
                      market: "Oakland",
                      abbr: "OAK",
                      ab: 14,
                      runs: 2,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.125,
                      slg: 0.0,
                      ops: 0.125,
                      h: 0,
                      ktotal: 6,
                      avg: ".000",
                    },
                    {
                      id: "43a39081-52b4-4f93-ad29-da7f329ea960",
                      name: "Mariners",
                      market: "Seattle",
                      abbr: "SEA",
                      ab: 10,
                      runs: 1,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.545,
                      slg: 0.8,
                      ops: 1.345,
                      h: 5,
                      ktotal: 3,
                      avg: ".500",
                    },
                    {
                      id: "47f490cd-2f58-4ef7-9dfd-2ad6ba6c1ae8",
                      name: "White Sox",
                      market: "Chicago",
                      abbr: "CWS",
                      ab: 13,
                      runs: 2,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 4,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.385,
                      slg: 0.615,
                      ops: 1.0,
                      h: 5,
                      ktotal: 3,
                      avg: ".385",
                    },
                  ],
                },
              ],
            },
            pitching: {
              overall: [
                {
                  total: [
                    {
                      win: 0,
                      loss: 0,
                      save: 0,
                      svo: 0,
                      start: 0,
                      play: 1,
                      complete: 0,
                      team_win: 0,
                      team_loss: 0,
                      ip_1: 3,
                      ip_2: 1.0,
                      h: 2,
                      runs: 1,
                      er: 1,
                      hr: 1,
                      bb: 0,
                      ibb: 0,
                      oba: 0.4,
                      era: 9.0,
                      ktotal: 1,
                      s: 0,
                      d: 1,
                      t: 0,
                      rbi: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.4,
                      slg: 1.2,
                      ops: 1.6,
                      bf: 5,
                    },
                  ],
                  day_night: [
                    {
                      value: "night",
                      win: 0,
                      loss: 0,
                      save: 0,
                      svo: 0,
                      start: 0,
                      play: 1,
                      complete: 0,
                      ip_1: 3,
                      ip_2: 1.0,
                      h: 2,
                      runs: 1,
                      er: 1,
                      hr: 1,
                      bb: 0,
                      ibb: 0,
                      oba: 0.4,
                      era: 9.0,
                      ktotal: 1,
                      team_win: 0,
                      team_loss: 0,
                    },
                  ],
                  venue: [
                    {
                      id: "434aa63c-1049-4a54-a6bc-3790c7772ee1",
                      name: "Oriole Park at Camden Yards",
                      market: "Baltimore",
                      surface: "grass",
                      win: 0,
                      loss: 0,
                      save: 0,
                      svo: 0,
                      start: 0,
                      play: 1,
                      complete: 0,
                      ip_1: 3,
                      ip_2: 1.0,
                      h: 2,
                      runs: 1,
                      er: 1,
                      hr: 1,
                      bb: 0,
                      ibb: 0,
                      oba: 0.4,
                      era: 9.0,
                      ktotal: 1,
                      team_win: 0,
                      team_loss: 0,
                    },
                  ],
                  surface: [
                    {
                      value: "grass",
                      win: 0,
                      loss: 0,
                      save: 0,
                      svo: 0,
                      start: 0,
                      play: 1,
                      complete: 0,
                      ip_1: 3,
                      ip_2: 1.0,
                      h: 2,
                      runs: 1,
                      er: 1,
                      hr: 1,
                      bb: 0,
                      ibb: 0,
                      oba: 0.4,
                      era: 9.0,
                      ktotal: 1,
                      team_win: 0,
                      team_loss: 0,
                    },
                  ],
                  month: [
                    {
                      value: "4",
                      win: 0,
                      loss: 0,
                      save: 0,
                      svo: 0,
                      start: 0,
                      play: 1,
                      complete: 0,
                      ip_1: 3,
                      ip_2: 1.0,
                      h: 2,
                      runs: 1,
                      er: 1,
                      hr: 1,
                      bb: 0,
                      ibb: 0,
                      oba: 0.4,
                      era: 9.0,
                      ktotal: 1,
                      team_win: 0,
                      team_loss: 0,
                    },
                  ],
                  home_away: [
                    {
                      value: "home",
                      win: 0,
                      loss: 0,
                      save: 0,
                      svo: 0,
                      start: 0,
                      play: 1,
                      complete: 0,
                      ip_1: 3,
                      ip_2: 1.0,
                      h: 2,
                      runs: 1,
                      er: 1,
                      hr: 1,
                      bb: 0,
                      ibb: 0,
                      oba: 0.4,
                      era: 9.0,
                      ktotal: 1,
                      team_win: 0,
                      team_loss: 0,
                    },
                  ],
                  hitter_hand: [
                    {
                      value: "R",
                      bf: 3,
                      runs: 1,
                      s: 0,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      oba: 0.667,
                      obp: 0.667,
                      slg: 2.0,
                      ops: 2.667,
                      h: 2,
                      ktotal: 0,
                    },
                    {
                      value: "L",
                      bf: 2,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      oba: 0.0,
                      obp: 0.0,
                      slg: 0.0,
                      ops: 0.0,
                      h: 0,
                      ktotal: 1,
                    },
                  ],
                  opponent: [
                    {
                      id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                      name: "Twins",
                      market: "Minnesota",
                      abbr: "MIN",
                      win: 0,
                      loss: 0,
                      save: 0,
                      svo: 0,
                      start: 0,
                      play: 1,
                      complete: 0,
                      ip_1: 3,
                      ip_2: 1.0,
                      h: 2,
                      runs: 1,
                      er: 1,
                      hr: 1,
                      bb: 0,
                      ibb: 0,
                      oba: 0.4,
                      era: 9.0,
                      ktotal: 1,
                      team_win: 0,
                      team_loss: 0,
                    },
                  ],
                },
              ],
              bullpen: [
                {
                  total: [
                    {
                      win: 0,
                      loss: 0,
                      save: 0,
                      svo: 0,
                      start: 0,
                      play: 1,
                      complete: 0,
                      team_win: 0,
                      team_loss: 0,
                      ip_1: 3,
                      ip_2: 1.0,
                      h: 2,
                      runs: 1,
                      er: 1,
                      hr: 1,
                      bb: 0,
                      ibb: 0,
                      oba: 0.4,
                      era: 9.0,
                      ktotal: 1,
                      s: 0,
                      d: 1,
                      t: 0,
                      rbi: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.4,
                      slg: 1.2,
                      ops: 1.6,
                      bf: 5,
                    },
                  ],
                  day_night: [
                    {
                      value: "night",
                      win: 0,
                      loss: 0,
                      save: 0,
                      svo: 0,
                      start: 0,
                      play: 1,
                      complete: 0,
                      ip_1: 3,
                      ip_2: 1.0,
                      h: 2,
                      runs: 1,
                      er: 1,
                      hr: 1,
                      bb: 0,
                      ibb: 0,
                      oba: 0.4,
                      era: 9.0,
                      ktotal: 1,
                      team_win: 0,
                      team_loss: 0,
                    },
                  ],
                  venue: [
                    {
                      id: "434aa63c-1049-4a54-a6bc-3790c7772ee1",
                      name: "Oriole Park at Camden Yards",
                      market: "Baltimore",
                      surface: "grass",
                      win: 0,
                      loss: 0,
                      save: 0,
                      svo: 0,
                      start: 0,
                      play: 1,
                      complete: 0,
                      ip_1: 3,
                      ip_2: 1.0,
                      h: 2,
                      runs: 1,
                      er: 1,
                      hr: 1,
                      bb: 0,
                      ibb: 0,
                      oba: 0.4,
                      era: 9.0,
                      ktotal: 1,
                      team_win: 0,
                      team_loss: 0,
                    },
                  ],
                  surface: [
                    {
                      value: "grass",
                      win: 0,
                      loss: 0,
                      save: 0,
                      svo: 0,
                      start: 0,
                      play: 1,
                      complete: 0,
                      ip_1: 3,
                      ip_2: 1.0,
                      h: 2,
                      runs: 1,
                      er: 1,
                      hr: 1,
                      bb: 0,
                      ibb: 0,
                      oba: 0.4,
                      era: 9.0,
                      ktotal: 1,
                      team_win: 0,
                      team_loss: 0,
                    },
                  ],
                  month: [
                    {
                      value: "4",
                      win: 0,
                      loss: 0,
                      save: 0,
                      svo: 0,
                      start: 0,
                      play: 1,
                      complete: 0,
                      ip_1: 3,
                      ip_2: 1.0,
                      h: 2,
                      runs: 1,
                      er: 1,
                      hr: 1,
                      bb: 0,
                      ibb: 0,
                      oba: 0.4,
                      era: 9.0,
                      ktotal: 1,
                      team_win: 0,
                      team_loss: 0,
                    },
                  ],
                  home_away: [
                    {
                      value: "home",
                      win: 0,
                      loss: 0,
                      save: 0,
                      svo: 0,
                      start: 0,
                      play: 1,
                      complete: 0,
                      ip_1: 3,
                      ip_2: 1.0,
                      h: 2,
                      runs: 1,
                      er: 1,
                      hr: 1,
                      bb: 0,
                      ibb: 0,
                      oba: 0.4,
                      era: 9.0,
                      ktotal: 1,
                      team_win: 0,
                      team_loss: 0,
                    },
                  ],
                  hitter_hand: [
                    {
                      value: "L",
                      bf: 2,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      oba: 0.0,
                      obp: 0.0,
                      slg: 0.0,
                      ops: 0.0,
                      h: 0,
                      ktotal: 1,
                    },
                    {
                      value: "R",
                      bf: 3,
                      runs: 1,
                      s: 0,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      oba: 0.667,
                      obp: 0.667,
                      slg: 2.0,
                      ops: 2.667,
                      h: 2,
                      ktotal: 0,
                    },
                  ],
                  opponent: [
                    {
                      id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                      name: "Twins",
                      market: "Minnesota",
                      abbr: "MIN",
                      win: 0,
                      loss: 0,
                      save: 0,
                      svo: 0,
                      start: 0,
                      play: 1,
                      complete: 0,
                      ip_1: 3,
                      ip_2: 1.0,
                      h: 2,
                      runs: 1,
                      er: 1,
                      hr: 1,
                      bb: 0,
                      ibb: 0,
                      oba: 0.4,
                      era: 9.0,
                      ktotal: 1,
                      team_win: 0,
                      team_loss: 0,
                    },
                  ],
                },
              ],
            },
          },
          statcast_metrics: {
            hitting: {
              overall: [
                {
                  generated_speed: {
                    min: 32.06,
                    avg: 73.08,
                    max: 92.66,
                    count: 166,
                  },
                },
                {
                  homerun_distance: {
                    min: 346.36,
                    avg: 390.33,
                    max: 416.66,
                    count: 12,
                  },
                },
                { travel_time: { min: 0.02, avg: 2.18, max: 5.0, count: 154 } },
                {
                  max_height: {
                    min: 1.13,
                    avg: 41.28,
                    max: 167.52,
                    count: 166,
                  },
                },
                {
                  launch_angle: {
                    min: -49.19,
                    avg: 14.11,
                    max: 83.79,
                    count: 171,
                  },
                },
                {
                  launch_speed: {
                    min: 45.49,
                    avg: 89.06,
                    max: 108.36,
                    count: 171,
                  },
                },
                {
                  barreled_ball: { min: 0.0, avg: 0.11, max: 1.0, count: 166 },
                },
                {
                  launch_spin_rate: {
                    min: 749.87,
                    avg: 2613.33,
                    max: 5481.17,
                    count: 126,
                  },
                },
                {
                  distance: { min: 3.23, avg: 176.25, max: 390.07, count: 154 },
                },
                {
                  travel_distance: {
                    min: 3.06,
                    avg: 182.11,
                    max: 382.48,
                    count: 154,
                  },
                },
                { hang_time: { min: 0.02, avg: 2.58, max: 6.78, count: 166 } },
              ],
            },
            pitching: {
              overall: [
                {
                  launch_speed: {
                    min: 86.73,
                    avg: 99.26,
                    max: 104.09,
                    count: 4,
                  },
                },
              ],
              pitch_types: [
                {
                  type: "Slider",
                  launch_angle: {
                    min: -3.25,
                    avg: -3.25,
                    max: -3.25,
                    count: 1,
                  },
                  release_spin_rate: {
                    min: 2261.17,
                    avg: 2261.17,
                    max: 2261.17,
                    count: 1,
                  },
                  release_extension: {
                    min: 5.61,
                    avg: 5.61,
                    max: 5.61,
                    count: 1,
                  },
                  release_speed: {
                    min: 83.28,
                    avg: 83.28,
                    max: 83.28,
                    count: 1,
                  },
                  effective_speed: {
                    min: 82.07,
                    avg: 82.07,
                    max: 82.07,
                    count: 1,
                  },
                },
                {
                  type: "Curveball",
                  launch_angle: { min: -2.18, avg: -1.11, max: 0.95, count: 4 },
                  release_spin_rate: {
                    min: 1198.02,
                    avg: 1566.94,
                    max: 1986.51,
                    count: 3,
                  },
                  release_extension: {
                    min: 5.09,
                    avg: 5.24,
                    max: 5.51,
                    count: 4,
                  },
                  release_speed: {
                    min: 75.45,
                    avg: 77.66,
                    max: 80.51,
                    count: 4,
                  },
                  effective_speed: {
                    min: 73.34,
                    avg: 76.14,
                    max: 79.55,
                    count: 4,
                  },
                  generated_speed: {
                    min: 59.91,
                    avg: 59.91,
                    max: 59.91,
                    count: 1,
                  },
                },
                {
                  type: "Changeup",
                  launch_angle: {
                    min: -6.03,
                    avg: 1.46,
                    max: 25.91,
                    count: 21,
                  },
                  release_spin_rate: {
                    min: 2053.75,
                    avg: 2166.77,
                    max: 2310.36,
                    count: 17,
                  },
                  release_extension: {
                    min: 5.14,
                    avg: 5.48,
                    max: 5.9,
                    count: 17,
                  },
                  release_speed: {
                    min: 81.71,
                    avg: 83.94,
                    max: 85.89,
                    count: 17,
                  },
                  effective_speed: {
                    min: 80.56,
                    avg: 82.81,
                    max: 85.05,
                    count: 17,
                  },
                  generated_speed: {
                    min: 46.93,
                    avg: 73.15,
                    max: 89.16,
                    count: 12,
                  },
                },
              ],
            },
          },
        },
        teams: [
          {
            name: "Orioles",
            market: "Baltimore",
            abbr: "BAL",
            id: "75729d34-bca7-4a0f-b3df-6f26c6ad3719",
            statistics: {
              hitting: {
                overall: {
                  ab: 307,
                  lob: 125,
                  rbi: 36,
                  abhr: 25.583,
                  abk: 2.209,
                  bip: 159,
                  babip: 0.27,
                  bbk: 0.281,
                  bbpa: 0.111,
                  iso: 0.147,
                  obp: 0.276,
                  ops: 0.602,
                  seca: 0.274,
                  slg: 0.326,
                  xbh: 21,
                  pitch_count: 1524,
                  lob_risp_2out: 32,
                  team_lob: 62,
                  ab_risp: 65,
                  hit_risp: 15,
                  rbi_2out: 15,
                  linedrive: 48,
                  groundball: 67,
                  popup: 5,
                  flyball: 51,
                  ap: 352,
                  avg: ".179",
                  gofo: 1.07,
                  onbase: {
                    s: 34,
                    d: 9,
                    t: 0,
                    hr: 12,
                    tb: 100,
                    bb: 38,
                    ibb: 1,
                    hbp: 3,
                    fc: 5,
                    roe: 2,
                    h: 55,
                    cycle: 0,
                  },
                  runs: { total: 26 },
                  outcome: {
                    klook: 307,
                    kswing: 233,
                    ktotal: 540,
                    ball: 569,
                    iball: 0,
                    dirtball: 31,
                    foul: 210,
                  },
                  outs: {
                    po: 5,
                    fo: 33,
                    fidp: 0,
                    lo: 21,
                    lidp: 0,
                    go: 63,
                    gidp: 6,
                    klook: 60,
                    kswing: 79,
                    ktotal: 139,
                    sacfly: 3,
                    sachit: 0,
                  },
                  steal: { caught: 0, stolen: 0, pct: 0, pickoff: 0 },
                  pitches: { count: 1524, btotal: 603, ktotal: 921 },
                  games: { start: 86, play: 105, finish: 19, complete: 80 },
                },
              },
              pitching: {
                overall: {
                  oba: 0.4,
                  lob: 1,
                  era: 9.0,
                  k9: 9.0,
                  whip: 2.0,
                  kbb: 0.0,
                  pitch_count: 22,
                  wp: 0,
                  bk: 0,
                  ip_1: 3,
                  ip_2: 1.0,
                  bf: 5,
                  gofo: 1.0,
                  babip: 0.333,
                  bf_ip: 5.0,
                  gbfb: 1.0,
                  oab: 5,
                  slg: 1.2,
                  obp: 0.4,
                  onbase: {
                    s: 0,
                    d: 1,
                    t: 0,
                    hr: 1,
                    tb: 6,
                    bb: 0,
                    ibb: 0,
                    hbp: 0,
                    fc: 0,
                    roe: 0,
                    h: 2,
                    h9: 18.0,
                    hr9: 9.0,
                  },
                  runs: {
                    total: 1,
                    unearned: 0,
                    earned: 1,
                    ir: 0,
                    ira: 0,
                    bqr: 0,
                    bqra: 0,
                  },
                  outcome: {
                    klook: 1,
                    kswing: 1,
                    ktotal: 2,
                    ball: 6,
                    iball: 0,
                    dirtball: 0,
                    foul: 10,
                  },
                  outs: {
                    po: 0,
                    fo: 0,
                    fidp: 0,
                    lo: 1,
                    lidp: 0,
                    go: 1,
                    gidp: 0,
                    klook: 0,
                    kswing: 1,
                    ktotal: 1,
                    sacfly: 0,
                    sachit: 0,
                  },
                  steal: { caught: 0, stolen: 0, pickoff: 0 },
                  pitches: {
                    count: 22,
                    btotal: 6,
                    ktotal: 16,
                    per_ip: 22.0,
                    per_bf: 4.4,
                  },
                  in_play: {
                    linedrive: 2,
                    groundball: 1,
                    popup: 0,
                    flyball: 1,
                  },
                  games: {
                    start: 0,
                    play: 1,
                    finish: 1,
                    svo: 0,
                    qstart: 0,
                    shutout: 0,
                    complete: 0,
                    win: 0,
                    loss: 0,
                    save: 0,
                    hold: 0,
                    blown_save: 0,
                    team_win: 0,
                    team_loss: 0,
                  },
                },
                bullpen: {
                  oba: 0.4,
                  lob: 1,
                  era: 9.0,
                  k9: 9.0,
                  whip: 2.0,
                  kbb: 0.0,
                  pitch_count: 22,
                  wp: 0,
                  bk: 0,
                  ip_1: 3,
                  ip_2: 1.0,
                  bf: 5,
                  gofo: 1.0,
                  babip: 0.333,
                  bf_ip: 5.0,
                  gbfb: 1.0,
                  oab: 5,
                  slg: 1.2,
                  obp: 0.4,
                  onbase: {
                    s: 0,
                    d: 1,
                    t: 0,
                    hr: 1,
                    tb: 6,
                    bb: 0,
                    ibb: 0,
                    hbp: 0,
                    fc: 0,
                    roe: 0,
                    h: 2,
                    h9: 18.0,
                    hr9: 9.0,
                  },
                  runs: {
                    total: 1,
                    unearned: 0,
                    earned: 1,
                    ir: 0,
                    ira: 0,
                    bqr: 0,
                    bqra: 0,
                  },
                  outcome: {
                    klook: 1,
                    kswing: 1,
                    ktotal: 2,
                    ball: 6,
                    iball: 0,
                    dirtball: 0,
                    foul: 10,
                  },
                  outs: {
                    po: 0,
                    fo: 0,
                    fidp: 0,
                    lo: 1,
                    lidp: 0,
                    go: 1,
                    gidp: 0,
                    klook: 0,
                    kswing: 1,
                    ktotal: 1,
                    sacfly: 0,
                    sachit: 0,
                  },
                  steal: { caught: 0, stolen: 0, pickoff: 0 },
                  pitches: {
                    count: 22,
                    btotal: 6,
                    ktotal: 16,
                    per_ip: 22.0,
                    per_bf: 4.4,
                  },
                  in_play: {
                    linedrive: 2,
                    groundball: 1,
                    popup: 0,
                    flyball: 1,
                  },
                  games: {
                    start: 0,
                    play: 1,
                    finish: 1,
                    svo: 0,
                    qstart: 0,
                    shutout: 0,
                    complete: 0,
                    win: 0,
                    loss: 0,
                    save: 0,
                    hold: 0,
                    blown_save: 0,
                    team_win: 0,
                    team_loss: 0,
                  },
                },
              },
              fielding: {
                overall: {
                  po: 672,
                  a: 37,
                  dp: 86,
                  tp: 0,
                  error: 5,
                  tc: 714,
                  fpct: 0.993,
                  c_wp: 0,
                  pb: 0,
                  rf: 7.162,
                  inn_1: 2335,
                  inn_2: 778.1,
                  steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                  errors: {
                    throwing: 1,
                    fielding: 4,
                    interference: 0,
                    total: 5,
                  },
                  assists: { outfield: 0, total: 37 },
                  games: { start: 86, play: 99, finish: 13, complete: 0 },
                },
                positions: [
                  {
                    po: 0,
                    a: 0,
                    dp: 0,
                    tp: 0,
                    error: 0,
                    tc: 0,
                    fpct: 0.0,
                    c_wp: 0,
                    pb: 0,
                    rf: 0.0,
                    inn_1: 3,
                    inn_2: 1.0,
                    position: "P",
                    steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                    errors: {
                      throwing: 0,
                      fielding: 0,
                      interference: 0,
                      total: 0,
                    },
                    assists: { outfield: 0, total: 0 },
                    games: { start: 0, play: 1, finish: 1, complete: 0 },
                  },
                  {
                    po: 671,
                    a: 37,
                    dp: 86,
                    tp: 0,
                    error: 4,
                    tc: 712,
                    fpct: 0.994,
                    c_wp: 0,
                    pb: 0,
                    rf: 7.299,
                    inn_1: 2308,
                    inn_2: 769.1,
                    position: "1B",
                    steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                    errors: {
                      throwing: 1,
                      fielding: 3,
                      interference: 0,
                      total: 4,
                    },
                    assists: { outfield: 0, total: 37 },
                    games: { start: 85, play: 97, finish: 12, complete: 0 },
                  },
                  {
                    po: 1,
                    a: 0,
                    dp: 0,
                    tp: 0,
                    error: 1,
                    tc: 2,
                    fpct: 0.5,
                    c_wp: 0,
                    pb: 0,
                    rf: 1.0,
                    inn_1: 24,
                    inn_2: 8.0,
                    position: "RF",
                    steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                    errors: {
                      throwing: 0,
                      fielding: 1,
                      interference: 0,
                      total: 1,
                    },
                    assists: { outfield: 0, total: 0 },
                    games: { start: 1, play: 1, finish: 0, complete: 0 },
                  },
                ],
              },
            },
            splits: {
              hitting: {
                overall: [
                  {
                    total: [
                      {
                        ab: 307,
                        runs: 26,
                        s: 34,
                        d: 9,
                        t: 0,
                        hr: 12,
                        rbi: 36,
                        bb: 38,
                        ibb: 1,
                        hbp: 3,
                        sb: 0,
                        cs: 0,
                        obp: 0.276,
                        slg: 0.326,
                        ops: 0.602,
                        h: 55,
                        ktotal: 139,
                        avg: ".179",
                      },
                    ],
                    day_night: [
                      {
                        value: "night",
                        ab: 197,
                        runs: 15,
                        s: 29,
                        d: 3,
                        t: 0,
                        hr: 7,
                        rbi: 23,
                        bb: 20,
                        ibb: 1,
                        hbp: 2,
                        sb: 0,
                        cs: 0,
                        obp: 0.279,
                        slg: 0.32,
                        ops: 0.599,
                        h: 39,
                        ktotal: 83,
                        avg: ".198",
                      },
                      {
                        value: "day",
                        ab: 110,
                        runs: 11,
                        s: 5,
                        d: 6,
                        t: 0,
                        hr: 5,
                        rbi: 13,
                        bb: 18,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.269,
                        slg: 0.336,
                        ops: 0.605,
                        h: 16,
                        ktotal: 56,
                        avg: ".145",
                      },
                    ],
                    venue: [
                      {
                        id: "434aa63c-1049-4a54-a6bc-3790c7772ee1",
                        name: "Oriole Park at Camden Yards",
                        market: "Baltimore",
                        surface: "grass",
                        ab: 160,
                        runs: 12,
                        s: 18,
                        d: 4,
                        t: 0,
                        hr: 5,
                        rbi: 17,
                        bb: 20,
                        ibb: 0,
                        hbp: 2,
                        sb: 0,
                        cs: 0,
                        obp: 0.265,
                        slg: 0.288,
                        ops: 0.553,
                        h: 27,
                        ktotal: 64,
                        avg: ".169",
                      },
                      {
                        id: "302f8dcd-eed6-4b83-8609-81548d51e955",
                        name: "Target Field",
                        market: "Minnesota",
                        surface: "grass",
                        ab: 6,
                        runs: 1,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 0,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.286,
                        slg: 0.667,
                        ops: 0.953,
                        h: 1,
                        ktotal: 3,
                        avg: ".167",
                      },
                      {
                        id: "4a550fc1-c03d-4294-b63a-30ff197bedc4",
                        name: "Guaranteed Rate Field",
                        market: "Chicago",
                        surface: "grass",
                        ab: 9,
                        runs: 1,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 2,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.333,
                        ops: 0.666,
                        h: 3,
                        ktotal: 2,
                        avg: ".333",
                      },
                      {
                        id: "60732da9-ad03-4feb-9a36-aee3e98c7a2b",
                        name: "Angel Stadium of Anaheim",
                        market: "Los Angeles",
                        surface: "grass",
                        ab: 3,
                        runs: 1,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 0.0,
                        ops: 0.25,
                        h: 0,
                        ktotal: 2,
                        avg: ".000",
                      },
                      {
                        id: "f1c03dac-3c0f-437c-a325-8d5702cd321a",
                        name: "T-Mobile Park",
                        market: "Seattle",
                        surface: "grass",
                        ab: 4,
                        runs: 0,
                        s: 2,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.5,
                        slg: 0.5,
                        ops: 1.0,
                        h: 2,
                        ktotal: 1,
                        avg: ".500",
                      },
                      {
                        id: "84d72338-2173-4a90-9d25-99adc6c86f4b",
                        name: "Rogers Centre",
                        market: "Toronto",
                        surface: "turf",
                        ab: 24,
                        runs: 2,
                        s: 3,
                        d: 1,
                        t: 0,
                        hr: 2,
                        rbi: 5,
                        bb: 5,
                        ibb: 1,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.4,
                        slg: 0.542,
                        ops: 0.942,
                        h: 6,
                        ktotal: 11,
                        avg: ".250",
                      },
                      {
                        id: "3aaaf4af-0f8c-49c1-8bf1-1780bb5a5f5c",
                        name: "Tropicana Field",
                        market: "Tampa Bay",
                        surface: "turf",
                        ab: 9,
                        runs: 3,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.5,
                        slg: 0.778,
                        ops: 1.278,
                        h: 4,
                        ktotal: 4,
                        avg: ".444",
                      },
                      {
                        id: "f0600f04-1386-4c61-a412-804960c46cb1",
                        name: "Minute Maid Park",
                        market: "Houston",
                        surface: "grass",
                        ab: 3,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.0,
                        slg: 0.0,
                        ops: 0.0,
                        h: 0,
                        ktotal: 2,
                        avg: ".000",
                      },
                      {
                        id: "bb062fb1-e268-412e-b3d2-2ddb6acc1822",
                        name: "Nationals Park",
                        market: "Washington",
                        surface: "grass",
                        ab: 4,
                        runs: 1,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 2,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 1.0,
                        ops: 1.25,
                        h: 1,
                        ktotal: 2,
                        avg: ".250",
                      },
                      {
                        id: "85dd5cd6-7324-4b52-a1cc-68f816d0cdce",
                        name: "Coors Field",
                        market: "Colorado",
                        surface: "grass",
                        ab: 1,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.0,
                        slg: 0.0,
                        ops: 0.0,
                        h: 0,
                        ktotal: 1,
                        avg: ".000",
                      },
                      {
                        id: "706e9828-6687-4ac8-a409-3fb972e8bae9",
                        name: "Yankee Stadium",
                        market: "New York",
                        surface: "grass",
                        ab: 15,
                        runs: 0,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.125,
                        slg: 0.067,
                        ops: 0.192,
                        h: 1,
                        ktotal: 9,
                        avg: ".067",
                      },
                      {
                        id: "0ab45d79-5475-4308-9f94-74b0c185ee6f",
                        name: "PETCO Park",
                        market: "San Diego",
                        surface: "grass",
                        ab: 6,
                        runs: 1,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.167,
                        slg: 0.667,
                        ops: 0.834,
                        h: 1,
                        ktotal: 4,
                        avg: ".167",
                      },
                      {
                        id: "3f47c1c6-b059-4fa2-9d85-5d37b7000992",
                        name: "Globe Life Park in Arlington",
                        market: "Texas",
                        surface: "grass",
                        ab: 11,
                        runs: 0,
                        s: 0,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.091,
                        slg: 0.182,
                        ops: 0.273,
                        h: 1,
                        ktotal: 4,
                        avg: ".091",
                      },
                      {
                        id: "b27b0dc5-c2c5-401d-b8b9-a6a2dbc225e5",
                        name: "Fenway Park",
                        market: "Boston",
                        surface: "grass",
                        ab: 20,
                        runs: 3,
                        s: 2,
                        d: 3,
                        t: 0,
                        hr: 1,
                        rbi: 6,
                        bb: 6,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.462,
                        slg: 0.6,
                        ops: 1.062,
                        h: 6,
                        ktotal: 6,
                        avg: ".300",
                      },
                      {
                        id: "6fca95c9-7f2c-4acb-a9f3-02ef96340d2a",
                        name: "Kauffman Stadium",
                        market: "Kansas City",
                        surface: "grass",
                        ab: 2,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.5,
                        slg: 0.0,
                        ops: 0.5,
                        h: 0,
                        ktotal: 2,
                        avg: ".000",
                      },
                      {
                        id: "bf05de0d-7ced-4a19-8e17-2bbd985f8a92",
                        name: "Chase Field",
                        market: "Arizona",
                        surface: "turf",
                        ab: 9,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.0,
                        slg: 0.0,
                        ops: 0.0,
                        h: 0,
                        ktotal: 8,
                        avg: ".000",
                      },
                      {
                        id: "2b0ccd49-4d87-4996-ac4d-27ffc7ee4c16",
                        name: "Progressive Field",
                        market: "Cleveland",
                        surface: "grass",
                        ab: 16,
                        runs: 0,
                        s: 2,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.176,
                        slg: 0.125,
                        ops: 0.301,
                        h: 2,
                        ktotal: 11,
                        avg: ".125",
                      },
                      {
                        id: "48cbd35a-d932-4a75-beab-067bfbacfc26",
                        name: "RingCentral Coliseum",
                        market: "Oakland",
                        surface: "grass",
                        ab: 5,
                        runs: 1,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.167,
                        slg: 0.0,
                        ops: 0.167,
                        h: 0,
                        ktotal: 3,
                        avg: ".000",
                      },
                    ],
                    surface: [
                      {
                        value: "grass",
                        ab: 265,
                        runs: 21,
                        s: 28,
                        d: 8,
                        t: 0,
                        hr: 9,
                        rbi: 30,
                        bb: 32,
                        ibb: 0,
                        hbp: 3,
                        sb: 0,
                        cs: 0,
                        obp: 0.264,
                        slg: 0.302,
                        ops: 0.566,
                        h: 45,
                        ktotal: 116,
                        avg: ".170",
                      },
                      {
                        value: "turf",
                        ab: 42,
                        runs: 5,
                        s: 6,
                        d: 1,
                        t: 0,
                        hr: 3,
                        rbi: 6,
                        bb: 6,
                        ibb: 1,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.347,
                        slg: 0.476,
                        ops: 0.823,
                        h: 10,
                        ktotal: 23,
                        avg: ".238",
                      },
                    ],
                    month: [
                      {
                        value: "8",
                        ab: 45,
                        runs: 5,
                        s: 3,
                        d: 1,
                        t: 0,
                        hr: 2,
                        rbi: 7,
                        bb: 11,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.293,
                        slg: 0.289,
                        ops: 0.582,
                        h: 6,
                        ktotal: 20,
                        avg: ".133",
                      },
                      {
                        value: "3",
                        ab: 6,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.143,
                        slg: 0.0,
                        ops: 0.143,
                        h: 0,
                        ktotal: 3,
                        avg: ".000",
                      },
                      {
                        value: "6",
                        ab: 49,
                        runs: 2,
                        s: 7,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 4,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.241,
                        slg: 0.184,
                        ops: 0.425,
                        h: 8,
                        ktotal: 21,
                        avg: ".163",
                      },
                      {
                        value: "9",
                        ab: 30,
                        runs: 3,
                        s: 4,
                        d: 1,
                        t: 0,
                        hr: 2,
                        rbi: 2,
                        bb: 6,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.361,
                        slg: 0.467,
                        ops: 0.828,
                        h: 7,
                        ktotal: 14,
                        avg: ".233",
                      },
                      {
                        value: "7",
                        ab: 54,
                        runs: 5,
                        s: 7,
                        d: 2,
                        t: 0,
                        hr: 3,
                        rbi: 9,
                        bb: 5,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.283,
                        slg: 0.426,
                        ops: 0.709,
                        h: 12,
                        ktotal: 27,
                        avg: ".222",
                      },
                      {
                        value: "5",
                        ab: 61,
                        runs: 5,
                        s: 7,
                        d: 1,
                        t: 0,
                        hr: 2,
                        rbi: 4,
                        bb: 7,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.261,
                        slg: 0.279,
                        ops: 0.54,
                        h: 10,
                        ktotal: 29,
                        avg: ".164",
                      },
                      {
                        value: "4",
                        ab: 62,
                        runs: 6,
                        s: 6,
                        d: 3,
                        t: 0,
                        hr: 3,
                        rbi: 13,
                        bb: 4,
                        ibb: 1,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.265,
                        slg: 0.387,
                        ops: 0.652,
                        h: 12,
                        ktotal: 25,
                        avg: ".194",
                      },
                    ],
                    home_away: [
                      {
                        value: "away",
                        ab: 147,
                        runs: 14,
                        s: 16,
                        d: 5,
                        t: 0,
                        hr: 7,
                        rbi: 19,
                        bb: 18,
                        ibb: 1,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.287,
                        slg: 0.367,
                        ops: 0.654,
                        h: 28,
                        ktotal: 75,
                        avg: ".190",
                      },
                      {
                        value: "home",
                        ab: 160,
                        runs: 12,
                        s: 18,
                        d: 4,
                        t: 0,
                        hr: 5,
                        rbi: 17,
                        bb: 20,
                        ibb: 0,
                        hbp: 2,
                        sb: 0,
                        cs: 0,
                        obp: 0.265,
                        slg: 0.288,
                        ops: 0.553,
                        h: 27,
                        ktotal: 64,
                        avg: ".169",
                      },
                    ],
                    pitcher_hand: [
                      {
                        value: "L",
                        ab: 61,
                        runs: 4,
                        s: 6,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 3,
                        bb: 7,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.232,
                        slg: 0.23,
                        ops: 0.462,
                        h: 8,
                        ktotal: 30,
                        avg: ".131",
                      },
                      {
                        value: "R",
                        ab: 246,
                        runs: 22,
                        s: 28,
                        d: 9,
                        t: 0,
                        hr: 10,
                        rbi: 33,
                        bb: 31,
                        ibb: 1,
                        hbp: 2,
                        sb: 0,
                        cs: 0,
                        obp: 0.286,
                        slg: 0.35,
                        ops: 0.636,
                        h: 47,
                        ktotal: 109,
                        avg: ".191",
                      },
                    ],
                    opponent: [
                      {
                        id: "a09ec676-f887-43dc-bbb3-cf4bbaee9a18",
                        name: "Yankees",
                        market: "New York",
                        abbr: "NYY",
                        ab: 38,
                        runs: 1,
                        s: 1,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 2,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.119,
                        slg: 0.079,
                        ops: 0.198,
                        h: 2,
                        ktotal: 21,
                        avg: ".053",
                      },
                      {
                        id: "29dd9a87-5bcc-4774-80c3-7f50d985068b",
                        name: "Rockies",
                        market: "Colorado",
                        abbr: "COL",
                        ab: 1,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.0,
                        slg: 0.0,
                        ops: 0.0,
                        h: 0,
                        ktotal: 1,
                        avg: ".000",
                      },
                      {
                        id: "93941372-eb4c-4c40-aced-fe3267174393",
                        name: "Red Sox",
                        market: "Boston",
                        abbr: "BOS",
                        ab: 44,
                        runs: 4,
                        s: 7,
                        d: 4,
                        t: 0,
                        hr: 1,
                        rbi: 9,
                        bb: 7,
                        ibb: 0,
                        hbp: 2,
                        sb: 0,
                        cs: 0,
                        obp: 0.396,
                        slg: 0.432,
                        ops: 0.828,
                        h: 12,
                        ktotal: 15,
                        avg: ".273",
                      },
                      {
                        id: "d52d5339-cbdd-43f3-9dfa-a42fd588b9a3",
                        name: "Padres",
                        market: "San Diego",
                        abbr: "SD",
                        ab: 9,
                        runs: 1,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.3,
                        slg: 0.556,
                        ops: 0.856,
                        h: 2,
                        ktotal: 5,
                        avg: ".222",
                      },
                      {
                        id: "ef64da7f-cfaf-4300-87b0-9313386b977c",
                        name: "Dodgers",
                        market: "Los Angeles",
                        abbr: "LAD",
                        ab: 2,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.0,
                        slg: 0.0,
                        ops: 0.0,
                        h: 0,
                        ktotal: 1,
                        avg: ".000",
                      },
                      {
                        id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                        name: "Twins",
                        market: "Minnesota",
                        abbr: "MIN",
                        ab: 14,
                        runs: 2,
                        s: 1,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 0,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.267,
                        slg: 0.5,
                        ops: 0.767,
                        h: 3,
                        ktotal: 6,
                        avg: ".214",
                      },
                      {
                        id: "bdc11650-6f74-49c4-875e-778aeb7632d9",
                        name: "Rays",
                        market: "Tampa Bay",
                        abbr: "TB",
                        ab: 31,
                        runs: 3,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 2,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.2,
                        slg: 0.226,
                        ops: 0.426,
                        h: 4,
                        ktotal: 8,
                        avg: ".129",
                      },
                      {
                        id: "d99f919b-1534-4516-8e8a-9cd106c6d8cd",
                        name: "Rangers",
                        market: "Texas",
                        abbr: "TEX",
                        ab: 14,
                        runs: 0,
                        s: 0,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.133,
                        slg: 0.143,
                        ops: 0.276,
                        h: 1,
                        ktotal: 5,
                        avg: ".071",
                      },
                      {
                        id: "25507be1-6a68-4267-bd82-e097d94b359b",
                        name: "Diamondbacks",
                        market: "Arizona",
                        abbr: "ARI",
                        ab: 9,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.0,
                        slg: 0.0,
                        ops: 0.0,
                        h: 0,
                        ktotal: 8,
                        avg: ".000",
                      },
                      {
                        id: "80715d0d-0d2a-450f-a970-1b9a3b18c7e7",
                        name: "Indians",
                        market: "Cleveland",
                        abbr: "CLE",
                        ab: 23,
                        runs: 0,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.167,
                        slg: 0.13,
                        ops: 0.297,
                        h: 3,
                        ktotal: 15,
                        avg: ".130",
                      },
                      {
                        id: "d89bed32-3aee-4407-99e3-4103641b999a",
                        name: "Nationals",
                        market: "Washington",
                        abbr: "WSH",
                        ab: 11,
                        runs: 1,
                        s: 2,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 3,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.545,
                        ops: 0.878,
                        h: 3,
                        ktotal: 5,
                        avg: ".273",
                      },
                      {
                        id: "833a51a9-0d84-410f-bd77-da08c3e5e26e",
                        name: "Royals",
                        market: "Kansas City",
                        abbr: "KC",
                        ab: 2,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.5,
                        slg: 0.0,
                        ops: 0.5,
                        h: 0,
                        ktotal: 2,
                        avg: ".000",
                      },
                      {
                        id: "1d678440-b4b1-4954-9b39-70afb3ebbcfa",
                        name: "Blue Jays",
                        market: "Toronto",
                        abbr: "TOR",
                        ab: 47,
                        runs: 6,
                        s: 7,
                        d: 1,
                        t: 0,
                        hr: 3,
                        rbi: 8,
                        bb: 11,
                        ibb: 1,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.39,
                        slg: 0.447,
                        ops: 0.837,
                        h: 11,
                        ktotal: 21,
                        avg: ".234",
                      },
                      {
                        id: "4f735188-37c8-473d-ae32-1f7e34ccf892",
                        name: "Angels",
                        market: "Los Angeles",
                        abbr: "LAA",
                        ab: 13,
                        runs: 3,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 3,
                        bb: 4,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.412,
                        slg: 0.692,
                        ops: 1.104,
                        h: 3,
                        ktotal: 5,
                        avg: ".231",
                      },
                      {
                        id: "eb21dadd-8f10-4095-8bf3-dfb3b779f107",
                        name: "Astros",
                        market: "Houston",
                        abbr: "HOU",
                        ab: 12,
                        runs: 0,
                        s: 0,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.143,
                        slg: 0.167,
                        ops: 0.31,
                        h: 1,
                        ktotal: 9,
                        avg: ".083",
                      },
                      {
                        id: "27a59d3b-ff7c-48ea-b016-4798f560f5e1",
                        name: "Athletics",
                        market: "Oakland",
                        abbr: "OAK",
                        ab: 14,
                        runs: 2,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.125,
                        slg: 0.0,
                        ops: 0.125,
                        h: 0,
                        ktotal: 6,
                        avg: ".000",
                      },
                      {
                        id: "43a39081-52b4-4f93-ad29-da7f329ea960",
                        name: "Mariners",
                        market: "Seattle",
                        abbr: "SEA",
                        ab: 10,
                        runs: 1,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.545,
                        slg: 0.8,
                        ops: 1.345,
                        h: 5,
                        ktotal: 3,
                        avg: ".500",
                      },
                      {
                        id: "47f490cd-2f58-4ef7-9dfd-2ad6ba6c1ae8",
                        name: "White Sox",
                        market: "Chicago",
                        abbr: "CWS",
                        ab: 13,
                        runs: 2,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 4,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.385,
                        slg: 0.615,
                        ops: 1.0,
                        h: 5,
                        ktotal: 3,
                        avg: ".385",
                      },
                    ],
                  },
                ],
              },
              pitching: {
                overall: [
                  {
                    total: [
                      {
                        win: 0,
                        loss: 0,
                        save: 0,
                        svo: 0,
                        start: 0,
                        play: 1,
                        complete: 0,
                        team_win: 0,
                        team_loss: 0,
                        ip_1: 3,
                        ip_2: 1.0,
                        h: 2,
                        runs: 1,
                        er: 1,
                        hr: 1,
                        bb: 0,
                        ibb: 0,
                        oba: 0.4,
                        era: 9.0,
                        ktotal: 1,
                        s: 0,
                        d: 1,
                        t: 0,
                        rbi: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.4,
                        slg: 1.2,
                        ops: 1.6,
                        bf: 5,
                      },
                    ],
                    day_night: [
                      {
                        value: "night",
                        win: 0,
                        loss: 0,
                        save: 0,
                        svo: 0,
                        start: 0,
                        play: 1,
                        complete: 0,
                        ip_1: 3,
                        ip_2: 1.0,
                        h: 2,
                        runs: 1,
                        er: 1,
                        hr: 1,
                        bb: 0,
                        ibb: 0,
                        oba: 0.4,
                        era: 9.0,
                        ktotal: 1,
                        team_win: 0,
                        team_loss: 0,
                      },
                    ],
                    venue: [
                      {
                        id: "434aa63c-1049-4a54-a6bc-3790c7772ee1",
                        name: "Oriole Park at Camden Yards",
                        market: "Baltimore",
                        surface: "grass",
                        win: 0,
                        loss: 0,
                        save: 0,
                        svo: 0,
                        start: 0,
                        play: 1,
                        complete: 0,
                        ip_1: 3,
                        ip_2: 1.0,
                        h: 2,
                        runs: 1,
                        er: 1,
                        hr: 1,
                        bb: 0,
                        ibb: 0,
                        oba: 0.4,
                        era: 9.0,
                        ktotal: 1,
                        team_win: 0,
                        team_loss: 0,
                      },
                    ],
                    surface: [
                      {
                        value: "grass",
                        win: 0,
                        loss: 0,
                        save: 0,
                        svo: 0,
                        start: 0,
                        play: 1,
                        complete: 0,
                        ip_1: 3,
                        ip_2: 1.0,
                        h: 2,
                        runs: 1,
                        er: 1,
                        hr: 1,
                        bb: 0,
                        ibb: 0,
                        oba: 0.4,
                        era: 9.0,
                        ktotal: 1,
                        team_win: 0,
                        team_loss: 0,
                      },
                    ],
                    month: [
                      {
                        value: "4",
                        win: 0,
                        loss: 0,
                        save: 0,
                        svo: 0,
                        start: 0,
                        play: 1,
                        complete: 0,
                        ip_1: 3,
                        ip_2: 1.0,
                        h: 2,
                        runs: 1,
                        er: 1,
                        hr: 1,
                        bb: 0,
                        ibb: 0,
                        oba: 0.4,
                        era: 9.0,
                        ktotal: 1,
                        team_win: 0,
                        team_loss: 0,
                      },
                    ],
                    home_away: [
                      {
                        value: "home",
                        win: 0,
                        loss: 0,
                        save: 0,
                        svo: 0,
                        start: 0,
                        play: 1,
                        complete: 0,
                        ip_1: 3,
                        ip_2: 1.0,
                        h: 2,
                        runs: 1,
                        er: 1,
                        hr: 1,
                        bb: 0,
                        ibb: 0,
                        oba: 0.4,
                        era: 9.0,
                        ktotal: 1,
                        team_win: 0,
                        team_loss: 0,
                      },
                    ],
                    hitter_hand: [
                      {
                        value: "R",
                        bf: 3,
                        runs: 1,
                        s: 0,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        oba: 0.667,
                        obp: 0.667,
                        slg: 2.0,
                        ops: 2.667,
                        h: 2,
                        ktotal: 0,
                      },
                      {
                        value: "L",
                        bf: 2,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        oba: 0.0,
                        obp: 0.0,
                        slg: 0.0,
                        ops: 0.0,
                        h: 0,
                        ktotal: 1,
                      },
                    ],
                    opponent: [
                      {
                        id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                        name: "Twins",
                        market: "Minnesota",
                        abbr: "MIN",
                        win: 0,
                        loss: 0,
                        save: 0,
                        svo: 0,
                        start: 0,
                        play: 1,
                        complete: 0,
                        ip_1: 3,
                        ip_2: 1.0,
                        h: 2,
                        runs: 1,
                        er: 1,
                        hr: 1,
                        bb: 0,
                        ibb: 0,
                        oba: 0.4,
                        era: 9.0,
                        ktotal: 1,
                        team_win: 0,
                        team_loss: 0,
                      },
                    ],
                  },
                ],
                bullpen: [
                  {
                    total: [
                      {
                        win: 0,
                        loss: 0,
                        save: 0,
                        svo: 0,
                        start: 0,
                        play: 1,
                        complete: 0,
                        team_win: 0,
                        team_loss: 0,
                        ip_1: 3,
                        ip_2: 1.0,
                        h: 2,
                        runs: 1,
                        er: 1,
                        hr: 1,
                        bb: 0,
                        ibb: 0,
                        oba: 0.4,
                        era: 9.0,
                        ktotal: 1,
                        s: 0,
                        d: 1,
                        t: 0,
                        rbi: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.4,
                        slg: 1.2,
                        ops: 1.6,
                        bf: 5,
                      },
                    ],
                    day_night: [
                      {
                        value: "night",
                        win: 0,
                        loss: 0,
                        save: 0,
                        svo: 0,
                        start: 0,
                        play: 1,
                        complete: 0,
                        ip_1: 3,
                        ip_2: 1.0,
                        h: 2,
                        runs: 1,
                        er: 1,
                        hr: 1,
                        bb: 0,
                        ibb: 0,
                        oba: 0.4,
                        era: 9.0,
                        ktotal: 1,
                        team_win: 0,
                        team_loss: 0,
                      },
                    ],
                    venue: [
                      {
                        id: "434aa63c-1049-4a54-a6bc-3790c7772ee1",
                        name: "Oriole Park at Camden Yards",
                        market: "Baltimore",
                        surface: "grass",
                        win: 0,
                        loss: 0,
                        save: 0,
                        svo: 0,
                        start: 0,
                        play: 1,
                        complete: 0,
                        ip_1: 3,
                        ip_2: 1.0,
                        h: 2,
                        runs: 1,
                        er: 1,
                        hr: 1,
                        bb: 0,
                        ibb: 0,
                        oba: 0.4,
                        era: 9.0,
                        ktotal: 1,
                        team_win: 0,
                        team_loss: 0,
                      },
                    ],
                    surface: [
                      {
                        value: "grass",
                        win: 0,
                        loss: 0,
                        save: 0,
                        svo: 0,
                        start: 0,
                        play: 1,
                        complete: 0,
                        ip_1: 3,
                        ip_2: 1.0,
                        h: 2,
                        runs: 1,
                        er: 1,
                        hr: 1,
                        bb: 0,
                        ibb: 0,
                        oba: 0.4,
                        era: 9.0,
                        ktotal: 1,
                        team_win: 0,
                        team_loss: 0,
                      },
                    ],
                    month: [
                      {
                        value: "4",
                        win: 0,
                        loss: 0,
                        save: 0,
                        svo: 0,
                        start: 0,
                        play: 1,
                        complete: 0,
                        ip_1: 3,
                        ip_2: 1.0,
                        h: 2,
                        runs: 1,
                        er: 1,
                        hr: 1,
                        bb: 0,
                        ibb: 0,
                        oba: 0.4,
                        era: 9.0,
                        ktotal: 1,
                        team_win: 0,
                        team_loss: 0,
                      },
                    ],
                    home_away: [
                      {
                        value: "home",
                        win: 0,
                        loss: 0,
                        save: 0,
                        svo: 0,
                        start: 0,
                        play: 1,
                        complete: 0,
                        ip_1: 3,
                        ip_2: 1.0,
                        h: 2,
                        runs: 1,
                        er: 1,
                        hr: 1,
                        bb: 0,
                        ibb: 0,
                        oba: 0.4,
                        era: 9.0,
                        ktotal: 1,
                        team_win: 0,
                        team_loss: 0,
                      },
                    ],
                    hitter_hand: [
                      {
                        value: "L",
                        bf: 2,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        oba: 0.0,
                        obp: 0.0,
                        slg: 0.0,
                        ops: 0.0,
                        h: 0,
                        ktotal: 1,
                      },
                      {
                        value: "R",
                        bf: 3,
                        runs: 1,
                        s: 0,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        oba: 0.667,
                        obp: 0.667,
                        slg: 2.0,
                        ops: 2.667,
                        h: 2,
                        ktotal: 0,
                      },
                    ],
                    opponent: [
                      {
                        id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                        name: "Twins",
                        market: "Minnesota",
                        abbr: "MIN",
                        win: 0,
                        loss: 0,
                        save: 0,
                        svo: 0,
                        start: 0,
                        play: 1,
                        complete: 0,
                        ip_1: 3,
                        ip_2: 1.0,
                        h: 2,
                        runs: 1,
                        er: 1,
                        hr: 1,
                        bb: 0,
                        ibb: 0,
                        oba: 0.4,
                        era: 9.0,
                        ktotal: 1,
                        team_win: 0,
                        team_loss: 0,
                      },
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
      {
        id: "00cd4d28-e638-4ae7-9824-6dbbfba50d4a",
        year: 2018,
        type: "REG",
        totals: {
          statistics: {
            hitting: {
              overall: {
                ab: 470,
                lob: 190,
                rbi: 49,
                abhr: 29.375,
                abk: 2.448,
                bip: 266,
                babip: 0.237,
                bbk: 0.214,
                bbpa: 0.079,
                iso: 0.128,
                obp: 0.243,
                ops: 0.539,
                seca: 0.219,
                slg: 0.296,
                xbh: 28,
                pitch_count: 2185,
                lob_risp_2out: 55,
                team_lob: 107,
                ab_risp: 90,
                hit_risp: 18,
                rbi_2out: 26,
                linedrive: 68,
                groundball: 116,
                popup: 15,
                flyball: 83,
                ap: 522,
                avg: ".168",
                gofo: 0.96,
                onbase: {
                  s: 51,
                  d: 12,
                  t: 0,
                  hr: 16,
                  tb: 139,
                  bb: 39,
                  ibb: 2,
                  hbp: 7,
                  fc: 5,
                  roe: 4,
                  h: 79,
                  cycle: 0,
                },
                runs: { total: 40 },
                outcome: {
                  klook: 382,
                  kswing: 337,
                  ktotal: 719,
                  ball: 762,
                  iball: 0,
                  dirtball: 50,
                  foul: 365,
                },
                outs: {
                  po: 15,
                  fo: 62,
                  fidp: 0,
                  lo: 29,
                  lidp: 0,
                  go: 102,
                  gidp: 5,
                  klook: 57,
                  kswing: 135,
                  ktotal: 192,
                  sacfly: 4,
                  sachit: 0,
                },
                steal: { caught: 0, stolen: 2, pct: 1.0, pickoff: 0 },
                pitches: { count: 2185, btotal: 819, ktotal: 1366 },
                games: { start: 127, play: 128, finish: 1, complete: 123 },
              },
            },
            fielding: {
              overall: {
                po: 913,
                a: 67,
                dp: 98,
                tp: 0,
                error: 5,
                tc: 985,
                fpct: 0.995,
                c_wp: 0,
                pb: 0,
                rf: 8.448,
                inn_1: 3030,
                inn_2: 1010.0,
                steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                errors: { throwing: 2, fielding: 3, interference: 0, total: 5 },
                assists: { outfield: 0, total: 67 },
                games: { start: 115, play: 116, finish: 1, complete: 0 },
              },
              positions: [
                {
                  po: 913,
                  a: 67,
                  dp: 98,
                  tp: 0,
                  error: 5,
                  tc: 985,
                  fpct: 0.995,
                  c_wp: 0,
                  pb: 0,
                  rf: 8.448,
                  inn_1: 3030,
                  inn_2: 1010.0,
                  position: "1B",
                  steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                  errors: {
                    throwing: 2,
                    fielding: 3,
                    interference: 0,
                    total: 5,
                  },
                  assists: { outfield: 0, total: 67 },
                  games: { start: 115, play: 116, finish: 1, complete: 0 },
                },
              ],
            },
          },
          splits: {
            hitting: {
              overall: [
                {
                  total: [
                    {
                      ab: 470,
                      runs: 40,
                      s: 51,
                      d: 12,
                      t: 0,
                      hr: 16,
                      rbi: 49,
                      bb: 39,
                      ibb: 2,
                      hbp: 7,
                      sb: 2,
                      cs: 0,
                      obp: 0.243,
                      slg: 0.296,
                      ops: 0.539,
                      h: 79,
                      ktotal: 192,
                      avg: ".168",
                    },
                  ],
                  day_night: [
                    {
                      value: "day",
                      ab: 135,
                      runs: 13,
                      s: 14,
                      d: 5,
                      t: 0,
                      hr: 6,
                      rbi: 17,
                      bb: 14,
                      ibb: 1,
                      hbp: 3,
                      sb: 0,
                      cs: 0,
                      obp: 0.279,
                      slg: 0.356,
                      ops: 0.635,
                      h: 25,
                      ktotal: 55,
                      avg: ".185",
                    },
                    {
                      value: "night",
                      ab: 335,
                      runs: 27,
                      s: 37,
                      d: 7,
                      t: 0,
                      hr: 10,
                      rbi: 32,
                      bb: 25,
                      ibb: 1,
                      hbp: 4,
                      sb: 2,
                      cs: 0,
                      obp: 0.228,
                      slg: 0.272,
                      ops: 0.5,
                      h: 54,
                      ktotal: 137,
                      avg: ".161",
                    },
                  ],
                  venue: [
                    {
                      id: "60732da9-ad03-4feb-9a36-aee3e98c7a2b",
                      name: "Angel Stadium of Anaheim",
                      market: "Los Angeles",
                      surface: "grass",
                      ab: 8,
                      runs: 1,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 2,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.545,
                      slg: 0.375,
                      ops: 0.92,
                      h: 3,
                      ktotal: 2,
                      avg: ".375",
                    },
                    {
                      id: "4a550fc1-c03d-4294-b63a-30ff197bedc4",
                      name: "Guaranteed Rate Field",
                      market: "Chicago",
                      surface: "grass",
                      ab: 13,
                      runs: 1,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 3,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.235,
                      slg: 0.0,
                      ops: 0.235,
                      h: 0,
                      ktotal: 6,
                      avg: ".000",
                    },
                    {
                      id: "b27b0dc5-c2c5-401d-b8b9-a6a2dbc225e5",
                      name: "Fenway Park",
                      market: "Boston",
                      surface: "grass",
                      ab: 24,
                      runs: 2,
                      s: 1,
                      d: 2,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.125,
                      slg: 0.208,
                      ops: 0.333,
                      h: 3,
                      ktotal: 15,
                      avg: ".125",
                    },
                    {
                      id: "f1c03dac-3c0f-437c-a325-8d5702cd321a",
                      name: "T-Mobile Park",
                      market: "Seattle",
                      surface: "grass",
                      ab: 9,
                      runs: 0,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.333,
                      ops: 0.666,
                      h: 3,
                      ktotal: 1,
                      avg: ".333",
                    },
                    {
                      id: "434aa63c-1049-4a54-a6bc-3790c7772ee1",
                      name: "Oriole Park at Camden Yards",
                      market: "Baltimore",
                      surface: "grass",
                      ab: 239,
                      runs: 24,
                      s: 26,
                      d: 7,
                      t: 0,
                      hr: 10,
                      rbi: 25,
                      bb: 17,
                      ibb: 1,
                      hbp: 4,
                      sb: 2,
                      cs: 0,
                      obp: 0.248,
                      slg: 0.335,
                      ops: 0.583,
                      h: 43,
                      ktotal: 89,
                      avg: ".180",
                    },
                    {
                      id: "302f8dcd-eed6-4b83-8609-81548d51e955",
                      name: "Target Field",
                      market: "Minnesota",
                      surface: "grass",
                      ab: 15,
                      runs: 2,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 4,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 0.6,
                      ops: 0.85,
                      h: 3,
                      ktotal: 4,
                      avg: ".200",
                    },
                    {
                      id: "f0600f04-1386-4c61-a412-804960c46cb1",
                      name: "Minute Maid Park",
                      market: "Houston",
                      surface: "grass",
                      ab: 9,
                      runs: 0,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.2,
                      slg: 0.111,
                      ops: 0.311,
                      h: 1,
                      ktotal: 5,
                      avg: ".111",
                    },
                    {
                      id: "3aaaf4af-0f8c-49c1-8bf1-1780bb5a5f5c",
                      name: "Tropicana Field",
                      market: "Tampa Bay",
                      surface: "turf",
                      ab: 28,
                      runs: 1,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 2,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.133,
                      slg: 0.107,
                      ops: 0.24,
                      h: 3,
                      ktotal: 13,
                      avg: ".107",
                    },
                    {
                      id: "706e9828-6687-4ac8-a409-3fb972e8bae9",
                      name: "Yankee Stadium",
                      market: "New York",
                      surface: "grass",
                      ab: 26,
                      runs: 2,
                      s: 2,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 4,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.258,
                      slg: 0.231,
                      ops: 0.489,
                      h: 3,
                      ktotal: 11,
                      avg: ".115",
                    },
                    {
                      id: "b4186004-b6d0-42e7-8dc1-82ef0d946ecc",
                      name: "Citizens Bank Park",
                      market: "Philadelphia",
                      surface: "grass",
                      ab: 8,
                      runs: 0,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.125,
                      slg: 0.125,
                      ops: 0.25,
                      h: 1,
                      ktotal: 3,
                      avg: ".125",
                    },
                    {
                      id: "84d72338-2173-4a90-9d25-99adc6c86f4b",
                      name: "Rogers Centre",
                      market: "Toronto",
                      surface: "turf",
                      ab: 23,
                      runs: 2,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 3,
                      bb: 0,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.12,
                      slg: 0.217,
                      ops: 0.337,
                      h: 2,
                      ktotal: 15,
                      avg: ".087",
                    },
                    {
                      id: "3f47c1c6-b059-4fa2-9d85-5d37b7000992",
                      name: "Globe Life Park in Arlington",
                      market: "Texas",
                      surface: "grass",
                      ab: 8,
                      runs: 1,
                      s: 1,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 2,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.375,
                      ops: 0.708,
                      h: 2,
                      ktotal: 3,
                      avg: ".250",
                    },
                    {
                      id: "2b0ccd49-4d87-4996-ac4d-27ffc7ee4c16",
                      name: "Progressive Field",
                      market: "Cleveland",
                      surface: "grass",
                      ab: 11,
                      runs: 0,
                      s: 2,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.273,
                      slg: 0.364,
                      ops: 0.637,
                      h: 3,
                      ktotal: 3,
                      avg: ".273",
                    },
                    {
                      id: "ef9a5eef-06c6-4963-ac70-7fd02c8c8d42",
                      name: "Comerica Park",
                      market: "Detroit",
                      surface: "grass",
                      ab: 9,
                      runs: 1,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 3,
                      bb: 4,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.462,
                      slg: 0.556,
                      ops: 1.018,
                      h: 2,
                      ktotal: 6,
                      avg: ".222",
                    },
                    {
                      id: "48cbd35a-d932-4a75-beab-067bfbacfc26",
                      name: "RingCentral Coliseum",
                      market: "Oakland",
                      surface: "grass",
                      ab: 13,
                      runs: 1,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.077,
                      slg: 0.077,
                      ops: 0.154,
                      h: 1,
                      ktotal: 6,
                      avg: ".077",
                    },
                    {
                      id: "6fca95c9-7f2c-4acb-a9f3-02ef96340d2a",
                      name: "Kauffman Stadium",
                      market: "Kansas City",
                      surface: "grass",
                      ab: 9,
                      runs: 1,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.455,
                      slg: 0.333,
                      ops: 0.788,
                      h: 3,
                      ktotal: 3,
                      avg: ".333",
                    },
                    {
                      id: "265c7d6c-427a-4b8a-8def-392c41954bec",
                      name: "Citi Field",
                      market: "New York",
                      surface: "grass",
                      ab: 6,
                      runs: 0,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.286,
                      slg: 0.167,
                      ops: 0.453,
                      h: 1,
                      ktotal: 3,
                      avg: ".167",
                    },
                    {
                      id: "3ca8b577-5844-42ed-81a5-bd5d5cbe350c",
                      name: "Truist Park",
                      market: "Atlanta",
                      surface: "grass",
                      ab: 12,
                      runs: 1,
                      s: 0,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 5,
                      bb: 1,
                      ibb: 1,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.267,
                      slg: 0.5,
                      ops: 0.767,
                      h: 2,
                      ktotal: 4,
                      avg: ".167",
                    },
                  ],
                  surface: [
                    {
                      value: "grass",
                      ab: 419,
                      runs: 37,
                      s: 47,
                      d: 12,
                      t: 0,
                      hr: 15,
                      rbi: 44,
                      bb: 38,
                      ibb: 2,
                      hbp: 6,
                      sb: 2,
                      cs: 0,
                      obp: 0.257,
                      slg: 0.313,
                      ops: 0.57,
                      h: 74,
                      ktotal: 164,
                      avg: ".177",
                    },
                    {
                      value: "turf",
                      ab: 51,
                      runs: 3,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 5,
                      bb: 1,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.127,
                      slg: 0.157,
                      ops: 0.284,
                      h: 5,
                      ktotal: 28,
                      avg: ".098",
                    },
                  ],
                  month: [
                    {
                      value: "8",
                      ab: 93,
                      runs: 10,
                      s: 15,
                      d: 3,
                      t: 0,
                      hr: 3,
                      rbi: 13,
                      bb: 7,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.282,
                      slg: 0.355,
                      ops: 0.637,
                      h: 21,
                      ktotal: 41,
                      avg: ".226",
                    },
                    {
                      value: "3",
                      ab: 9,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 1,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.1,
                      slg: 0.0,
                      ops: 0.1,
                      h: 0,
                      ktotal: 1,
                      avg: ".000",
                    },
                    {
                      value: "4",
                      ab: 81,
                      runs: 3,
                      s: 11,
                      d: 2,
                      t: 0,
                      hr: 2,
                      rbi: 6,
                      bb: 9,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.275,
                      slg: 0.284,
                      ops: 0.559,
                      h: 15,
                      ktotal: 32,
                      avg: ".185",
                    },
                    {
                      value: "6",
                      ab: 61,
                      runs: 4,
                      s: 5,
                      d: 1,
                      t: 0,
                      hr: 3,
                      rbi: 9,
                      bb: 3,
                      ibb: 1,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.209,
                      slg: 0.311,
                      ops: 0.52,
                      h: 9,
                      ktotal: 27,
                      avg: ".148",
                    },
                    {
                      value: "7",
                      ab: 83,
                      runs: 15,
                      s: 5,
                      d: 3,
                      t: 0,
                      hr: 6,
                      rbi: 11,
                      bb: 10,
                      ibb: 0,
                      hbp: 2,
                      sb: 2,
                      cs: 0,
                      obp: 0.274,
                      slg: 0.422,
                      ops: 0.696,
                      h: 14,
                      ktotal: 28,
                      avg: ".169",
                    },
                    {
                      value: "5",
                      ab: 90,
                      runs: 6,
                      s: 9,
                      d: 2,
                      t: 0,
                      hr: 2,
                      rbi: 9,
                      bb: 8,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.22,
                      slg: 0.233,
                      ops: 0.453,
                      h: 13,
                      ktotal: 39,
                      avg: ".144",
                    },
                    {
                      value: "9",
                      ab: 53,
                      runs: 2,
                      s: 6,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 2,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.179,
                      slg: 0.151,
                      ops: 0.33,
                      h: 7,
                      ktotal: 24,
                      avg: ".132",
                    },
                  ],
                  home_away: [
                    {
                      value: "home",
                      ab: 239,
                      runs: 24,
                      s: 26,
                      d: 7,
                      t: 0,
                      hr: 10,
                      rbi: 25,
                      bb: 17,
                      ibb: 1,
                      hbp: 4,
                      sb: 2,
                      cs: 0,
                      obp: 0.248,
                      slg: 0.335,
                      ops: 0.583,
                      h: 43,
                      ktotal: 89,
                      avg: ".180",
                    },
                    {
                      value: "away",
                      ab: 231,
                      runs: 16,
                      s: 25,
                      d: 5,
                      t: 0,
                      hr: 6,
                      rbi: 24,
                      bb: 22,
                      ibb: 1,
                      hbp: 3,
                      sb: 0,
                      cs: 0,
                      obp: 0.238,
                      slg: 0.255,
                      ops: 0.493,
                      h: 36,
                      ktotal: 103,
                      avg: ".156",
                    },
                  ],
                  pitcher_hand: [
                    {
                      value: "R",
                      ab: 338,
                      runs: 31,
                      s: 40,
                      d: 9,
                      t: 0,
                      hr: 11,
                      rbi: 34,
                      bb: 28,
                      ibb: 2,
                      hbp: 6,
                      sb: 1,
                      cs: 0,
                      obp: 0.255,
                      slg: 0.302,
                      ops: 0.557,
                      h: 60,
                      ktotal: 138,
                      avg: ".178",
                    },
                    {
                      value: "L",
                      ab: 132,
                      runs: 9,
                      s: 11,
                      d: 3,
                      t: 0,
                      hr: 5,
                      rbi: 15,
                      bb: 11,
                      ibb: 0,
                      hbp: 1,
                      sb: 1,
                      cs: 0,
                      obp: 0.212,
                      slg: 0.28,
                      ops: 0.492,
                      h: 19,
                      ktotal: 54,
                      avg: ".144",
                    },
                  ],
                  opponent: [
                    {
                      id: "d99f919b-1534-4516-8e8a-9cd106c6d8cd",
                      name: "Rangers",
                      market: "Texas",
                      abbr: "TEX",
                      ab: 18,
                      runs: 2,
                      s: 3,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 2,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 1,
                      cs: 0,
                      obp: 0.3,
                      slg: 0.278,
                      ops: 0.578,
                      h: 4,
                      ktotal: 6,
                      avg: ".222",
                    },
                    {
                      id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                      name: "Twins",
                      market: "Minnesota",
                      abbr: "MIN",
                      ab: 27,
                      runs: 2,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 4,
                      bb: 2,
                      ibb: 1,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.2,
                      slg: 0.333,
                      ops: 0.533,
                      h: 3,
                      ktotal: 5,
                      avg: ".111",
                    },
                    {
                      id: "1d678440-b4b1-4954-9b39-70afb3ebbcfa",
                      name: "Blue Jays",
                      market: "Toronto",
                      abbr: "TOR",
                      ab: 54,
                      runs: 5,
                      s: 7,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 8,
                      bb: 1,
                      ibb: 0,
                      hbp: 2,
                      sb: 0,
                      cs: 0,
                      obp: 0.207,
                      slg: 0.241,
                      ops: 0.448,
                      h: 9,
                      ktotal: 26,
                      avg: ".167",
                    },
                    {
                      id: "f246a5e5-afdb-479c-9aaa-c68beeda7af6",
                      name: "Mets",
                      market: "New York",
                      abbr: "NYM",
                      ab: 13,
                      runs: 1,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.357,
                      slg: 0.538,
                      ops: 0.895,
                      h: 4,
                      ktotal: 5,
                      avg: ".308",
                    },
                    {
                      id: "4f735188-37c8-473d-ae32-1f7e34ccf892",
                      name: "Angels",
                      market: "Los Angeles",
                      abbr: "LAA",
                      ab: 20,
                      runs: 2,
                      s: 4,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 2,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.348,
                      slg: 0.3,
                      ops: 0.648,
                      h: 5,
                      ktotal: 4,
                      avg: ".250",
                    },
                    {
                      id: "bdc11650-6f74-49c4-875e-778aeb7632d9",
                      name: "Rays",
                      market: "Tampa Bay",
                      abbr: "TB",
                      ab: 62,
                      runs: 9,
                      s: 7,
                      d: 1,
                      t: 0,
                      hr: 3,
                      rbi: 7,
                      bb: 6,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.257,
                      slg: 0.339,
                      ops: 0.596,
                      h: 11,
                      ktotal: 24,
                      avg: ".177",
                    },
                    {
                      id: "27a59d3b-ff7c-48ea-b016-4798f560f5e1",
                      name: "Athletics",
                      market: "Oakland",
                      abbr: "OAK",
                      ab: 20,
                      runs: 1,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.05,
                      slg: 0.05,
                      ops: 0.1,
                      h: 1,
                      ktotal: 9,
                      avg: ".050",
                    },
                    {
                      id: "eb21dadd-8f10-4095-8bf3-dfb3b779f107",
                      name: "Astros",
                      market: "Houston",
                      abbr: "HOU",
                      ab: 9,
                      runs: 0,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.2,
                      slg: 0.111,
                      ops: 0.311,
                      h: 1,
                      ktotal: 5,
                      avg: ".111",
                    },
                    {
                      id: "a09ec676-f887-43dc-bbb3-cf4bbaee9a18",
                      name: "Yankees",
                      market: "New York",
                      abbr: "NYY",
                      ab: 59,
                      runs: 5,
                      s: 7,
                      d: 1,
                      t: 0,
                      hr: 2,
                      rbi: 4,
                      bb: 8,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.279,
                      slg: 0.288,
                      ops: 0.567,
                      h: 10,
                      ktotal: 24,
                      avg: ".169",
                    },
                    {
                      id: "833a51a9-0d84-410f-bd77-da08c3e5e26e",
                      name: "Royals",
                      market: "Kansas City",
                      abbr: "KC",
                      ab: 21,
                      runs: 3,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 4,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.524,
                      ops: 0.857,
                      h: 5,
                      ktotal: 8,
                      avg: ".238",
                    },
                    {
                      id: "d89bed32-3aee-4407-99e3-4103641b999a",
                      name: "Nationals",
                      market: "Washington",
                      abbr: "WSH",
                      ab: 7,
                      runs: 0,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.143,
                      slg: 0.143,
                      ops: 0.286,
                      h: 1,
                      ktotal: 3,
                      avg: ".143",
                    },
                    {
                      id: "575c19b7-4052-41c2-9f0a-1c5813d02f99",
                      name: "Tigers",
                      market: "Detroit",
                      abbr: "DET",
                      ab: 13,
                      runs: 1,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 3,
                      bb: 4,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.353,
                      slg: 0.385,
                      ops: 0.738,
                      h: 2,
                      ktotal: 8,
                      avg: ".154",
                    },
                    {
                      id: "2142e1ba-3b40-445c-b8bb-f1f8b1054220",
                      name: "Phillies",
                      market: "Philadelphia",
                      abbr: "PHI",
                      ab: 16,
                      runs: 0,
                      s: 1,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.125,
                      slg: 0.188,
                      ops: 0.313,
                      h: 2,
                      ktotal: 7,
                      avg: ".125",
                    },
                    {
                      id: "12079497-e414-450a-8bf2-29f91de646bf",
                      name: "Braves",
                      market: "Atlanta",
                      abbr: "ATL",
                      ab: 12,
                      runs: 1,
                      s: 0,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 5,
                      bb: 1,
                      ibb: 1,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.267,
                      slg: 0.5,
                      ops: 0.767,
                      h: 2,
                      ktotal: 4,
                      avg: ".167",
                    },
                    {
                      id: "80715d0d-0d2a-450f-a970-1b9a3b18c7e7",
                      name: "Indians",
                      market: "Cleveland",
                      abbr: "CLE",
                      ab: 26,
                      runs: 0,
                      s: 5,
                      d: 2,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.269,
                      slg: 0.346,
                      ops: 0.615,
                      h: 7,
                      ktotal: 7,
                      avg: ".269",
                    },
                    {
                      id: "93941372-eb4c-4c40-aced-fe3267174393",
                      name: "Red Sox",
                      market: "Boston",
                      abbr: "BOS",
                      ab: 50,
                      runs: 5,
                      s: 2,
                      d: 2,
                      t: 0,
                      hr: 1,
                      rbi: 2,
                      bb: 2,
                      ibb: 0,
                      hbp: 1,
                      sb: 1,
                      cs: 0,
                      obp: 0.148,
                      slg: 0.2,
                      ops: 0.348,
                      h: 5,
                      ktotal: 29,
                      avg: ".100",
                    },
                    {
                      id: "43a39081-52b4-4f93-ad29-da7f329ea960",
                      name: "Mariners",
                      market: "Seattle",
                      abbr: "SEA",
                      ab: 23,
                      runs: 2,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 5,
                      bb: 1,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.32,
                      slg: 0.522,
                      ops: 0.842,
                      h: 6,
                      ktotal: 8,
                      avg: ".261",
                    },
                    {
                      id: "47f490cd-2f58-4ef7-9dfd-2ad6ba6c1ae8",
                      name: "White Sox",
                      market: "Chicago",
                      abbr: "CWS",
                      ab: 20,
                      runs: 1,
                      s: 0,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 4,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.24,
                      slg: 0.1,
                      ops: 0.34,
                      h: 1,
                      ktotal: 10,
                      avg: ".050",
                    },
                  ],
                },
              ],
            },
          },
        },
        teams: [
          {
            name: "Orioles",
            market: "Baltimore",
            abbr: "BAL",
            id: "75729d34-bca7-4a0f-b3df-6f26c6ad3719",
            statistics: {
              hitting: {
                overall: {
                  ab: 470,
                  lob: 190,
                  rbi: 49,
                  abhr: 29.375,
                  abk: 2.448,
                  bip: 266,
                  babip: 0.237,
                  bbk: 0.214,
                  bbpa: 0.079,
                  iso: 0.128,
                  obp: 0.243,
                  ops: 0.539,
                  seca: 0.219,
                  slg: 0.296,
                  xbh: 28,
                  pitch_count: 2185,
                  lob_risp_2out: 55,
                  team_lob: 107,
                  ab_risp: 90,
                  hit_risp: 18,
                  rbi_2out: 26,
                  linedrive: 68,
                  groundball: 116,
                  popup: 15,
                  flyball: 83,
                  ap: 522,
                  avg: ".168",
                  gofo: 0.96,
                  onbase: {
                    s: 51,
                    d: 12,
                    t: 0,
                    hr: 16,
                    tb: 139,
                    bb: 39,
                    ibb: 2,
                    hbp: 7,
                    fc: 5,
                    roe: 4,
                    h: 79,
                    cycle: 0,
                  },
                  runs: { total: 40 },
                  outcome: {
                    klook: 382,
                    kswing: 337,
                    ktotal: 719,
                    ball: 762,
                    iball: 0,
                    dirtball: 50,
                    foul: 365,
                  },
                  outs: {
                    po: 15,
                    fo: 62,
                    fidp: 0,
                    lo: 29,
                    lidp: 0,
                    go: 102,
                    gidp: 5,
                    klook: 57,
                    kswing: 135,
                    ktotal: 192,
                    sacfly: 4,
                    sachit: 0,
                  },
                  steal: { caught: 0, stolen: 2, pct: 1.0, pickoff: 0 },
                  pitches: { count: 2185, btotal: 819, ktotal: 1366 },
                  games: { start: 127, play: 128, finish: 1, complete: 123 },
                },
              },
              fielding: {
                overall: {
                  po: 913,
                  a: 67,
                  dp: 98,
                  tp: 0,
                  error: 5,
                  tc: 985,
                  fpct: 0.995,
                  c_wp: 0,
                  pb: 0,
                  rf: 8.448,
                  inn_1: 3030,
                  inn_2: 1010.0,
                  steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                  errors: {
                    throwing: 2,
                    fielding: 3,
                    interference: 0,
                    total: 5,
                  },
                  assists: { outfield: 0, total: 67 },
                  games: { start: 115, play: 116, finish: 1, complete: 0 },
                },
                positions: [
                  {
                    po: 913,
                    a: 67,
                    dp: 98,
                    tp: 0,
                    error: 5,
                    tc: 985,
                    fpct: 0.995,
                    c_wp: 0,
                    pb: 0,
                    rf: 8.448,
                    inn_1: 3030,
                    inn_2: 1010.0,
                    position: "1B",
                    steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                    errors: {
                      throwing: 2,
                      fielding: 3,
                      interference: 0,
                      total: 5,
                    },
                    assists: { outfield: 0, total: 67 },
                    games: { start: 115, play: 116, finish: 1, complete: 0 },
                  },
                ],
              },
            },
            splits: {
              hitting: {
                overall: [
                  {
                    total: [
                      {
                        ab: 470,
                        runs: 40,
                        s: 51,
                        d: 12,
                        t: 0,
                        hr: 16,
                        rbi: 49,
                        bb: 39,
                        ibb: 2,
                        hbp: 7,
                        sb: 2,
                        cs: 0,
                        obp: 0.243,
                        slg: 0.296,
                        ops: 0.539,
                        h: 79,
                        ktotal: 192,
                        avg: ".168",
                      },
                    ],
                    day_night: [
                      {
                        value: "day",
                        ab: 135,
                        runs: 13,
                        s: 14,
                        d: 5,
                        t: 0,
                        hr: 6,
                        rbi: 17,
                        bb: 14,
                        ibb: 1,
                        hbp: 3,
                        sb: 0,
                        cs: 0,
                        obp: 0.279,
                        slg: 0.356,
                        ops: 0.635,
                        h: 25,
                        ktotal: 55,
                        avg: ".185",
                      },
                      {
                        value: "night",
                        ab: 335,
                        runs: 27,
                        s: 37,
                        d: 7,
                        t: 0,
                        hr: 10,
                        rbi: 32,
                        bb: 25,
                        ibb: 1,
                        hbp: 4,
                        sb: 2,
                        cs: 0,
                        obp: 0.228,
                        slg: 0.272,
                        ops: 0.5,
                        h: 54,
                        ktotal: 137,
                        avg: ".161",
                      },
                    ],
                    venue: [
                      {
                        id: "60732da9-ad03-4feb-9a36-aee3e98c7a2b",
                        name: "Angel Stadium of Anaheim",
                        market: "Los Angeles",
                        surface: "grass",
                        ab: 8,
                        runs: 1,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 2,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.545,
                        slg: 0.375,
                        ops: 0.92,
                        h: 3,
                        ktotal: 2,
                        avg: ".375",
                      },
                      {
                        id: "4a550fc1-c03d-4294-b63a-30ff197bedc4",
                        name: "Guaranteed Rate Field",
                        market: "Chicago",
                        surface: "grass",
                        ab: 13,
                        runs: 1,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 3,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.235,
                        slg: 0.0,
                        ops: 0.235,
                        h: 0,
                        ktotal: 6,
                        avg: ".000",
                      },
                      {
                        id: "b27b0dc5-c2c5-401d-b8b9-a6a2dbc225e5",
                        name: "Fenway Park",
                        market: "Boston",
                        surface: "grass",
                        ab: 24,
                        runs: 2,
                        s: 1,
                        d: 2,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.125,
                        slg: 0.208,
                        ops: 0.333,
                        h: 3,
                        ktotal: 15,
                        avg: ".125",
                      },
                      {
                        id: "f1c03dac-3c0f-437c-a325-8d5702cd321a",
                        name: "T-Mobile Park",
                        market: "Seattle",
                        surface: "grass",
                        ab: 9,
                        runs: 0,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.333,
                        ops: 0.666,
                        h: 3,
                        ktotal: 1,
                        avg: ".333",
                      },
                      {
                        id: "434aa63c-1049-4a54-a6bc-3790c7772ee1",
                        name: "Oriole Park at Camden Yards",
                        market: "Baltimore",
                        surface: "grass",
                        ab: 239,
                        runs: 24,
                        s: 26,
                        d: 7,
                        t: 0,
                        hr: 10,
                        rbi: 25,
                        bb: 17,
                        ibb: 1,
                        hbp: 4,
                        sb: 2,
                        cs: 0,
                        obp: 0.248,
                        slg: 0.335,
                        ops: 0.583,
                        h: 43,
                        ktotal: 89,
                        avg: ".180",
                      },
                      {
                        id: "302f8dcd-eed6-4b83-8609-81548d51e955",
                        name: "Target Field",
                        market: "Minnesota",
                        surface: "grass",
                        ab: 15,
                        runs: 2,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 4,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 0.6,
                        ops: 0.85,
                        h: 3,
                        ktotal: 4,
                        avg: ".200",
                      },
                      {
                        id: "f0600f04-1386-4c61-a412-804960c46cb1",
                        name: "Minute Maid Park",
                        market: "Houston",
                        surface: "grass",
                        ab: 9,
                        runs: 0,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.2,
                        slg: 0.111,
                        ops: 0.311,
                        h: 1,
                        ktotal: 5,
                        avg: ".111",
                      },
                      {
                        id: "3aaaf4af-0f8c-49c1-8bf1-1780bb5a5f5c",
                        name: "Tropicana Field",
                        market: "Tampa Bay",
                        surface: "turf",
                        ab: 28,
                        runs: 1,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 2,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.133,
                        slg: 0.107,
                        ops: 0.24,
                        h: 3,
                        ktotal: 13,
                        avg: ".107",
                      },
                      {
                        id: "706e9828-6687-4ac8-a409-3fb972e8bae9",
                        name: "Yankee Stadium",
                        market: "New York",
                        surface: "grass",
                        ab: 26,
                        runs: 2,
                        s: 2,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 4,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.258,
                        slg: 0.231,
                        ops: 0.489,
                        h: 3,
                        ktotal: 11,
                        avg: ".115",
                      },
                      {
                        id: "b4186004-b6d0-42e7-8dc1-82ef0d946ecc",
                        name: "Citizens Bank Park",
                        market: "Philadelphia",
                        surface: "grass",
                        ab: 8,
                        runs: 0,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.125,
                        slg: 0.125,
                        ops: 0.25,
                        h: 1,
                        ktotal: 3,
                        avg: ".125",
                      },
                      {
                        id: "84d72338-2173-4a90-9d25-99adc6c86f4b",
                        name: "Rogers Centre",
                        market: "Toronto",
                        surface: "turf",
                        ab: 23,
                        runs: 2,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 3,
                        bb: 0,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.12,
                        slg: 0.217,
                        ops: 0.337,
                        h: 2,
                        ktotal: 15,
                        avg: ".087",
                      },
                      {
                        id: "3f47c1c6-b059-4fa2-9d85-5d37b7000992",
                        name: "Globe Life Park in Arlington",
                        market: "Texas",
                        surface: "grass",
                        ab: 8,
                        runs: 1,
                        s: 1,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 2,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.375,
                        ops: 0.708,
                        h: 2,
                        ktotal: 3,
                        avg: ".250",
                      },
                      {
                        id: "2b0ccd49-4d87-4996-ac4d-27ffc7ee4c16",
                        name: "Progressive Field",
                        market: "Cleveland",
                        surface: "grass",
                        ab: 11,
                        runs: 0,
                        s: 2,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.273,
                        slg: 0.364,
                        ops: 0.637,
                        h: 3,
                        ktotal: 3,
                        avg: ".273",
                      },
                      {
                        id: "ef9a5eef-06c6-4963-ac70-7fd02c8c8d42",
                        name: "Comerica Park",
                        market: "Detroit",
                        surface: "grass",
                        ab: 9,
                        runs: 1,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 3,
                        bb: 4,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.462,
                        slg: 0.556,
                        ops: 1.018,
                        h: 2,
                        ktotal: 6,
                        avg: ".222",
                      },
                      {
                        id: "48cbd35a-d932-4a75-beab-067bfbacfc26",
                        name: "RingCentral Coliseum",
                        market: "Oakland",
                        surface: "grass",
                        ab: 13,
                        runs: 1,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.077,
                        slg: 0.077,
                        ops: 0.154,
                        h: 1,
                        ktotal: 6,
                        avg: ".077",
                      },
                      {
                        id: "6fca95c9-7f2c-4acb-a9f3-02ef96340d2a",
                        name: "Kauffman Stadium",
                        market: "Kansas City",
                        surface: "grass",
                        ab: 9,
                        runs: 1,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.455,
                        slg: 0.333,
                        ops: 0.788,
                        h: 3,
                        ktotal: 3,
                        avg: ".333",
                      },
                      {
                        id: "265c7d6c-427a-4b8a-8def-392c41954bec",
                        name: "Citi Field",
                        market: "New York",
                        surface: "grass",
                        ab: 6,
                        runs: 0,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.286,
                        slg: 0.167,
                        ops: 0.453,
                        h: 1,
                        ktotal: 3,
                        avg: ".167",
                      },
                      {
                        id: "3ca8b577-5844-42ed-81a5-bd5d5cbe350c",
                        name: "Truist Park",
                        market: "Atlanta",
                        surface: "grass",
                        ab: 12,
                        runs: 1,
                        s: 0,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 5,
                        bb: 1,
                        ibb: 1,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.267,
                        slg: 0.5,
                        ops: 0.767,
                        h: 2,
                        ktotal: 4,
                        avg: ".167",
                      },
                    ],
                    surface: [
                      {
                        value: "grass",
                        ab: 419,
                        runs: 37,
                        s: 47,
                        d: 12,
                        t: 0,
                        hr: 15,
                        rbi: 44,
                        bb: 38,
                        ibb: 2,
                        hbp: 6,
                        sb: 2,
                        cs: 0,
                        obp: 0.257,
                        slg: 0.313,
                        ops: 0.57,
                        h: 74,
                        ktotal: 164,
                        avg: ".177",
                      },
                      {
                        value: "turf",
                        ab: 51,
                        runs: 3,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 5,
                        bb: 1,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.127,
                        slg: 0.157,
                        ops: 0.284,
                        h: 5,
                        ktotal: 28,
                        avg: ".098",
                      },
                    ],
                    month: [
                      {
                        value: "8",
                        ab: 93,
                        runs: 10,
                        s: 15,
                        d: 3,
                        t: 0,
                        hr: 3,
                        rbi: 13,
                        bb: 7,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.282,
                        slg: 0.355,
                        ops: 0.637,
                        h: 21,
                        ktotal: 41,
                        avg: ".226",
                      },
                      {
                        value: "3",
                        ab: 9,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 1,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.1,
                        slg: 0.0,
                        ops: 0.1,
                        h: 0,
                        ktotal: 1,
                        avg: ".000",
                      },
                      {
                        value: "4",
                        ab: 81,
                        runs: 3,
                        s: 11,
                        d: 2,
                        t: 0,
                        hr: 2,
                        rbi: 6,
                        bb: 9,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.275,
                        slg: 0.284,
                        ops: 0.559,
                        h: 15,
                        ktotal: 32,
                        avg: ".185",
                      },
                      {
                        value: "6",
                        ab: 61,
                        runs: 4,
                        s: 5,
                        d: 1,
                        t: 0,
                        hr: 3,
                        rbi: 9,
                        bb: 3,
                        ibb: 1,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.209,
                        slg: 0.311,
                        ops: 0.52,
                        h: 9,
                        ktotal: 27,
                        avg: ".148",
                      },
                      {
                        value: "7",
                        ab: 83,
                        runs: 15,
                        s: 5,
                        d: 3,
                        t: 0,
                        hr: 6,
                        rbi: 11,
                        bb: 10,
                        ibb: 0,
                        hbp: 2,
                        sb: 2,
                        cs: 0,
                        obp: 0.274,
                        slg: 0.422,
                        ops: 0.696,
                        h: 14,
                        ktotal: 28,
                        avg: ".169",
                      },
                      {
                        value: "5",
                        ab: 90,
                        runs: 6,
                        s: 9,
                        d: 2,
                        t: 0,
                        hr: 2,
                        rbi: 9,
                        bb: 8,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.22,
                        slg: 0.233,
                        ops: 0.453,
                        h: 13,
                        ktotal: 39,
                        avg: ".144",
                      },
                      {
                        value: "9",
                        ab: 53,
                        runs: 2,
                        s: 6,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 2,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.179,
                        slg: 0.151,
                        ops: 0.33,
                        h: 7,
                        ktotal: 24,
                        avg: ".132",
                      },
                    ],
                    home_away: [
                      {
                        value: "home",
                        ab: 239,
                        runs: 24,
                        s: 26,
                        d: 7,
                        t: 0,
                        hr: 10,
                        rbi: 25,
                        bb: 17,
                        ibb: 1,
                        hbp: 4,
                        sb: 2,
                        cs: 0,
                        obp: 0.248,
                        slg: 0.335,
                        ops: 0.583,
                        h: 43,
                        ktotal: 89,
                        avg: ".180",
                      },
                      {
                        value: "away",
                        ab: 231,
                        runs: 16,
                        s: 25,
                        d: 5,
                        t: 0,
                        hr: 6,
                        rbi: 24,
                        bb: 22,
                        ibb: 1,
                        hbp: 3,
                        sb: 0,
                        cs: 0,
                        obp: 0.238,
                        slg: 0.255,
                        ops: 0.493,
                        h: 36,
                        ktotal: 103,
                        avg: ".156",
                      },
                    ],
                    pitcher_hand: [
                      {
                        value: "R",
                        ab: 338,
                        runs: 31,
                        s: 40,
                        d: 9,
                        t: 0,
                        hr: 11,
                        rbi: 34,
                        bb: 28,
                        ibb: 2,
                        hbp: 6,
                        sb: 1,
                        cs: 0,
                        obp: 0.255,
                        slg: 0.302,
                        ops: 0.557,
                        h: 60,
                        ktotal: 138,
                        avg: ".178",
                      },
                      {
                        value: "L",
                        ab: 132,
                        runs: 9,
                        s: 11,
                        d: 3,
                        t: 0,
                        hr: 5,
                        rbi: 15,
                        bb: 11,
                        ibb: 0,
                        hbp: 1,
                        sb: 1,
                        cs: 0,
                        obp: 0.212,
                        slg: 0.28,
                        ops: 0.492,
                        h: 19,
                        ktotal: 54,
                        avg: ".144",
                      },
                    ],
                    opponent: [
                      {
                        id: "d99f919b-1534-4516-8e8a-9cd106c6d8cd",
                        name: "Rangers",
                        market: "Texas",
                        abbr: "TEX",
                        ab: 18,
                        runs: 2,
                        s: 3,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 2,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 1,
                        cs: 0,
                        obp: 0.3,
                        slg: 0.278,
                        ops: 0.578,
                        h: 4,
                        ktotal: 6,
                        avg: ".222",
                      },
                      {
                        id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                        name: "Twins",
                        market: "Minnesota",
                        abbr: "MIN",
                        ab: 27,
                        runs: 2,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 4,
                        bb: 2,
                        ibb: 1,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.2,
                        slg: 0.333,
                        ops: 0.533,
                        h: 3,
                        ktotal: 5,
                        avg: ".111",
                      },
                      {
                        id: "1d678440-b4b1-4954-9b39-70afb3ebbcfa",
                        name: "Blue Jays",
                        market: "Toronto",
                        abbr: "TOR",
                        ab: 54,
                        runs: 5,
                        s: 7,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 8,
                        bb: 1,
                        ibb: 0,
                        hbp: 2,
                        sb: 0,
                        cs: 0,
                        obp: 0.207,
                        slg: 0.241,
                        ops: 0.448,
                        h: 9,
                        ktotal: 26,
                        avg: ".167",
                      },
                      {
                        id: "f246a5e5-afdb-479c-9aaa-c68beeda7af6",
                        name: "Mets",
                        market: "New York",
                        abbr: "NYM",
                        ab: 13,
                        runs: 1,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.357,
                        slg: 0.538,
                        ops: 0.895,
                        h: 4,
                        ktotal: 5,
                        avg: ".308",
                      },
                      {
                        id: "4f735188-37c8-473d-ae32-1f7e34ccf892",
                        name: "Angels",
                        market: "Los Angeles",
                        abbr: "LAA",
                        ab: 20,
                        runs: 2,
                        s: 4,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 2,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.348,
                        slg: 0.3,
                        ops: 0.648,
                        h: 5,
                        ktotal: 4,
                        avg: ".250",
                      },
                      {
                        id: "bdc11650-6f74-49c4-875e-778aeb7632d9",
                        name: "Rays",
                        market: "Tampa Bay",
                        abbr: "TB",
                        ab: 62,
                        runs: 9,
                        s: 7,
                        d: 1,
                        t: 0,
                        hr: 3,
                        rbi: 7,
                        bb: 6,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.257,
                        slg: 0.339,
                        ops: 0.596,
                        h: 11,
                        ktotal: 24,
                        avg: ".177",
                      },
                      {
                        id: "27a59d3b-ff7c-48ea-b016-4798f560f5e1",
                        name: "Athletics",
                        market: "Oakland",
                        abbr: "OAK",
                        ab: 20,
                        runs: 1,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.05,
                        slg: 0.05,
                        ops: 0.1,
                        h: 1,
                        ktotal: 9,
                        avg: ".050",
                      },
                      {
                        id: "eb21dadd-8f10-4095-8bf3-dfb3b779f107",
                        name: "Astros",
                        market: "Houston",
                        abbr: "HOU",
                        ab: 9,
                        runs: 0,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.2,
                        slg: 0.111,
                        ops: 0.311,
                        h: 1,
                        ktotal: 5,
                        avg: ".111",
                      },
                      {
                        id: "a09ec676-f887-43dc-bbb3-cf4bbaee9a18",
                        name: "Yankees",
                        market: "New York",
                        abbr: "NYY",
                        ab: 59,
                        runs: 5,
                        s: 7,
                        d: 1,
                        t: 0,
                        hr: 2,
                        rbi: 4,
                        bb: 8,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.279,
                        slg: 0.288,
                        ops: 0.567,
                        h: 10,
                        ktotal: 24,
                        avg: ".169",
                      },
                      {
                        id: "833a51a9-0d84-410f-bd77-da08c3e5e26e",
                        name: "Royals",
                        market: "Kansas City",
                        abbr: "KC",
                        ab: 21,
                        runs: 3,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 4,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.524,
                        ops: 0.857,
                        h: 5,
                        ktotal: 8,
                        avg: ".238",
                      },
                      {
                        id: "d89bed32-3aee-4407-99e3-4103641b999a",
                        name: "Nationals",
                        market: "Washington",
                        abbr: "WSH",
                        ab: 7,
                        runs: 0,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.143,
                        slg: 0.143,
                        ops: 0.286,
                        h: 1,
                        ktotal: 3,
                        avg: ".143",
                      },
                      {
                        id: "575c19b7-4052-41c2-9f0a-1c5813d02f99",
                        name: "Tigers",
                        market: "Detroit",
                        abbr: "DET",
                        ab: 13,
                        runs: 1,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 3,
                        bb: 4,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.353,
                        slg: 0.385,
                        ops: 0.738,
                        h: 2,
                        ktotal: 8,
                        avg: ".154",
                      },
                      {
                        id: "2142e1ba-3b40-445c-b8bb-f1f8b1054220",
                        name: "Phillies",
                        market: "Philadelphia",
                        abbr: "PHI",
                        ab: 16,
                        runs: 0,
                        s: 1,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.125,
                        slg: 0.188,
                        ops: 0.313,
                        h: 2,
                        ktotal: 7,
                        avg: ".125",
                      },
                      {
                        id: "12079497-e414-450a-8bf2-29f91de646bf",
                        name: "Braves",
                        market: "Atlanta",
                        abbr: "ATL",
                        ab: 12,
                        runs: 1,
                        s: 0,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 5,
                        bb: 1,
                        ibb: 1,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.267,
                        slg: 0.5,
                        ops: 0.767,
                        h: 2,
                        ktotal: 4,
                        avg: ".167",
                      },
                      {
                        id: "80715d0d-0d2a-450f-a970-1b9a3b18c7e7",
                        name: "Indians",
                        market: "Cleveland",
                        abbr: "CLE",
                        ab: 26,
                        runs: 0,
                        s: 5,
                        d: 2,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.269,
                        slg: 0.346,
                        ops: 0.615,
                        h: 7,
                        ktotal: 7,
                        avg: ".269",
                      },
                      {
                        id: "93941372-eb4c-4c40-aced-fe3267174393",
                        name: "Red Sox",
                        market: "Boston",
                        abbr: "BOS",
                        ab: 50,
                        runs: 5,
                        s: 2,
                        d: 2,
                        t: 0,
                        hr: 1,
                        rbi: 2,
                        bb: 2,
                        ibb: 0,
                        hbp: 1,
                        sb: 1,
                        cs: 0,
                        obp: 0.148,
                        slg: 0.2,
                        ops: 0.348,
                        h: 5,
                        ktotal: 29,
                        avg: ".100",
                      },
                      {
                        id: "43a39081-52b4-4f93-ad29-da7f329ea960",
                        name: "Mariners",
                        market: "Seattle",
                        abbr: "SEA",
                        ab: 23,
                        runs: 2,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 5,
                        bb: 1,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.32,
                        slg: 0.522,
                        ops: 0.842,
                        h: 6,
                        ktotal: 8,
                        avg: ".261",
                      },
                      {
                        id: "47f490cd-2f58-4ef7-9dfd-2ad6ba6c1ae8",
                        name: "White Sox",
                        market: "Chicago",
                        abbr: "CWS",
                        ab: 20,
                        runs: 1,
                        s: 0,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 4,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.24,
                        slg: 0.1,
                        ops: 0.34,
                        h: 1,
                        ktotal: 10,
                        avg: ".050",
                      },
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
      {
        id: "a0d35b04-59fb-465a-8dce-f2c0894e753c",
        year: 2017,
        type: "REG",
        totals: {
          statistics: {
            hitting: {
              overall: {
                ab: 456,
                lob: 194,
                rbi: 61,
                abhr: 17.538,
                abk: 2.338,
                bip: 239,
                babip: 0.301,
                bbk: 0.313,
                bbpa: 0.116,
                iso: 0.208,
                obp: 0.309,
                ops: 0.732,
                seca: 0.342,
                slg: 0.423,
                xbh: 42,
                pitch_count: 2118,
                lob_risp_2out: 38,
                team_lob: 81,
                ab_risp: 93,
                hit_risp: 22,
                rbi_2out: 14,
                linedrive: 78,
                groundball: 97,
                popup: 13,
                flyball: 77,
                ap: 524,
                avg: ".215",
                gofo: 1.05,
                onbase: {
                  s: 56,
                  d: 15,
                  t: 1,
                  hr: 26,
                  tb: 193,
                  bb: 57,
                  ibb: 4,
                  hbp: 3,
                  fc: 3,
                  roe: 0,
                  h: 98,
                  cycle: 0,
                },
                runs: { total: 65 },
                outcome: {
                  klook: 410,
                  kswing: 318,
                  ktotal: 728,
                  ball: 751,
                  iball: 0,
                  dirtball: 46,
                  foul: 325,
                },
                outs: {
                  po: 13,
                  fo: 43,
                  fidp: 0,
                  lo: 29,
                  lidp: 0,
                  go: 89,
                  gidp: 7,
                  klook: 75,
                  kswing: 120,
                  ktotal: 195,
                  sacfly: 4,
                  sachit: 0,
                },
                steal: { caught: 1, stolen: 1, pct: 0.5, pickoff: 0 },
                games: { start: 126, play: 128, finish: 1, complete: 118 },
              },
            },
            fielding: {
              overall: {
                po: 1015,
                a: 80,
                dp: 122,
                tp: 2,
                error: 7,
                tc: 1102,
                fpct: 0.994,
                c_wp: 0,
                pb: 0,
                rf: 8.622,
                steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                errors: { throwing: 1, fielding: 6, interference: 0, total: 7 },
                games: { start: 125, play: 127, finish: 1, complete: 0 },
              },
            },
          },
          splits: {
            hitting: {
              overall: [
                {
                  total: [
                    {
                      ab: 456,
                      runs: 65,
                      s: 56,
                      d: 15,
                      t: 1,
                      hr: 26,
                      rbi: 61,
                      bb: 57,
                      ibb: 4,
                      hbp: 3,
                      sb: 1,
                      cs: 1,
                      obp: 0.309,
                      slg: 0.423,
                      ops: 0.732,
                      h: 98,
                      ktotal: 195,
                      avg: ".215",
                    },
                  ],
                  day_night: [
                    {
                      value: "night",
                      ab: 319,
                      runs: 45,
                      s: 36,
                      d: 11,
                      t: 1,
                      hr: 18,
                      rbi: 40,
                      bb: 43,
                      ibb: 2,
                      hbp: 3,
                      sb: 0,
                      cs: 1,
                      obp: 0.309,
                      slg: 0.417,
                      ops: 0.726,
                      h: 66,
                      ktotal: 139,
                      avg: ".207",
                    },
                    {
                      value: "day",
                      ab: 137,
                      runs: 20,
                      s: 20,
                      d: 4,
                      t: 0,
                      hr: 8,
                      rbi: 21,
                      bb: 14,
                      ibb: 2,
                      hbp: 0,
                      sb: 1,
                      cs: 0,
                      obp: 0.31,
                      slg: 0.438,
                      ops: 0.748,
                      h: 32,
                      ktotal: 56,
                      avg: ".234",
                    },
                  ],
                  venue: [
                    {
                      id: "2b0ccd49-4d87-4996-ac4d-27ffc7ee4c16",
                      name: "Progressive Field",
                      market: "Cleveland",
                      surface: "grass",
                      ab: 9,
                      runs: 1,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.2,
                      slg: 0.444,
                      ops: 0.644,
                      h: 1,
                      ktotal: 2,
                      avg: ".111",
                    },
                    {
                      id: "ef9a5eef-06c6-4963-ac70-7fd02c8c8d42",
                      name: "Comerica Park",
                      market: "Detroit",
                      surface: "grass",
                      ab: 12,
                      runs: 5,
                      s: 0,
                      d: 2,
                      t: 0,
                      hr: 3,
                      rbi: 6,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.533,
                      slg: 1.333,
                      ops: 1.866,
                      h: 5,
                      ktotal: 5,
                      avg: ".417",
                    },
                    {
                      id: "60732da9-ad03-4feb-9a36-aee3e98c7a2b",
                      name: "Angel Stadium of Anaheim",
                      market: "Los Angeles",
                      surface: "grass",
                      ab: 11,
                      runs: 0,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.167,
                      slg: 0.091,
                      ops: 0.258,
                      h: 1,
                      ktotal: 3,
                      avg: ".091",
                    },
                    {
                      id: "f0600f04-1386-4c61-a412-804960c46cb1",
                      name: "Minute Maid Park",
                      market: "Houston",
                      surface: "grass",
                      ab: 12,
                      runs: 1,
                      s: 0,
                      d: 0,
                      t: 1,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.083,
                      slg: 0.25,
                      ops: 0.333,
                      h: 1,
                      ktotal: 8,
                      avg: ".083",
                    },
                    {
                      id: "61314394-c8b8-411e-b891-ca41285d5362",
                      name: "PNC Park",
                      market: "Pittsburgh",
                      surface: "grass",
                      ab: 3,
                      runs: 1,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 1.333,
                      ops: 1.666,
                      h: 1,
                      ktotal: 1,
                      avg: ".333",
                    },
                    {
                      id: "4a550fc1-c03d-4294-b63a-30ff197bedc4",
                      name: "Guaranteed Rate Field",
                      market: "Chicago",
                      surface: "grass",
                      ab: 2,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.0,
                      slg: 0.0,
                      ops: 0.0,
                      h: 0,
                      ktotal: 0,
                      avg: ".000",
                    },
                    {
                      id: "434aa63c-1049-4a54-a6bc-3790c7772ee1",
                      name: "Oriole Park at Camden Yards",
                      market: "Baltimore",
                      surface: "grass",
                      ab: 253,
                      runs: 34,
                      s: 32,
                      d: 7,
                      t: 0,
                      hr: 15,
                      rbi: 37,
                      bb: 29,
                      ibb: 3,
                      hbp: 1,
                      sb: 1,
                      cs: 1,
                      obp: 0.302,
                      slg: 0.419,
                      ops: 0.721,
                      h: 54,
                      ktotal: 107,
                      avg: ".213",
                    },
                    {
                      id: "48cbd35a-d932-4a75-beab-067bfbacfc26",
                      name: "RingCentral Coliseum",
                      market: "Oakland",
                      surface: "grass",
                      ab: 11,
                      runs: 0,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 3,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.385,
                      slg: 0.273,
                      ops: 0.658,
                      h: 3,
                      ktotal: 4,
                      avg: ".273",
                    },
                    {
                      id: "84d72338-2173-4a90-9d25-99adc6c86f4b",
                      name: "Rogers Centre",
                      market: "Toronto",
                      surface: "turf",
                      ab: 25,
                      runs: 3,
                      s: 2,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 4,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.276,
                      slg: 0.32,
                      ops: 0.596,
                      h: 4,
                      ktotal: 14,
                      avg: ".160",
                    },
                    {
                      id: "b27b0dc5-c2c5-401d-b8b9-a6a2dbc225e5",
                      name: "Fenway Park",
                      market: "Boston",
                      surface: "grass",
                      ab: 33,
                      runs: 6,
                      s: 6,
                      d: 1,
                      t: 0,
                      hr: 2,
                      rbi: 4,
                      bb: 5,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.35,
                      slg: 0.485,
                      ops: 0.835,
                      h: 9,
                      ktotal: 13,
                      avg: ".273",
                    },
                    {
                      id: "706e9828-6687-4ac8-a409-3fb972e8bae9",
                      name: "Yankee Stadium",
                      market: "New York",
                      surface: "grass",
                      ab: 26,
                      runs: 5,
                      s: 2,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 3,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.267,
                      slg: 0.308,
                      ops: 0.575,
                      h: 4,
                      ktotal: 12,
                      avg: ".154",
                    },
                    {
                      id: "3aaaf4af-0f8c-49c1-8bf1-1780bb5a5f5c",
                      name: "Tropicana Field",
                      market: "Tampa Bay",
                      surface: "turf",
                      ab: 12,
                      runs: 0,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.267,
                      slg: 0.083,
                      ops: 0.35,
                      h: 1,
                      ktotal: 8,
                      avg: ".083",
                    },
                    {
                      id: "6fca95c9-7f2c-4acb-a9f3-02ef96340d2a",
                      name: "Kauffman Stadium",
                      market: "Kansas City",
                      surface: "grass",
                      ab: 6,
                      runs: 3,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 3,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.667,
                      slg: 1.5,
                      ops: 2.167,
                      h: 3,
                      ktotal: 2,
                      avg: ".500",
                    },
                    {
                      id: "3f47c1c6-b059-4fa2-9d85-5d37b7000992",
                      name: "Globe Life Park in Arlington",
                      market: "Texas",
                      surface: "grass",
                      ab: 8,
                      runs: 3,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 2,
                      bb: 3,
                      ibb: 1,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.692,
                      slg: 0.5,
                      ops: 1.192,
                      h: 4,
                      ktotal: 3,
                      avg: ".500",
                    },
                    {
                      id: "bb062fb1-e268-412e-b3d2-2ddb6acc1822",
                      name: "Nationals Park",
                      market: "Washington",
                      surface: "grass",
                      ab: 8,
                      runs: 1,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.125,
                      slg: 0.125,
                      ops: 0.25,
                      h: 1,
                      ktotal: 3,
                      avg: ".125",
                    },
                    {
                      id: "f102d8fb-de67-4b86-9053-8b55f578d45c",
                      name: "Great American Ball Park",
                      market: "Cincinnati",
                      surface: "grass",
                      ab: 12,
                      runs: 0,
                      s: 0,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.083,
                      slg: 0.167,
                      ops: 0.25,
                      h: 1,
                      ktotal: 5,
                      avg: ".083",
                    },
                    {
                      id: "f1c03dac-3c0f-437c-a325-8d5702cd321a",
                      name: "T-Mobile Park",
                      market: "Seattle",
                      surface: "grass",
                      ab: 13,
                      runs: 2,
                      s: 3,
                      d: 2,
                      t: 0,
                      hr: 0,
                      rbi: 2,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.385,
                      slg: 0.538,
                      ops: 0.923,
                      h: 5,
                      ktotal: 5,
                      avg: ".385",
                    },
                  ],
                  surface: [
                    {
                      value: "turf",
                      ab: 37,
                      runs: 3,
                      s: 3,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 7,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.273,
                      slg: 0.243,
                      ops: 0.516,
                      h: 5,
                      ktotal: 22,
                      avg: ".135",
                    },
                    {
                      value: "grass",
                      ab: 419,
                      runs: 62,
                      s: 53,
                      d: 14,
                      t: 1,
                      hr: 25,
                      rbi: 60,
                      bb: 50,
                      ibb: 4,
                      hbp: 3,
                      sb: 1,
                      cs: 1,
                      obp: 0.313,
                      slg: 0.439,
                      ops: 0.752,
                      h: 93,
                      ktotal: 173,
                      avg: ".222",
                    },
                  ],
                  month: [
                    {
                      value: "5",
                      ab: 99,
                      runs: 16,
                      s: 11,
                      d: 2,
                      t: 1,
                      hr: 8,
                      rbi: 16,
                      bb: 14,
                      ibb: 1,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.319,
                      slg: 0.505,
                      ops: 0.824,
                      h: 22,
                      ktotal: 46,
                      avg: ".222",
                    },
                    {
                      value: "7",
                      ab: 52,
                      runs: 7,
                      s: 7,
                      d: 1,
                      t: 0,
                      hr: 3,
                      rbi: 11,
                      bb: 7,
                      ibb: 1,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.323,
                      slg: 0.404,
                      ops: 0.727,
                      h: 11,
                      ktotal: 22,
                      avg: ".212",
                    },
                    {
                      value: "8",
                      ab: 99,
                      runs: 16,
                      s: 15,
                      d: 4,
                      t: 0,
                      hr: 5,
                      rbi: 16,
                      bb: 12,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 1,
                      obp: 0.321,
                      slg: 0.434,
                      ops: 0.755,
                      h: 24,
                      ktotal: 38,
                      avg: ".242",
                    },
                    {
                      value: "4",
                      ab: 80,
                      runs: 11,
                      s: 11,
                      d: 5,
                      t: 0,
                      hr: 3,
                      rbi: 5,
                      bb: 11,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.337,
                      slg: 0.413,
                      ops: 0.75,
                      h: 19,
                      ktotal: 33,
                      avg: ".238",
                    },
                    {
                      value: "10",
                      ab: 3,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.0,
                      slg: 0.0,
                      ops: 0.0,
                      h: 0,
                      ktotal: 2,
                      avg: ".000",
                    },
                    {
                      value: "9",
                      ab: 85,
                      runs: 8,
                      s: 7,
                      d: 3,
                      t: 0,
                      hr: 4,
                      rbi: 8,
                      bb: 9,
                      ibb: 2,
                      hbp: 1,
                      sb: 1,
                      cs: 0,
                      obp: 0.268,
                      slg: 0.341,
                      ops: 0.609,
                      h: 14,
                      ktotal: 38,
                      avg: ".165",
                    },
                    {
                      value: "6",
                      ab: 38,
                      runs: 7,
                      s: 5,
                      d: 0,
                      t: 0,
                      hr: 3,
                      rbi: 5,
                      bb: 4,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.286,
                      slg: 0.447,
                      ops: 0.733,
                      h: 8,
                      ktotal: 16,
                      avg: ".211",
                    },
                  ],
                  home_away: [
                    {
                      value: "home",
                      ab: 253,
                      runs: 34,
                      s: 32,
                      d: 7,
                      t: 0,
                      hr: 15,
                      rbi: 37,
                      bb: 29,
                      ibb: 3,
                      hbp: 1,
                      sb: 1,
                      cs: 1,
                      obp: 0.302,
                      slg: 0.419,
                      ops: 0.721,
                      h: 54,
                      ktotal: 107,
                      avg: ".213",
                    },
                    {
                      value: "away",
                      ab: 203,
                      runs: 31,
                      s: 24,
                      d: 8,
                      t: 1,
                      hr: 11,
                      rbi: 24,
                      bb: 28,
                      ibb: 1,
                      hbp: 2,
                      sb: 0,
                      cs: 0,
                      obp: 0.318,
                      slg: 0.429,
                      ops: 0.747,
                      h: 44,
                      ktotal: 88,
                      avg: ".217",
                    },
                  ],
                  pitcher_hand: [
                    {
                      value: "R",
                      ab: 312,
                      runs: 49,
                      s: 34,
                      d: 12,
                      t: 0,
                      hr: 22,
                      rbi: 46,
                      bb: 41,
                      ibb: 4,
                      hbp: 1,
                      sb: 1,
                      cs: 0,
                      obp: 0.317,
                      slg: 0.468,
                      ops: 0.785,
                      h: 68,
                      ktotal: 130,
                      avg: ".218",
                    },
                    {
                      value: "L",
                      ab: 144,
                      runs: 16,
                      s: 22,
                      d: 3,
                      t: 1,
                      hr: 4,
                      rbi: 15,
                      bb: 16,
                      ibb: 0,
                      hbp: 2,
                      sb: 0,
                      cs: 1,
                      obp: 0.293,
                      slg: 0.326,
                      ops: 0.619,
                      h: 30,
                      ktotal: 65,
                      avg: ".208",
                    },
                  ],
                  opponent: [
                    {
                      id: "27a59d3b-ff7c-48ea-b016-4798f560f5e1",
                      name: "Athletics",
                      market: "Oakland",
                      abbr: "OAK",
                      ab: 21,
                      runs: 2,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 4,
                      bb: 5,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.385,
                      slg: 0.381,
                      ops: 0.766,
                      h: 5,
                      ktotal: 9,
                      avg: ".238",
                    },
                    {
                      id: "1d678440-b4b1-4954-9b39-70afb3ebbcfa",
                      name: "Blue Jays",
                      market: "Toronto",
                      abbr: "TOR",
                      ab: 64,
                      runs: 7,
                      s: 5,
                      d: 2,
                      t: 0,
                      hr: 3,
                      rbi: 5,
                      bb: 4,
                      ibb: 2,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.239,
                      slg: 0.328,
                      ops: 0.567,
                      h: 10,
                      ktotal: 33,
                      avg: ".156",
                    },
                    {
                      id: "a09ec676-f887-43dc-bbb3-cf4bbaee9a18",
                      name: "Yankees",
                      market: "New York",
                      abbr: "NYY",
                      ab: 59,
                      runs: 8,
                      s: 8,
                      d: 2,
                      t: 0,
                      hr: 3,
                      rbi: 6,
                      bb: 5,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.292,
                      slg: 0.407,
                      ops: 0.699,
                      h: 13,
                      ktotal: 26,
                      avg: ".220",
                    },
                    {
                      id: "93941372-eb4c-4c40-aced-fe3267174393",
                      name: "Red Sox",
                      market: "Boston",
                      abbr: "BOS",
                      ab: 69,
                      runs: 10,
                      s: 11,
                      d: 3,
                      t: 0,
                      hr: 3,
                      rbi: 7,
                      bb: 9,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.325,
                      slg: 0.42,
                      ops: 0.745,
                      h: 17,
                      ktotal: 26,
                      avg: ".246",
                    },
                    {
                      id: "833a51a9-0d84-410f-bd77-da08c3e5e26e",
                      name: "Royals",
                      market: "Kansas City",
                      abbr: "KC",
                      ab: 18,
                      runs: 4,
                      s: 2,
                      d: 1,
                      t: 0,
                      hr: 2,
                      rbi: 5,
                      bb: 4,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.409,
                      slg: 0.667,
                      ops: 1.076,
                      h: 5,
                      ktotal: 6,
                      avg: ".278",
                    },
                    {
                      id: "481dfe7e-5dab-46ab-a49f-9dcc2b6e2cfd",
                      name: "Pirates",
                      market: "Pittsburgh",
                      abbr: "PIT",
                      ab: 11,
                      runs: 4,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 2,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.818,
                      ops: 1.151,
                      h: 3,
                      ktotal: 5,
                      avg: ".273",
                    },
                    {
                      id: "c874a065-c115-4e7d-b0f0-235584fb0e6f",
                      name: "Reds",
                      market: "Cincinnati",
                      abbr: "CIN",
                      ab: 12,
                      runs: 0,
                      s: 0,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.083,
                      slg: 0.167,
                      ops: 0.25,
                      h: 1,
                      ktotal: 5,
                      avg: ".083",
                    },
                    {
                      id: "47f490cd-2f58-4ef7-9dfd-2ad6ba6c1ae8",
                      name: "White Sox",
                      market: "Chicago",
                      abbr: "CWS",
                      ab: 11,
                      runs: 2,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 2,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.429,
                      slg: 0.636,
                      ops: 1.065,
                      h: 4,
                      ktotal: 3,
                      avg: ".364",
                    },
                    {
                      id: "43a39081-52b4-4f93-ad29-da7f329ea960",
                      name: "Mariners",
                      market: "Seattle",
                      abbr: "SEA",
                      ab: 24,
                      runs: 5,
                      s: 5,
                      d: 3,
                      t: 0,
                      hr: 0,
                      rbi: 3,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.36,
                      slg: 0.458,
                      ops: 0.818,
                      h: 8,
                      ktotal: 11,
                      avg: ".333",
                    },
                    {
                      id: "80715d0d-0d2a-450f-a970-1b9a3b18c7e7",
                      name: "Indians",
                      market: "Cleveland",
                      abbr: "CLE",
                      ab: 9,
                      runs: 1,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.2,
                      slg: 0.444,
                      ops: 0.644,
                      h: 1,
                      ktotal: 2,
                      avg: ".111",
                    },
                    {
                      id: "575c19b7-4052-41c2-9f0a-1c5813d02f99",
                      name: "Tigers",
                      market: "Detroit",
                      abbr: "DET",
                      ab: 26,
                      runs: 7,
                      s: 0,
                      d: 2,
                      t: 0,
                      hr: 4,
                      rbi: 8,
                      bb: 6,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 1,
                      obp: 0.375,
                      slg: 0.769,
                      ops: 1.144,
                      h: 6,
                      ktotal: 11,
                      avg: ".231",
                    },
                    {
                      id: "bdc11650-6f74-49c4-875e-778aeb7632d9",
                      name: "Rays",
                      market: "Tampa Bay",
                      abbr: "TB",
                      ab: 35,
                      runs: 3,
                      s: 7,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 5,
                      bb: 9,
                      ibb: 0,
                      hbp: 0,
                      sb: 1,
                      cs: 0,
                      obp: 0.386,
                      slg: 0.314,
                      ops: 0.7,
                      h: 8,
                      ktotal: 17,
                      avg: ".229",
                    },
                    {
                      id: "eb21dadd-8f10-4095-8bf3-dfb3b779f107",
                      name: "Astros",
                      market: "Houston",
                      abbr: "HOU",
                      ab: 23,
                      runs: 2,
                      s: 2,
                      d: 0,
                      t: 1,
                      hr: 0,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.16,
                      slg: 0.217,
                      ops: 0.377,
                      h: 3,
                      ktotal: 13,
                      avg: ".130",
                    },
                    {
                      id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                      name: "Twins",
                      market: "Minnesota",
                      abbr: "MIN",
                      ab: 10,
                      runs: 1,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.182,
                      slg: 0.4,
                      ops: 0.582,
                      h: 1,
                      ktotal: 3,
                      avg: ".100",
                    },
                    {
                      id: "4f735188-37c8-473d-ae32-1f7e34ccf892",
                      name: "Angels",
                      market: "Los Angeles",
                      abbr: "LAA",
                      ab: 15,
                      runs: 1,
                      s: 2,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 0.4,
                      ops: 0.65,
                      h: 3,
                      ktotal: 5,
                      avg: ".200",
                    },
                    {
                      id: "d99f919b-1534-4516-8e8a-9cd106c6d8cd",
                      name: "Rangers",
                      market: "Texas",
                      abbr: "TEX",
                      ab: 23,
                      runs: 6,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 3,
                      rbi: 9,
                      bb: 4,
                      ibb: 1,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.448,
                      slg: 0.696,
                      ops: 1.144,
                      h: 7,
                      ktotal: 9,
                      avg: ".304",
                    },
                    {
                      id: "d89bed32-3aee-4407-99e3-4103641b999a",
                      name: "Nationals",
                      market: "Washington",
                      abbr: "WSH",
                      ab: 15,
                      runs: 2,
                      s: 2,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 2,
                      ibb: 1,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.278,
                      slg: 0.133,
                      ops: 0.411,
                      h: 2,
                      ktotal: 6,
                      avg: ".133",
                    },
                    {
                      id: "55714da8-fcaf-4574-8443-59bfb511a524",
                      name: "Cubs",
                      market: "Chicago",
                      abbr: "CHC",
                      ab: 11,
                      runs: 0,
                      s: 0,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.167,
                      slg: 0.182,
                      ops: 0.349,
                      h: 1,
                      ktotal: 5,
                      avg: ".091",
                    },
                  ],
                },
              ],
            },
          },
        },
        teams: [
          {
            name: "Orioles",
            market: "Baltimore",
            abbr: "BAL",
            id: "75729d34-bca7-4a0f-b3df-6f26c6ad3719",
            statistics: {
              hitting: {
                overall: {
                  ab: 456,
                  lob: 194,
                  rbi: 61,
                  abhr: 17.538,
                  abk: 2.338,
                  bip: 239,
                  babip: 0.301,
                  bbk: 0.313,
                  bbpa: 0.116,
                  iso: 0.208,
                  obp: 0.309,
                  ops: 0.732,
                  seca: 0.342,
                  slg: 0.423,
                  xbh: 42,
                  pitch_count: 2118,
                  lob_risp_2out: 38,
                  team_lob: 81,
                  ab_risp: 93,
                  hit_risp: 22,
                  rbi_2out: 14,
                  linedrive: 78,
                  groundball: 97,
                  popup: 13,
                  flyball: 77,
                  ap: 524,
                  avg: ".215",
                  gofo: 1.05,
                  onbase: {
                    s: 56,
                    d: 15,
                    t: 1,
                    hr: 26,
                    tb: 193,
                    bb: 57,
                    ibb: 4,
                    hbp: 3,
                    fc: 3,
                    roe: 0,
                    h: 98,
                    cycle: 0,
                  },
                  runs: { total: 65 },
                  outcome: {
                    klook: 410,
                    kswing: 318,
                    ktotal: 728,
                    ball: 751,
                    iball: 0,
                    dirtball: 46,
                    foul: 325,
                  },
                  outs: {
                    po: 13,
                    fo: 43,
                    fidp: 0,
                    lo: 29,
                    lidp: 0,
                    go: 89,
                    gidp: 7,
                    klook: 75,
                    kswing: 120,
                    ktotal: 195,
                    sacfly: 4,
                    sachit: 0,
                  },
                  steal: { caught: 1, stolen: 1, pct: 0.5, pickoff: 0 },
                  games: { start: 126, play: 128, finish: 1, complete: 118 },
                },
              },
              fielding: {
                overall: {
                  po: 1015,
                  a: 80,
                  dp: 122,
                  tp: 2,
                  error: 7,
                  tc: 1102,
                  fpct: 0.994,
                  c_wp: 0,
                  pb: 0,
                  rf: 8.622,
                  steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                  errors: {
                    throwing: 1,
                    fielding: 6,
                    interference: 0,
                    total: 7,
                  },
                  games: { start: 125, play: 127, finish: 1, complete: 0 },
                },
              },
            },
            splits: {
              hitting: {
                overall: [
                  {
                    total: [
                      {
                        ab: 456,
                        runs: 65,
                        s: 56,
                        d: 15,
                        t: 1,
                        hr: 26,
                        rbi: 61,
                        bb: 57,
                        ibb: 4,
                        hbp: 3,
                        sb: 1,
                        cs: 1,
                        obp: 0.309,
                        slg: 0.423,
                        ops: 0.732,
                        h: 98,
                        ktotal: 195,
                        avg: ".215",
                      },
                    ],
                    day_night: [
                      {
                        value: "night",
                        ab: 319,
                        runs: 45,
                        s: 36,
                        d: 11,
                        t: 1,
                        hr: 18,
                        rbi: 40,
                        bb: 43,
                        ibb: 2,
                        hbp: 3,
                        sb: 0,
                        cs: 1,
                        obp: 0.309,
                        slg: 0.417,
                        ops: 0.726,
                        h: 66,
                        ktotal: 139,
                        avg: ".207",
                      },
                      {
                        value: "day",
                        ab: 137,
                        runs: 20,
                        s: 20,
                        d: 4,
                        t: 0,
                        hr: 8,
                        rbi: 21,
                        bb: 14,
                        ibb: 2,
                        hbp: 0,
                        sb: 1,
                        cs: 0,
                        obp: 0.31,
                        slg: 0.438,
                        ops: 0.748,
                        h: 32,
                        ktotal: 56,
                        avg: ".234",
                      },
                    ],
                    venue: [
                      {
                        id: "2b0ccd49-4d87-4996-ac4d-27ffc7ee4c16",
                        name: "Progressive Field",
                        market: "Cleveland",
                        surface: "grass",
                        ab: 9,
                        runs: 1,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.2,
                        slg: 0.444,
                        ops: 0.644,
                        h: 1,
                        ktotal: 2,
                        avg: ".111",
                      },
                      {
                        id: "ef9a5eef-06c6-4963-ac70-7fd02c8c8d42",
                        name: "Comerica Park",
                        market: "Detroit",
                        surface: "grass",
                        ab: 12,
                        runs: 5,
                        s: 0,
                        d: 2,
                        t: 0,
                        hr: 3,
                        rbi: 6,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.533,
                        slg: 1.333,
                        ops: 1.866,
                        h: 5,
                        ktotal: 5,
                        avg: ".417",
                      },
                      {
                        id: "60732da9-ad03-4feb-9a36-aee3e98c7a2b",
                        name: "Angel Stadium of Anaheim",
                        market: "Los Angeles",
                        surface: "grass",
                        ab: 11,
                        runs: 0,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.167,
                        slg: 0.091,
                        ops: 0.258,
                        h: 1,
                        ktotal: 3,
                        avg: ".091",
                      },
                      {
                        id: "f0600f04-1386-4c61-a412-804960c46cb1",
                        name: "Minute Maid Park",
                        market: "Houston",
                        surface: "grass",
                        ab: 12,
                        runs: 1,
                        s: 0,
                        d: 0,
                        t: 1,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.083,
                        slg: 0.25,
                        ops: 0.333,
                        h: 1,
                        ktotal: 8,
                        avg: ".083",
                      },
                      {
                        id: "61314394-c8b8-411e-b891-ca41285d5362",
                        name: "PNC Park",
                        market: "Pittsburgh",
                        surface: "grass",
                        ab: 3,
                        runs: 1,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 1.333,
                        ops: 1.666,
                        h: 1,
                        ktotal: 1,
                        avg: ".333",
                      },
                      {
                        id: "4a550fc1-c03d-4294-b63a-30ff197bedc4",
                        name: "Guaranteed Rate Field",
                        market: "Chicago",
                        surface: "grass",
                        ab: 2,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.0,
                        slg: 0.0,
                        ops: 0.0,
                        h: 0,
                        ktotal: 0,
                        avg: ".000",
                      },
                      {
                        id: "434aa63c-1049-4a54-a6bc-3790c7772ee1",
                        name: "Oriole Park at Camden Yards",
                        market: "Baltimore",
                        surface: "grass",
                        ab: 253,
                        runs: 34,
                        s: 32,
                        d: 7,
                        t: 0,
                        hr: 15,
                        rbi: 37,
                        bb: 29,
                        ibb: 3,
                        hbp: 1,
                        sb: 1,
                        cs: 1,
                        obp: 0.302,
                        slg: 0.419,
                        ops: 0.721,
                        h: 54,
                        ktotal: 107,
                        avg: ".213",
                      },
                      {
                        id: "48cbd35a-d932-4a75-beab-067bfbacfc26",
                        name: "RingCentral Coliseum",
                        market: "Oakland",
                        surface: "grass",
                        ab: 11,
                        runs: 0,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 3,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.385,
                        slg: 0.273,
                        ops: 0.658,
                        h: 3,
                        ktotal: 4,
                        avg: ".273",
                      },
                      {
                        id: "84d72338-2173-4a90-9d25-99adc6c86f4b",
                        name: "Rogers Centre",
                        market: "Toronto",
                        surface: "turf",
                        ab: 25,
                        runs: 3,
                        s: 2,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 4,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.276,
                        slg: 0.32,
                        ops: 0.596,
                        h: 4,
                        ktotal: 14,
                        avg: ".160",
                      },
                      {
                        id: "b27b0dc5-c2c5-401d-b8b9-a6a2dbc225e5",
                        name: "Fenway Park",
                        market: "Boston",
                        surface: "grass",
                        ab: 33,
                        runs: 6,
                        s: 6,
                        d: 1,
                        t: 0,
                        hr: 2,
                        rbi: 4,
                        bb: 5,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.35,
                        slg: 0.485,
                        ops: 0.835,
                        h: 9,
                        ktotal: 13,
                        avg: ".273",
                      },
                      {
                        id: "706e9828-6687-4ac8-a409-3fb972e8bae9",
                        name: "Yankee Stadium",
                        market: "New York",
                        surface: "grass",
                        ab: 26,
                        runs: 5,
                        s: 2,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 3,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.267,
                        slg: 0.308,
                        ops: 0.575,
                        h: 4,
                        ktotal: 12,
                        avg: ".154",
                      },
                      {
                        id: "3aaaf4af-0f8c-49c1-8bf1-1780bb5a5f5c",
                        name: "Tropicana Field",
                        market: "Tampa Bay",
                        surface: "turf",
                        ab: 12,
                        runs: 0,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.267,
                        slg: 0.083,
                        ops: 0.35,
                        h: 1,
                        ktotal: 8,
                        avg: ".083",
                      },
                      {
                        id: "6fca95c9-7f2c-4acb-a9f3-02ef96340d2a",
                        name: "Kauffman Stadium",
                        market: "Kansas City",
                        surface: "grass",
                        ab: 6,
                        runs: 3,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 3,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.667,
                        slg: 1.5,
                        ops: 2.167,
                        h: 3,
                        ktotal: 2,
                        avg: ".500",
                      },
                      {
                        id: "3f47c1c6-b059-4fa2-9d85-5d37b7000992",
                        name: "Globe Life Park in Arlington",
                        market: "Texas",
                        surface: "grass",
                        ab: 8,
                        runs: 3,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 2,
                        bb: 3,
                        ibb: 1,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.692,
                        slg: 0.5,
                        ops: 1.192,
                        h: 4,
                        ktotal: 3,
                        avg: ".500",
                      },
                      {
                        id: "bb062fb1-e268-412e-b3d2-2ddb6acc1822",
                        name: "Nationals Park",
                        market: "Washington",
                        surface: "grass",
                        ab: 8,
                        runs: 1,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.125,
                        slg: 0.125,
                        ops: 0.25,
                        h: 1,
                        ktotal: 3,
                        avg: ".125",
                      },
                      {
                        id: "f102d8fb-de67-4b86-9053-8b55f578d45c",
                        name: "Great American Ball Park",
                        market: "Cincinnati",
                        surface: "grass",
                        ab: 12,
                        runs: 0,
                        s: 0,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.083,
                        slg: 0.167,
                        ops: 0.25,
                        h: 1,
                        ktotal: 5,
                        avg: ".083",
                      },
                      {
                        id: "f1c03dac-3c0f-437c-a325-8d5702cd321a",
                        name: "T-Mobile Park",
                        market: "Seattle",
                        surface: "grass",
                        ab: 13,
                        runs: 2,
                        s: 3,
                        d: 2,
                        t: 0,
                        hr: 0,
                        rbi: 2,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.385,
                        slg: 0.538,
                        ops: 0.923,
                        h: 5,
                        ktotal: 5,
                        avg: ".385",
                      },
                    ],
                    surface: [
                      {
                        value: "turf",
                        ab: 37,
                        runs: 3,
                        s: 3,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 7,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.273,
                        slg: 0.243,
                        ops: 0.516,
                        h: 5,
                        ktotal: 22,
                        avg: ".135",
                      },
                      {
                        value: "grass",
                        ab: 419,
                        runs: 62,
                        s: 53,
                        d: 14,
                        t: 1,
                        hr: 25,
                        rbi: 60,
                        bb: 50,
                        ibb: 4,
                        hbp: 3,
                        sb: 1,
                        cs: 1,
                        obp: 0.313,
                        slg: 0.439,
                        ops: 0.752,
                        h: 93,
                        ktotal: 173,
                        avg: ".222",
                      },
                    ],
                    month: [
                      {
                        value: "5",
                        ab: 99,
                        runs: 16,
                        s: 11,
                        d: 2,
                        t: 1,
                        hr: 8,
                        rbi: 16,
                        bb: 14,
                        ibb: 1,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.319,
                        slg: 0.505,
                        ops: 0.824,
                        h: 22,
                        ktotal: 46,
                        avg: ".222",
                      },
                      {
                        value: "7",
                        ab: 52,
                        runs: 7,
                        s: 7,
                        d: 1,
                        t: 0,
                        hr: 3,
                        rbi: 11,
                        bb: 7,
                        ibb: 1,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.323,
                        slg: 0.404,
                        ops: 0.727,
                        h: 11,
                        ktotal: 22,
                        avg: ".212",
                      },
                      {
                        value: "8",
                        ab: 99,
                        runs: 16,
                        s: 15,
                        d: 4,
                        t: 0,
                        hr: 5,
                        rbi: 16,
                        bb: 12,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 1,
                        obp: 0.321,
                        slg: 0.434,
                        ops: 0.755,
                        h: 24,
                        ktotal: 38,
                        avg: ".242",
                      },
                      {
                        value: "4",
                        ab: 80,
                        runs: 11,
                        s: 11,
                        d: 5,
                        t: 0,
                        hr: 3,
                        rbi: 5,
                        bb: 11,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.337,
                        slg: 0.413,
                        ops: 0.75,
                        h: 19,
                        ktotal: 33,
                        avg: ".238",
                      },
                      {
                        value: "10",
                        ab: 3,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.0,
                        slg: 0.0,
                        ops: 0.0,
                        h: 0,
                        ktotal: 2,
                        avg: ".000",
                      },
                      {
                        value: "9",
                        ab: 85,
                        runs: 8,
                        s: 7,
                        d: 3,
                        t: 0,
                        hr: 4,
                        rbi: 8,
                        bb: 9,
                        ibb: 2,
                        hbp: 1,
                        sb: 1,
                        cs: 0,
                        obp: 0.268,
                        slg: 0.341,
                        ops: 0.609,
                        h: 14,
                        ktotal: 38,
                        avg: ".165",
                      },
                      {
                        value: "6",
                        ab: 38,
                        runs: 7,
                        s: 5,
                        d: 0,
                        t: 0,
                        hr: 3,
                        rbi: 5,
                        bb: 4,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.286,
                        slg: 0.447,
                        ops: 0.733,
                        h: 8,
                        ktotal: 16,
                        avg: ".211",
                      },
                    ],
                    home_away: [
                      {
                        value: "home",
                        ab: 253,
                        runs: 34,
                        s: 32,
                        d: 7,
                        t: 0,
                        hr: 15,
                        rbi: 37,
                        bb: 29,
                        ibb: 3,
                        hbp: 1,
                        sb: 1,
                        cs: 1,
                        obp: 0.302,
                        slg: 0.419,
                        ops: 0.721,
                        h: 54,
                        ktotal: 107,
                        avg: ".213",
                      },
                      {
                        value: "away",
                        ab: 203,
                        runs: 31,
                        s: 24,
                        d: 8,
                        t: 1,
                        hr: 11,
                        rbi: 24,
                        bb: 28,
                        ibb: 1,
                        hbp: 2,
                        sb: 0,
                        cs: 0,
                        obp: 0.318,
                        slg: 0.429,
                        ops: 0.747,
                        h: 44,
                        ktotal: 88,
                        avg: ".217",
                      },
                    ],
                    pitcher_hand: [
                      {
                        value: "R",
                        ab: 312,
                        runs: 49,
                        s: 34,
                        d: 12,
                        t: 0,
                        hr: 22,
                        rbi: 46,
                        bb: 41,
                        ibb: 4,
                        hbp: 1,
                        sb: 1,
                        cs: 0,
                        obp: 0.317,
                        slg: 0.468,
                        ops: 0.785,
                        h: 68,
                        ktotal: 130,
                        avg: ".218",
                      },
                      {
                        value: "L",
                        ab: 144,
                        runs: 16,
                        s: 22,
                        d: 3,
                        t: 1,
                        hr: 4,
                        rbi: 15,
                        bb: 16,
                        ibb: 0,
                        hbp: 2,
                        sb: 0,
                        cs: 1,
                        obp: 0.293,
                        slg: 0.326,
                        ops: 0.619,
                        h: 30,
                        ktotal: 65,
                        avg: ".208",
                      },
                    ],
                    opponent: [
                      {
                        id: "27a59d3b-ff7c-48ea-b016-4798f560f5e1",
                        name: "Athletics",
                        market: "Oakland",
                        abbr: "OAK",
                        ab: 21,
                        runs: 2,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 4,
                        bb: 5,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.385,
                        slg: 0.381,
                        ops: 0.766,
                        h: 5,
                        ktotal: 9,
                        avg: ".238",
                      },
                      {
                        id: "1d678440-b4b1-4954-9b39-70afb3ebbcfa",
                        name: "Blue Jays",
                        market: "Toronto",
                        abbr: "TOR",
                        ab: 64,
                        runs: 7,
                        s: 5,
                        d: 2,
                        t: 0,
                        hr: 3,
                        rbi: 5,
                        bb: 4,
                        ibb: 2,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.239,
                        slg: 0.328,
                        ops: 0.567,
                        h: 10,
                        ktotal: 33,
                        avg: ".156",
                      },
                      {
                        id: "a09ec676-f887-43dc-bbb3-cf4bbaee9a18",
                        name: "Yankees",
                        market: "New York",
                        abbr: "NYY",
                        ab: 59,
                        runs: 8,
                        s: 8,
                        d: 2,
                        t: 0,
                        hr: 3,
                        rbi: 6,
                        bb: 5,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.292,
                        slg: 0.407,
                        ops: 0.699,
                        h: 13,
                        ktotal: 26,
                        avg: ".220",
                      },
                      {
                        id: "93941372-eb4c-4c40-aced-fe3267174393",
                        name: "Red Sox",
                        market: "Boston",
                        abbr: "BOS",
                        ab: 69,
                        runs: 10,
                        s: 11,
                        d: 3,
                        t: 0,
                        hr: 3,
                        rbi: 7,
                        bb: 9,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.325,
                        slg: 0.42,
                        ops: 0.745,
                        h: 17,
                        ktotal: 26,
                        avg: ".246",
                      },
                      {
                        id: "833a51a9-0d84-410f-bd77-da08c3e5e26e",
                        name: "Royals",
                        market: "Kansas City",
                        abbr: "KC",
                        ab: 18,
                        runs: 4,
                        s: 2,
                        d: 1,
                        t: 0,
                        hr: 2,
                        rbi: 5,
                        bb: 4,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.409,
                        slg: 0.667,
                        ops: 1.076,
                        h: 5,
                        ktotal: 6,
                        avg: ".278",
                      },
                      {
                        id: "481dfe7e-5dab-46ab-a49f-9dcc2b6e2cfd",
                        name: "Pirates",
                        market: "Pittsburgh",
                        abbr: "PIT",
                        ab: 11,
                        runs: 4,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 2,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.818,
                        ops: 1.151,
                        h: 3,
                        ktotal: 5,
                        avg: ".273",
                      },
                      {
                        id: "c874a065-c115-4e7d-b0f0-235584fb0e6f",
                        name: "Reds",
                        market: "Cincinnati",
                        abbr: "CIN",
                        ab: 12,
                        runs: 0,
                        s: 0,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.083,
                        slg: 0.167,
                        ops: 0.25,
                        h: 1,
                        ktotal: 5,
                        avg: ".083",
                      },
                      {
                        id: "47f490cd-2f58-4ef7-9dfd-2ad6ba6c1ae8",
                        name: "White Sox",
                        market: "Chicago",
                        abbr: "CWS",
                        ab: 11,
                        runs: 2,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 2,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.429,
                        slg: 0.636,
                        ops: 1.065,
                        h: 4,
                        ktotal: 3,
                        avg: ".364",
                      },
                      {
                        id: "43a39081-52b4-4f93-ad29-da7f329ea960",
                        name: "Mariners",
                        market: "Seattle",
                        abbr: "SEA",
                        ab: 24,
                        runs: 5,
                        s: 5,
                        d: 3,
                        t: 0,
                        hr: 0,
                        rbi: 3,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.36,
                        slg: 0.458,
                        ops: 0.818,
                        h: 8,
                        ktotal: 11,
                        avg: ".333",
                      },
                      {
                        id: "80715d0d-0d2a-450f-a970-1b9a3b18c7e7",
                        name: "Indians",
                        market: "Cleveland",
                        abbr: "CLE",
                        ab: 9,
                        runs: 1,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.2,
                        slg: 0.444,
                        ops: 0.644,
                        h: 1,
                        ktotal: 2,
                        avg: ".111",
                      },
                      {
                        id: "575c19b7-4052-41c2-9f0a-1c5813d02f99",
                        name: "Tigers",
                        market: "Detroit",
                        abbr: "DET",
                        ab: 26,
                        runs: 7,
                        s: 0,
                        d: 2,
                        t: 0,
                        hr: 4,
                        rbi: 8,
                        bb: 6,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 1,
                        obp: 0.375,
                        slg: 0.769,
                        ops: 1.144,
                        h: 6,
                        ktotal: 11,
                        avg: ".231",
                      },
                      {
                        id: "bdc11650-6f74-49c4-875e-778aeb7632d9",
                        name: "Rays",
                        market: "Tampa Bay",
                        abbr: "TB",
                        ab: 35,
                        runs: 3,
                        s: 7,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 5,
                        bb: 9,
                        ibb: 0,
                        hbp: 0,
                        sb: 1,
                        cs: 0,
                        obp: 0.386,
                        slg: 0.314,
                        ops: 0.7,
                        h: 8,
                        ktotal: 17,
                        avg: ".229",
                      },
                      {
                        id: "eb21dadd-8f10-4095-8bf3-dfb3b779f107",
                        name: "Astros",
                        market: "Houston",
                        abbr: "HOU",
                        ab: 23,
                        runs: 2,
                        s: 2,
                        d: 0,
                        t: 1,
                        hr: 0,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.16,
                        slg: 0.217,
                        ops: 0.377,
                        h: 3,
                        ktotal: 13,
                        avg: ".130",
                      },
                      {
                        id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                        name: "Twins",
                        market: "Minnesota",
                        abbr: "MIN",
                        ab: 10,
                        runs: 1,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.182,
                        slg: 0.4,
                        ops: 0.582,
                        h: 1,
                        ktotal: 3,
                        avg: ".100",
                      },
                      {
                        id: "4f735188-37c8-473d-ae32-1f7e34ccf892",
                        name: "Angels",
                        market: "Los Angeles",
                        abbr: "LAA",
                        ab: 15,
                        runs: 1,
                        s: 2,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 0.4,
                        ops: 0.65,
                        h: 3,
                        ktotal: 5,
                        avg: ".200",
                      },
                      {
                        id: "d99f919b-1534-4516-8e8a-9cd106c6d8cd",
                        name: "Rangers",
                        market: "Texas",
                        abbr: "TEX",
                        ab: 23,
                        runs: 6,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 3,
                        rbi: 9,
                        bb: 4,
                        ibb: 1,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.448,
                        slg: 0.696,
                        ops: 1.144,
                        h: 7,
                        ktotal: 9,
                        avg: ".304",
                      },
                      {
                        id: "d89bed32-3aee-4407-99e3-4103641b999a",
                        name: "Nationals",
                        market: "Washington",
                        abbr: "WSH",
                        ab: 15,
                        runs: 2,
                        s: 2,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 2,
                        ibb: 1,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.278,
                        slg: 0.133,
                        ops: 0.411,
                        h: 2,
                        ktotal: 6,
                        avg: ".133",
                      },
                      {
                        id: "55714da8-fcaf-4574-8443-59bfb511a524",
                        name: "Cubs",
                        market: "Chicago",
                        abbr: "CHC",
                        ab: 11,
                        runs: 0,
                        s: 0,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.167,
                        slg: 0.182,
                        ops: 0.349,
                        h: 1,
                        ktotal: 5,
                        avg: ".091",
                      },
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
      {
        id: "565de4be-dc80-4849-a7e1-54bc79156cc8",
        year: 2016,
        type: "PST",
        totals: {
          statistics: {
            hitting: {
              overall: {
                ab: 3,
                lob: 0,
                rbi: 0,
                abhr: 0,
                abk: 1.5,
                bip: 1,
                babip: 0.0,
                bbk: 0.5,
                bbpa: 0.25,
                iso: 0.0,
                obp: 0.25,
                ops: 0.25,
                seca: 0.333,
                slg: 0.0,
                xbh: 0,
                pitch_count: 21,
                lob_risp_2out: 0,
                team_lob: 0,
                ab_risp: 0,
                hit_risp: 0,
                ap: 4,
                avg: ".000",
                gofo: 0.0,
                onbase: {
                  s: 0,
                  d: 0,
                  t: 0,
                  hr: 0,
                  tb: 0,
                  bb: 1,
                  ibb: 0,
                  hbp: 0,
                  fc: 0,
                  roe: 0,
                  h: 0,
                  cycle: 0,
                },
                runs: { total: 0 },
                outcome: {
                  klook: 4,
                  kswing: 3,
                  ktotal: 7,
                  ball: 9,
                  iball: 0,
                  dirtball: 1,
                  foul: 3,
                },
                outs: {
                  po: 0,
                  fo: 1,
                  fidp: 0,
                  lo: 0,
                  lidp: 0,
                  go: 0,
                  gidp: 0,
                  klook: 1,
                  kswing: 1,
                  ktotal: 2,
                  sacfly: 0,
                  sachit: 0,
                },
                steal: { caught: 0, stolen: 0, pct: 0, pickoff: 0 },
                games: { start: 1, play: 1, finish: 0, complete: 1 },
              },
            },
            fielding: {
              overall: {
                po: 9,
                a: 1,
                dp: 3,
                tp: 0,
                error: 0,
                tc: 10,
                fpct: 1.0,
                c_wp: 0,
                pb: 0,
                rf: 10.0,
                steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                errors: { throwing: 0, fielding: 0, interference: 0, total: 0 },
                games: { start: 1, play: 1, finish: 0, complete: 0 },
              },
            },
          },
          splits: {
            hitting: {
              overall: [
                {
                  total: [
                    {
                      ab: 3,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 0.0,
                      ops: 0.25,
                      h: 0,
                      ktotal: 2,
                      avg: ".000",
                    },
                  ],
                  day_night: [
                    {
                      value: "night",
                      ab: 3,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 0.0,
                      ops: 0.25,
                      h: 0,
                      ktotal: 2,
                      avg: ".000",
                    },
                  ],
                  venue: [
                    {
                      id: "84d72338-2173-4a90-9d25-99adc6c86f4b",
                      name: "Rogers Centre",
                      market: "Toronto",
                      surface: "turf",
                      ab: 3,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 0.0,
                      ops: 0.25,
                      h: 0,
                      ktotal: 2,
                      avg: ".000",
                    },
                  ],
                  surface: [
                    {
                      value: "turf",
                      ab: 3,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 0.0,
                      ops: 0.25,
                      h: 0,
                      ktotal: 2,
                      avg: ".000",
                    },
                  ],
                  month: [
                    {
                      value: "10",
                      ab: 3,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 0.0,
                      ops: 0.25,
                      h: 0,
                      ktotal: 2,
                      avg: ".000",
                    },
                  ],
                  home_away: [
                    {
                      value: "away",
                      ab: 3,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 0.0,
                      ops: 0.25,
                      h: 0,
                      ktotal: 2,
                      avg: ".000",
                    },
                  ],
                  pitcher_hand: [
                    {
                      value: "L",
                      ab: 0,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 1.0,
                      slg: 0,
                      ops: 1.0,
                      h: 0,
                      ktotal: 0,
                      avg: ".000",
                    },
                    {
                      value: "R",
                      ab: 3,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.0,
                      slg: 0.0,
                      ops: 0.0,
                      h: 0,
                      ktotal: 2,
                      avg: ".000",
                    },
                  ],
                  opponent: [
                    {
                      id: "1d678440-b4b1-4954-9b39-70afb3ebbcfa",
                      name: "Blue Jays",
                      market: "Toronto",
                      abbr: "TOR",
                      ab: 3,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 0.0,
                      ops: 0.25,
                      h: 0,
                      ktotal: 2,
                      avg: ".000",
                    },
                  ],
                },
              ],
            },
          },
        },
        teams: [
          {
            name: "Orioles",
            market: "Baltimore",
            abbr: "BAL",
            id: "75729d34-bca7-4a0f-b3df-6f26c6ad3719",
            statistics: {
              hitting: {
                overall: {
                  ab: 3,
                  lob: 0,
                  rbi: 0,
                  abhr: 0,
                  abk: 1.5,
                  bip: 1,
                  babip: 0.0,
                  bbk: 0.5,
                  bbpa: 0.25,
                  iso: 0.0,
                  obp: 0.25,
                  ops: 0.25,
                  seca: 0.333,
                  slg: 0.0,
                  xbh: 0,
                  pitch_count: 21,
                  lob_risp_2out: 0,
                  team_lob: 0,
                  ab_risp: 0,
                  hit_risp: 0,
                  ap: 4,
                  avg: ".000",
                  gofo: 0.0,
                  onbase: {
                    s: 0,
                    d: 0,
                    t: 0,
                    hr: 0,
                    tb: 0,
                    bb: 1,
                    ibb: 0,
                    hbp: 0,
                    fc: 0,
                    roe: 0,
                    h: 0,
                    cycle: 0,
                  },
                  runs: { total: 0 },
                  outcome: {
                    klook: 4,
                    kswing: 3,
                    ktotal: 7,
                    ball: 9,
                    iball: 0,
                    dirtball: 1,
                    foul: 3,
                  },
                  outs: {
                    po: 0,
                    fo: 1,
                    fidp: 0,
                    lo: 0,
                    lidp: 0,
                    go: 0,
                    gidp: 0,
                    klook: 1,
                    kswing: 1,
                    ktotal: 2,
                    sacfly: 0,
                    sachit: 0,
                  },
                  steal: { caught: 0, stolen: 0, pct: 0, pickoff: 0 },
                  games: { start: 1, play: 1, finish: 0, complete: 1 },
                },
              },
              fielding: {
                overall: {
                  po: 9,
                  a: 1,
                  dp: 3,
                  tp: 0,
                  error: 0,
                  tc: 10,
                  fpct: 1.0,
                  c_wp: 0,
                  pb: 0,
                  rf: 10.0,
                  steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                  errors: {
                    throwing: 0,
                    fielding: 0,
                    interference: 0,
                    total: 0,
                  },
                  games: { start: 1, play: 1, finish: 0, complete: 0 },
                },
              },
            },
            splits: {
              hitting: {
                overall: [
                  {
                    total: [
                      {
                        ab: 3,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 0.0,
                        ops: 0.25,
                        h: 0,
                        ktotal: 2,
                        avg: ".000",
                      },
                    ],
                    day_night: [
                      {
                        value: "night",
                        ab: 3,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 0.0,
                        ops: 0.25,
                        h: 0,
                        ktotal: 2,
                        avg: ".000",
                      },
                    ],
                    venue: [
                      {
                        id: "84d72338-2173-4a90-9d25-99adc6c86f4b",
                        name: "Rogers Centre",
                        market: "Toronto",
                        surface: "turf",
                        ab: 3,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 0.0,
                        ops: 0.25,
                        h: 0,
                        ktotal: 2,
                        avg: ".000",
                      },
                    ],
                    surface: [
                      {
                        value: "turf",
                        ab: 3,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 0.0,
                        ops: 0.25,
                        h: 0,
                        ktotal: 2,
                        avg: ".000",
                      },
                    ],
                    month: [
                      {
                        value: "10",
                        ab: 3,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 0.0,
                        ops: 0.25,
                        h: 0,
                        ktotal: 2,
                        avg: ".000",
                      },
                    ],
                    home_away: [
                      {
                        value: "away",
                        ab: 3,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 0.0,
                        ops: 0.25,
                        h: 0,
                        ktotal: 2,
                        avg: ".000",
                      },
                    ],
                    pitcher_hand: [
                      {
                        value: "L",
                        ab: 0,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 1.0,
                        slg: 0,
                        ops: 1.0,
                        h: 0,
                        ktotal: 0,
                        avg: ".000",
                      },
                      {
                        value: "R",
                        ab: 3,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.0,
                        slg: 0.0,
                        ops: 0.0,
                        h: 0,
                        ktotal: 2,
                        avg: ".000",
                      },
                    ],
                    opponent: [
                      {
                        id: "1d678440-b4b1-4954-9b39-70afb3ebbcfa",
                        name: "Blue Jays",
                        market: "Toronto",
                        abbr: "TOR",
                        ab: 3,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 0.0,
                        ops: 0.25,
                        h: 0,
                        ktotal: 2,
                        avg: ".000",
                      },
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
      {
        id: "565de4be-dc80-4849-a7e1-54bc79156cc8",
        year: 2016,
        type: "REG",
        totals: {
          statistics: {
            hitting: {
              overall: {
                ab: 566,
                lob: 248,
                rbi: 84,
                abhr: 14.895,
                abk: 2.584,
                bip: 312,
                babip: 0.279,
                bbk: 0.402,
                bbpa: 0.132,
                iso: 0.238,
                obp: 0.332,
                ops: 0.791,
                seca: 0.396,
                slg: 0.459,
                xbh: 59,
                pitch_count: 2755,
                lob_risp_2out: 62,
                team_lob: 131,
                ab_risp: 124,
                hit_risp: 24,
                ap: 665,
                avg: ".221",
                gofo: 1.01,
                onbase: {
                  s: 66,
                  d: 21,
                  t: 0,
                  hr: 38,
                  tb: 260,
                  bb: 85,
                  ibb: 3,
                  hbp: 8,
                  fc: 8,
                  roe: 3,
                  h: 125,
                  cycle: 0,
                },
                runs: { total: 99 },
                outcome: {
                  klook: 447,
                  kswing: 434,
                  ktotal: 881,
                  ball: 1073,
                  iball: 10,
                  dirtball: 42,
                  foul: 391,
                },
                outs: {
                  po: 20,
                  fo: 77,
                  fidp: 1,
                  lo: 18,
                  lidp: 0,
                  go: 116,
                  gidp: 6,
                  klook: 79,
                  kswing: 140,
                  ktotal: 219,
                  sacfly: 3,
                  sachit: 0,
                },
                steal: { caught: 0, stolen: 1, pct: 1.0, pickoff: 0 },
                games: { start: 157, play: 157, finish: 0, complete: 143 },
              },
            },
            fielding: {
              overall: {
                po: 1330,
                a: 62,
                dp: 138,
                tp: 0,
                error: 10,
                tc: 1402,
                fpct: 0.993,
                c_wp: 0,
                pb: 0,
                rf: 8.981,
                steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                errors: {
                  throwing: 0,
                  fielding: 0,
                  interference: 0,
                  total: 10,
                },
                games: { start: 155, play: 155, finish: 0, complete: 0 },
              },
            },
          },
          splits: {
            hitting: {
              overall: [
                {
                  total: [
                    {
                      ab: 566,
                      runs: 99,
                      s: 66,
                      d: 21,
                      t: 0,
                      hr: 38,
                      rbi: 84,
                      bb: 85,
                      ibb: 3,
                      hbp: 8,
                      sb: 1,
                      cs: 0,
                      obp: 0.332,
                      slg: 0.459,
                      ops: 0.791,
                      h: 125,
                      ktotal: 219,
                      avg: ".221",
                    },
                  ],
                  day_night: [
                    {
                      value: "day",
                      ab: 164,
                      runs: 29,
                      s: 17,
                      d: 7,
                      t: 0,
                      hr: 10,
                      rbi: 27,
                      bb: 22,
                      ibb: 2,
                      hbp: 4,
                      sb: 0,
                      cs: 0,
                      obp: 0.323,
                      slg: 0.433,
                      ops: 0.756,
                      h: 34,
                      ktotal: 72,
                      avg: ".207",
                    },
                    {
                      value: "night",
                      ab: 402,
                      runs: 70,
                      s: 49,
                      d: 14,
                      t: 0,
                      hr: 28,
                      rbi: 57,
                      bb: 63,
                      ibb: 1,
                      hbp: 4,
                      sb: 1,
                      cs: 0,
                      obp: 0.336,
                      slg: 0.47,
                      ops: 0.806,
                      h: 91,
                      ktotal: 147,
                      avg: ".226",
                    },
                  ],
                  venue: [
                    {
                      id: "2d7542f5-7b80-49f7-9b24-c53ffdc75af6",
                      name: "Oracle Park",
                      market: "San Francisco",
                      surface: "grass",
                      ab: 10,
                      runs: 2,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.385,
                      slg: 0.5,
                      ops: 0.885,
                      h: 2,
                      ktotal: 7,
                      avg: ".200",
                    },
                    {
                      id: "3aaaf4af-0f8c-49c1-8bf1-1780bb5a5f5c",
                      name: "Tropicana Field",
                      market: "Tampa Bay",
                      surface: "turf",
                      ab: 29,
                      runs: 5,
                      s: 3,
                      d: 2,
                      t: 0,
                      hr: 2,
                      rbi: 4,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.29,
                      slg: 0.517,
                      ops: 0.807,
                      h: 7,
                      ktotal: 10,
                      avg: ".241",
                    },
                    {
                      id: "434aa63c-1049-4a54-a6bc-3790c7772ee1",
                      name: "Oriole Park at Camden Yards",
                      market: "Baltimore",
                      surface: "grass",
                      ab: 275,
                      runs: 47,
                      s: 28,
                      d: 9,
                      t: 0,
                      hr: 17,
                      rbi: 39,
                      bb: 46,
                      ibb: 3,
                      hbp: 4,
                      sb: 1,
                      cs: 0,
                      obp: 0.326,
                      slg: 0.415,
                      ops: 0.741,
                      h: 54,
                      ktotal: 102,
                      avg: ".196",
                    },
                    {
                      id: "48cbd35a-d932-4a75-beab-067bfbacfc26",
                      name: "RingCentral Coliseum",
                      market: "Oakland",
                      surface: "grass",
                      ab: 16,
                      runs: 2,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.294,
                      slg: 0.25,
                      ops: 0.544,
                      h: 4,
                      ktotal: 3,
                      avg: ".250",
                    },
                    {
                      id: "60732da9-ad03-4feb-9a36-aee3e98c7a2b",
                      name: "Angel Stadium of Anaheim",
                      market: "Los Angeles",
                      surface: "grass",
                      ab: 12,
                      runs: 3,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 2,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.231,
                      slg: 0.417,
                      ops: 0.648,
                      h: 2,
                      ktotal: 5,
                      avg: ".167",
                    },
                    {
                      id: "66a19c3d-24fe-477d-bee7-c6ef1b98352f",
                      name: "Dodger Stadium",
                      market: "Los Angeles",
                      surface: "grass",
                      ab: 13,
                      runs: 2,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.2,
                      slg: 0.077,
                      ops: 0.277,
                      h: 1,
                      ktotal: 8,
                      avg: ".077",
                    },
                    {
                      id: "706e9828-6687-4ac8-a409-3fb972e8bae9",
                      name: "Yankee Stadium",
                      market: "New York",
                      surface: "grass",
                      ab: 23,
                      runs: 5,
                      s: 3,
                      d: 1,
                      t: 0,
                      hr: 2,
                      rbi: 3,
                      bb: 4,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.393,
                      slg: 0.565,
                      ops: 0.958,
                      h: 6,
                      ktotal: 7,
                      avg: ".261",
                    },
                    {
                      id: "3f47c1c6-b059-4fa2-9d85-5d37b7000992",
                      name: "Globe Life Park in Arlington",
                      market: "Texas",
                      surface: "grass",
                      ab: 14,
                      runs: 4,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 4,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.474,
                      slg: 0.5,
                      ops: 0.974,
                      h: 4,
                      ktotal: 5,
                      avg: ".286",
                    },
                    {
                      id: "bb062fb1-e268-412e-b3d2-2ddb6acc1822",
                      name: "Nationals Park",
                      market: "Washington",
                      surface: "grass",
                      ab: 7,
                      runs: 1,
                      s: 0,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 0.286,
                      ops: 0.536,
                      h: 1,
                      ktotal: 2,
                      avg: ".143",
                    },
                    {
                      id: "2b0ccd49-4d87-4996-ac4d-27ffc7ee4c16",
                      name: "Progressive Field",
                      market: "Cleveland",
                      surface: "grass",
                      ab: 11,
                      runs: 2,
                      s: 1,
                      d: 2,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.385,
                      slg: 0.455,
                      ops: 0.84,
                      h: 3,
                      ktotal: 5,
                      avg: ".273",
                    },
                    {
                      id: "6fca95c9-7f2c-4acb-a9f3-02ef96340d2a",
                      name: "Kauffman Stadium",
                      market: "Kansas City",
                      surface: "grass",
                      ab: 13,
                      runs: 3,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 2,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.308,
                      slg: 0.538,
                      ops: 0.846,
                      h: 4,
                      ktotal: 5,
                      avg: ".308",
                    },
                    {
                      id: "f1c03dac-3c0f-437c-a325-8d5702cd321a",
                      name: "T-Mobile Park",
                      market: "Seattle",
                      surface: "grass",
                      ab: 17,
                      runs: 3,
                      s: 2,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 4,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.278,
                      slg: 0.588,
                      ops: 0.866,
                      h: 4,
                      ktotal: 6,
                      avg: ".235",
                    },
                    {
                      id: "84d72338-2173-4a90-9d25-99adc6c86f4b",
                      name: "Rogers Centre",
                      market: "Toronto",
                      surface: "turf",
                      ab: 37,
                      runs: 6,
                      s: 5,
                      d: 1,
                      t: 0,
                      hr: 4,
                      rbi: 9,
                      bb: 5,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.341,
                      slg: 0.622,
                      ops: 0.963,
                      h: 10,
                      ktotal: 17,
                      avg: ".270",
                    },
                    {
                      id: "b27b0dc5-c2c5-401d-b8b9-a6a2dbc225e5",
                      name: "Fenway Park",
                      market: "Boston",
                      surface: "grass",
                      ab: 34,
                      runs: 8,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 3,
                      rbi: 9,
                      bb: 5,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.308,
                      slg: 0.471,
                      ops: 0.779,
                      h: 7,
                      ktotal: 13,
                      avg: ".206",
                    },
                    {
                      id: "302f8dcd-eed6-4b83-8609-81548d51e955",
                      name: "Target Field",
                      market: "Minnesota",
                      surface: "grass",
                      ab: 14,
                      runs: 2,
                      s: 5,
                      d: 3,
                      t: 0,
                      hr: 1,
                      rbi: 4,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.643,
                      slg: 1.071,
                      ops: 1.714,
                      h: 9,
                      ktotal: 2,
                      avg: ".643",
                    },
                    {
                      id: "ef9a5eef-06c6-4963-ac70-7fd02c8c8d42",
                      name: "Comerica Park",
                      market: "Detroit",
                      surface: "grass",
                      ab: 9,
                      runs: 1,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 2,
                      bb: 2,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.444,
                      ops: 0.777,
                      h: 1,
                      ktotal: 6,
                      avg: ".111",
                    },
                    {
                      id: "f0600f04-1386-4c61-a412-804960c46cb1",
                      name: "Minute Maid Park",
                      market: "Houston",
                      surface: "grass",
                      ab: 14,
                      runs: 1,
                      s: 1,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 0.214,
                      ops: 0.464,
                      h: 2,
                      ktotal: 8,
                      avg: ".143",
                    },
                    {
                      id: "0ab45d79-5475-4308-9f94-74b0c185ee6f",
                      name: "PETCO Park",
                      market: "San Diego",
                      surface: "grass",
                      ab: 9,
                      runs: 1,
                      s: 1,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 2,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.3,
                      slg: 0.778,
                      ops: 1.078,
                      h: 3,
                      ktotal: 3,
                      avg: ".333",
                    },
                    {
                      id: "4a550fc1-c03d-4294-b63a-30ff197bedc4",
                      name: "Guaranteed Rate Field",
                      market: "Chicago",
                      surface: "grass",
                      ab: 9,
                      runs: 1,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 4,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.429,
                      slg: 0.444,
                      ops: 0.873,
                      h: 1,
                      ktotal: 5,
                      avg: ".111",
                    },
                  ],
                  surface: [
                    {
                      value: "turf",
                      ab: 66,
                      runs: 11,
                      s: 8,
                      d: 3,
                      t: 0,
                      hr: 6,
                      rbi: 13,
                      bb: 7,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.32,
                      slg: 0.576,
                      ops: 0.896,
                      h: 17,
                      ktotal: 27,
                      avg: ".258",
                    },
                    {
                      value: "grass",
                      ab: 500,
                      runs: 88,
                      s: 58,
                      d: 18,
                      t: 0,
                      hr: 32,
                      rbi: 71,
                      bb: 78,
                      ibb: 3,
                      hbp: 8,
                      sb: 1,
                      cs: 0,
                      obp: 0.334,
                      slg: 0.444,
                      ops: 0.778,
                      h: 108,
                      ktotal: 192,
                      avg: ".216",
                    },
                  ],
                  month: [
                    {
                      value: "7",
                      ab: 85,
                      runs: 9,
                      s: 9,
                      d: 1,
                      t: 0,
                      hr: 3,
                      rbi: 7,
                      bb: 14,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.273,
                      slg: 0.271,
                      ops: 0.544,
                      h: 13,
                      ktotal: 37,
                      avg: ".153",
                    },
                    {
                      value: "5",
                      ab: 100,
                      runs: 15,
                      s: 10,
                      d: 8,
                      t: 0,
                      hr: 3,
                      rbi: 11,
                      bb: 13,
                      ibb: 0,
                      hbp: 2,
                      sb: 0,
                      cs: 0,
                      obp: 0.313,
                      slg: 0.38,
                      ops: 0.693,
                      h: 21,
                      ktotal: 42,
                      avg: ".210",
                    },
                    {
                      value: "4",
                      ab: 85,
                      runs: 19,
                      s: 11,
                      d: 2,
                      t: 0,
                      hr: 7,
                      rbi: 17,
                      bb: 15,
                      ibb: 1,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.356,
                      slg: 0.506,
                      ops: 0.862,
                      h: 20,
                      ktotal: 28,
                      avg: ".235",
                    },
                    {
                      value: "8",
                      ab: 98,
                      runs: 18,
                      s: 9,
                      d: 2,
                      t: 0,
                      hr: 10,
                      rbi: 13,
                      bb: 17,
                      ibb: 0,
                      hbp: 2,
                      sb: 1,
                      cs: 0,
                      obp: 0.342,
                      slg: 0.541,
                      ops: 0.883,
                      h: 21,
                      ktotal: 37,
                      avg: ".214",
                    },
                    {
                      value: "9",
                      ab: 96,
                      runs: 14,
                      s: 14,
                      d: 2,
                      t: 0,
                      hr: 6,
                      rbi: 12,
                      bb: 12,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.321,
                      slg: 0.438,
                      ops: 0.759,
                      h: 22,
                      ktotal: 35,
                      avg: ".229",
                    },
                    {
                      value: "10",
                      ab: 7,
                      runs: 1,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 0.143,
                      ops: 0.393,
                      h: 1,
                      ktotal: 3,
                      avg: ".143",
                    },
                    {
                      value: "6",
                      ab: 95,
                      runs: 23,
                      s: 12,
                      d: 6,
                      t: 0,
                      hr: 9,
                      rbi: 24,
                      bb: 13,
                      ibb: 2,
                      hbp: 3,
                      sb: 0,
                      cs: 0,
                      obp: 0.388,
                      slg: 0.632,
                      ops: 1.02,
                      h: 27,
                      ktotal: 37,
                      avg: ".284",
                    },
                  ],
                  home_away: [
                    {
                      value: "home",
                      ab: 275,
                      runs: 47,
                      s: 28,
                      d: 9,
                      t: 0,
                      hr: 17,
                      rbi: 39,
                      bb: 46,
                      ibb: 3,
                      hbp: 4,
                      sb: 1,
                      cs: 0,
                      obp: 0.326,
                      slg: 0.415,
                      ops: 0.741,
                      h: 54,
                      ktotal: 102,
                      avg: ".196",
                    },
                    {
                      value: "away",
                      ab: 291,
                      runs: 52,
                      s: 38,
                      d: 12,
                      t: 0,
                      hr: 21,
                      rbi: 45,
                      bb: 39,
                      ibb: 0,
                      hbp: 4,
                      sb: 0,
                      cs: 0,
                      obp: 0.338,
                      slg: 0.502,
                      ops: 0.84,
                      h: 71,
                      ktotal: 117,
                      avg: ".244",
                    },
                  ],
                  pitcher_hand: [
                    {
                      value: "L",
                      ab: 176,
                      runs: 25,
                      s: 22,
                      d: 8,
                      t: 0,
                      hr: 8,
                      rbi: 29,
                      bb: 23,
                      ibb: 0,
                      hbp: 2,
                      sb: 1,
                      cs: 0,
                      obp: 0.313,
                      slg: 0.398,
                      ops: 0.711,
                      h: 38,
                      ktotal: 64,
                      avg: ".216",
                    },
                    {
                      value: "B",
                      ab: 1,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.0,
                      slg: 0.0,
                      ops: 0.0,
                      h: 0,
                      ktotal: 0,
                      avg: ".000",
                    },
                    {
                      value: "R",
                      ab: 390,
                      runs: 74,
                      s: 44,
                      d: 13,
                      t: 0,
                      hr: 30,
                      rbi: 55,
                      bb: 62,
                      ibb: 3,
                      hbp: 6,
                      sb: 0,
                      cs: 0,
                      obp: 0.341,
                      slg: 0.487,
                      ops: 0.828,
                      h: 87,
                      ktotal: 155,
                      avg: ".223",
                    },
                  ],
                  opponent: [
                    {
                      id: "d99f919b-1534-4516-8e8a-9cd106c6d8cd",
                      name: "Rangers",
                      market: "Texas",
                      abbr: "TEX",
                      ab: 24,
                      runs: 5,
                      s: 5,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 2,
                      bb: 5,
                      ibb: 0,
                      hbp: 1,
                      sb: 1,
                      cs: 0,
                      obp: 0.4,
                      slg: 0.375,
                      ops: 0.775,
                      h: 6,
                      ktotal: 8,
                      avg: ".250",
                    },
                    {
                      id: "93941372-eb4c-4c40-aced-fe3267174393",
                      name: "Red Sox",
                      market: "Boston",
                      abbr: "BOS",
                      ab: 66,
                      runs: 15,
                      s: 6,
                      d: 0,
                      t: 0,
                      hr: 4,
                      rbi: 10,
                      bb: 12,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.282,
                      slg: 0.333,
                      ops: 0.615,
                      h: 10,
                      ktotal: 23,
                      avg: ".152",
                    },
                    {
                      id: "d89bed32-3aee-4407-99e3-4103641b999a",
                      name: "Nationals",
                      market: "Washington",
                      abbr: "WSH",
                      ab: 15,
                      runs: 4,
                      s: 1,
                      d: 2,
                      t: 0,
                      hr: 1,
                      rbi: 2,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.353,
                      slg: 0.6,
                      ops: 0.953,
                      h: 4,
                      ktotal: 5,
                      avg: ".267",
                    },
                    {
                      id: "bdc11650-6f74-49c4-875e-778aeb7632d9",
                      name: "Rays",
                      market: "Tampa Bay",
                      abbr: "TB",
                      ab: 66,
                      runs: 15,
                      s: 7,
                      d: 4,
                      t: 0,
                      hr: 6,
                      rbi: 14,
                      bb: 6,
                      ibb: 1,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.329,
                      slg: 0.591,
                      ops: 0.92,
                      h: 17,
                      ktotal: 23,
                      avg: ".258",
                    },
                    {
                      id: "833a51a9-0d84-410f-bd77-da08c3e5e26e",
                      name: "Royals",
                      market: "Kansas City",
                      abbr: "KC",
                      ab: 19,
                      runs: 4,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 3,
                      bb: 1,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.579,
                      ops: 0.912,
                      h: 5,
                      ktotal: 8,
                      avg: ".263",
                    },
                    {
                      id: "27a59d3b-ff7c-48ea-b016-4798f560f5e1",
                      name: "Athletics",
                      market: "Oakland",
                      abbr: "OAK",
                      ab: 26,
                      runs: 4,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 2,
                      bb: 2,
                      ibb: 0,
                      hbp: 2,
                      sb: 0,
                      cs: 0,
                      obp: 0.3,
                      slg: 0.308,
                      ops: 0.608,
                      h: 5,
                      ktotal: 7,
                      avg: ".192",
                    },
                    {
                      id: "4f735188-37c8-473d-ae32-1f7e34ccf892",
                      name: "Angels",
                      market: "Los Angeles",
                      abbr: "LAA",
                      ab: 22,
                      runs: 5,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 4,
                      bb: 4,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.346,
                      slg: 0.5,
                      ops: 0.846,
                      h: 5,
                      ktotal: 8,
                      avg: ".227",
                    },
                    {
                      id: "a7723160-10b7-4277-a309-d8dd95a8ae65",
                      name: "Giants",
                      market: "San Francisco",
                      abbr: "SF",
                      ab: 10,
                      runs: 2,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.385,
                      slg: 0.5,
                      ops: 0.885,
                      h: 2,
                      ktotal: 7,
                      avg: ".200",
                    },
                    {
                      id: "eb21dadd-8f10-4095-8bf3-dfb3b779f107",
                      name: "Astros",
                      market: "Houston",
                      abbr: "HOU",
                      ab: 29,
                      runs: 5,
                      s: 1,
                      d: 1,
                      t: 0,
                      hr: 4,
                      rbi: 4,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.281,
                      slg: 0.655,
                      ops: 0.936,
                      h: 6,
                      ktotal: 12,
                      avg: ".207",
                    },
                    {
                      id: "a09ec676-f887-43dc-bbb3-cf4bbaee9a18",
                      name: "Yankees",
                      market: "New York",
                      abbr: "NYY",
                      ab: 55,
                      runs: 9,
                      s: 7,
                      d: 1,
                      t: 0,
                      hr: 4,
                      rbi: 6,
                      bb: 8,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.328,
                      slg: 0.455,
                      ops: 0.783,
                      h: 12,
                      ktotal: 24,
                      avg: ".218",
                    },
                    {
                      id: "47f490cd-2f58-4ef7-9dfd-2ad6ba6c1ae8",
                      name: "White Sox",
                      market: "Chicago",
                      abbr: "CWS",
                      ab: 25,
                      runs: 3,
                      s: 4,
                      d: 1,
                      t: 0,
                      hr: 2,
                      rbi: 5,
                      bb: 4,
                      ibb: 1,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.419,
                      slg: 0.56,
                      ops: 0.979,
                      h: 7,
                      ktotal: 8,
                      avg: ".280",
                    },
                    {
                      id: "25507be1-6a68-4267-bd82-e097d94b359b",
                      name: "Diamondbacks",
                      market: "Arizona",
                      abbr: "ARI",
                      ab: 13,
                      runs: 0,
                      s: 2,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 2,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.267,
                      slg: 0.154,
                      ops: 0.421,
                      h: 2,
                      ktotal: 6,
                      avg: ".154",
                    },
                    {
                      id: "80715d0d-0d2a-450f-a970-1b9a3b18c7e7",
                      name: "Indians",
                      market: "Cleveland",
                      abbr: "CLE",
                      ab: 22,
                      runs: 3,
                      s: 1,
                      d: 2,
                      t: 0,
                      hr: 0,
                      rbi: 2,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.24,
                      slg: 0.227,
                      ops: 0.467,
                      h: 3,
                      ktotal: 11,
                      avg: ".136",
                    },
                    {
                      id: "575c19b7-4052-41c2-9f0a-1c5813d02f99",
                      name: "Tigers",
                      market: "Detroit",
                      abbr: "DET",
                      ab: 20,
                      runs: 5,
                      s: 3,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 4,
                      bb: 6,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.444,
                      slg: 0.45,
                      ops: 0.894,
                      h: 5,
                      ktotal: 10,
                      avg: ".250",
                    },
                    {
                      id: "1d678440-b4b1-4954-9b39-70afb3ebbcfa",
                      name: "Blue Jays",
                      market: "Toronto",
                      abbr: "TOR",
                      ab: 64,
                      runs: 10,
                      s: 7,
                      d: 3,
                      t: 0,
                      hr: 4,
                      rbi: 11,
                      bb: 14,
                      ibb: 1,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.358,
                      slg: 0.453,
                      ops: 0.811,
                      h: 14,
                      ktotal: 24,
                      avg: ".219",
                    },
                    {
                      id: "43a39081-52b4-4f93-ad29-da7f329ea960",
                      name: "Mariners",
                      market: "Seattle",
                      abbr: "SEA",
                      ab: 26,
                      runs: 3,
                      s: 2,
                      d: 1,
                      t: 0,
                      hr: 2,
                      rbi: 4,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.276,
                      slg: 0.462,
                      ops: 0.738,
                      h: 5,
                      ktotal: 9,
                      avg: ".192",
                    },
                    {
                      id: "d52d5339-cbdd-43f3-9dfa-a42fd588b9a3",
                      name: "Padres",
                      market: "San Diego",
                      abbr: "SD",
                      ab: 17,
                      runs: 1,
                      s: 1,
                      d: 2,
                      t: 0,
                      hr: 1,
                      rbi: 3,
                      bb: 0,
                      ibb: 0,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.263,
                      slg: 0.529,
                      ops: 0.792,
                      h: 4,
                      ktotal: 8,
                      avg: ".235",
                    },
                    {
                      id: "29dd9a87-5bcc-4774-80c3-7f50d985068b",
                      name: "Rockies",
                      market: "Colorado",
                      abbr: "COL",
                      ab: 9,
                      runs: 0,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.111,
                      ops: 0.444,
                      h: 1,
                      ktotal: 5,
                      avg: ".111",
                    },
                    {
                      id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                      name: "Twins",
                      market: "Minnesota",
                      abbr: "MIN",
                      ab: 25,
                      runs: 4,
                      s: 6,
                      d: 3,
                      t: 0,
                      hr: 2,
                      rbi: 5,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.481,
                      slg: 0.8,
                      ops: 1.281,
                      h: 11,
                      ktotal: 5,
                      avg: ".440",
                    },
                    {
                      id: "ef64da7f-cfaf-4300-87b0-9313386b977c",
                      name: "Dodgers",
                      market: "Los Angeles",
                      abbr: "LAD",
                      ab: 13,
                      runs: 2,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.2,
                      slg: 0.077,
                      ops: 0.277,
                      h: 1,
                      ktotal: 8,
                      avg: ".077",
                    },
                  ],
                },
              ],
            },
          },
        },
        teams: [
          {
            name: "Orioles",
            market: "Baltimore",
            abbr: "BAL",
            id: "75729d34-bca7-4a0f-b3df-6f26c6ad3719",
            statistics: {
              hitting: {
                overall: {
                  ab: 566,
                  lob: 248,
                  rbi: 84,
                  abhr: 14.895,
                  abk: 2.584,
                  bip: 312,
                  babip: 0.279,
                  bbk: 0.402,
                  bbpa: 0.132,
                  iso: 0.238,
                  obp: 0.332,
                  ops: 0.791,
                  seca: 0.396,
                  slg: 0.459,
                  xbh: 59,
                  pitch_count: 2755,
                  lob_risp_2out: 62,
                  team_lob: 131,
                  ab_risp: 124,
                  hit_risp: 24,
                  ap: 665,
                  avg: ".221",
                  gofo: 1.01,
                  onbase: {
                    s: 66,
                    d: 21,
                    t: 0,
                    hr: 38,
                    tb: 260,
                    bb: 85,
                    ibb: 3,
                    hbp: 8,
                    fc: 8,
                    roe: 3,
                    h: 125,
                    cycle: 0,
                  },
                  runs: { total: 99 },
                  outcome: {
                    klook: 447,
                    kswing: 434,
                    ktotal: 881,
                    ball: 1073,
                    iball: 10,
                    dirtball: 42,
                    foul: 391,
                  },
                  outs: {
                    po: 20,
                    fo: 77,
                    fidp: 1,
                    lo: 18,
                    lidp: 0,
                    go: 116,
                    gidp: 6,
                    klook: 79,
                    kswing: 140,
                    ktotal: 219,
                    sacfly: 3,
                    sachit: 0,
                  },
                  steal: { caught: 0, stolen: 1, pct: 1.0, pickoff: 0 },
                  games: { start: 157, play: 157, finish: 0, complete: 143 },
                },
              },
              fielding: {
                overall: {
                  po: 1330,
                  a: 62,
                  dp: 138,
                  tp: 0,
                  error: 10,
                  tc: 1402,
                  fpct: 0.993,
                  c_wp: 0,
                  pb: 0,
                  rf: 8.981,
                  steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                  errors: {
                    throwing: 0,
                    fielding: 0,
                    interference: 0,
                    total: 10,
                  },
                  games: { start: 155, play: 155, finish: 0, complete: 0 },
                },
              },
            },
            splits: {
              hitting: {
                overall: [
                  {
                    total: [
                      {
                        ab: 566,
                        runs: 99,
                        s: 66,
                        d: 21,
                        t: 0,
                        hr: 38,
                        rbi: 84,
                        bb: 85,
                        ibb: 3,
                        hbp: 8,
                        sb: 1,
                        cs: 0,
                        obp: 0.332,
                        slg: 0.459,
                        ops: 0.791,
                        h: 125,
                        ktotal: 219,
                        avg: ".221",
                      },
                    ],
                    day_night: [
                      {
                        value: "day",
                        ab: 164,
                        runs: 29,
                        s: 17,
                        d: 7,
                        t: 0,
                        hr: 10,
                        rbi: 27,
                        bb: 22,
                        ibb: 2,
                        hbp: 4,
                        sb: 0,
                        cs: 0,
                        obp: 0.323,
                        slg: 0.433,
                        ops: 0.756,
                        h: 34,
                        ktotal: 72,
                        avg: ".207",
                      },
                      {
                        value: "night",
                        ab: 402,
                        runs: 70,
                        s: 49,
                        d: 14,
                        t: 0,
                        hr: 28,
                        rbi: 57,
                        bb: 63,
                        ibb: 1,
                        hbp: 4,
                        sb: 1,
                        cs: 0,
                        obp: 0.336,
                        slg: 0.47,
                        ops: 0.806,
                        h: 91,
                        ktotal: 147,
                        avg: ".226",
                      },
                    ],
                    venue: [
                      {
                        id: "2d7542f5-7b80-49f7-9b24-c53ffdc75af6",
                        name: "Oracle Park",
                        market: "San Francisco",
                        surface: "grass",
                        ab: 10,
                        runs: 2,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.385,
                        slg: 0.5,
                        ops: 0.885,
                        h: 2,
                        ktotal: 7,
                        avg: ".200",
                      },
                      {
                        id: "3aaaf4af-0f8c-49c1-8bf1-1780bb5a5f5c",
                        name: "Tropicana Field",
                        market: "Tampa Bay",
                        surface: "turf",
                        ab: 29,
                        runs: 5,
                        s: 3,
                        d: 2,
                        t: 0,
                        hr: 2,
                        rbi: 4,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.29,
                        slg: 0.517,
                        ops: 0.807,
                        h: 7,
                        ktotal: 10,
                        avg: ".241",
                      },
                      {
                        id: "434aa63c-1049-4a54-a6bc-3790c7772ee1",
                        name: "Oriole Park at Camden Yards",
                        market: "Baltimore",
                        surface: "grass",
                        ab: 275,
                        runs: 47,
                        s: 28,
                        d: 9,
                        t: 0,
                        hr: 17,
                        rbi: 39,
                        bb: 46,
                        ibb: 3,
                        hbp: 4,
                        sb: 1,
                        cs: 0,
                        obp: 0.326,
                        slg: 0.415,
                        ops: 0.741,
                        h: 54,
                        ktotal: 102,
                        avg: ".196",
                      },
                      {
                        id: "48cbd35a-d932-4a75-beab-067bfbacfc26",
                        name: "RingCentral Coliseum",
                        market: "Oakland",
                        surface: "grass",
                        ab: 16,
                        runs: 2,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.294,
                        slg: 0.25,
                        ops: 0.544,
                        h: 4,
                        ktotal: 3,
                        avg: ".250",
                      },
                      {
                        id: "60732da9-ad03-4feb-9a36-aee3e98c7a2b",
                        name: "Angel Stadium of Anaheim",
                        market: "Los Angeles",
                        surface: "grass",
                        ab: 12,
                        runs: 3,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 2,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.231,
                        slg: 0.417,
                        ops: 0.648,
                        h: 2,
                        ktotal: 5,
                        avg: ".167",
                      },
                      {
                        id: "66a19c3d-24fe-477d-bee7-c6ef1b98352f",
                        name: "Dodger Stadium",
                        market: "Los Angeles",
                        surface: "grass",
                        ab: 13,
                        runs: 2,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.2,
                        slg: 0.077,
                        ops: 0.277,
                        h: 1,
                        ktotal: 8,
                        avg: ".077",
                      },
                      {
                        id: "706e9828-6687-4ac8-a409-3fb972e8bae9",
                        name: "Yankee Stadium",
                        market: "New York",
                        surface: "grass",
                        ab: 23,
                        runs: 5,
                        s: 3,
                        d: 1,
                        t: 0,
                        hr: 2,
                        rbi: 3,
                        bb: 4,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.393,
                        slg: 0.565,
                        ops: 0.958,
                        h: 6,
                        ktotal: 7,
                        avg: ".261",
                      },
                      {
                        id: "3f47c1c6-b059-4fa2-9d85-5d37b7000992",
                        name: "Globe Life Park in Arlington",
                        market: "Texas",
                        surface: "grass",
                        ab: 14,
                        runs: 4,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 4,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.474,
                        slg: 0.5,
                        ops: 0.974,
                        h: 4,
                        ktotal: 5,
                        avg: ".286",
                      },
                      {
                        id: "bb062fb1-e268-412e-b3d2-2ddb6acc1822",
                        name: "Nationals Park",
                        market: "Washington",
                        surface: "grass",
                        ab: 7,
                        runs: 1,
                        s: 0,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 0.286,
                        ops: 0.536,
                        h: 1,
                        ktotal: 2,
                        avg: ".143",
                      },
                      {
                        id: "2b0ccd49-4d87-4996-ac4d-27ffc7ee4c16",
                        name: "Progressive Field",
                        market: "Cleveland",
                        surface: "grass",
                        ab: 11,
                        runs: 2,
                        s: 1,
                        d: 2,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.385,
                        slg: 0.455,
                        ops: 0.84,
                        h: 3,
                        ktotal: 5,
                        avg: ".273",
                      },
                      {
                        id: "6fca95c9-7f2c-4acb-a9f3-02ef96340d2a",
                        name: "Kauffman Stadium",
                        market: "Kansas City",
                        surface: "grass",
                        ab: 13,
                        runs: 3,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 2,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.308,
                        slg: 0.538,
                        ops: 0.846,
                        h: 4,
                        ktotal: 5,
                        avg: ".308",
                      },
                      {
                        id: "f1c03dac-3c0f-437c-a325-8d5702cd321a",
                        name: "T-Mobile Park",
                        market: "Seattle",
                        surface: "grass",
                        ab: 17,
                        runs: 3,
                        s: 2,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 4,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.278,
                        slg: 0.588,
                        ops: 0.866,
                        h: 4,
                        ktotal: 6,
                        avg: ".235",
                      },
                      {
                        id: "84d72338-2173-4a90-9d25-99adc6c86f4b",
                        name: "Rogers Centre",
                        market: "Toronto",
                        surface: "turf",
                        ab: 37,
                        runs: 6,
                        s: 5,
                        d: 1,
                        t: 0,
                        hr: 4,
                        rbi: 9,
                        bb: 5,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.341,
                        slg: 0.622,
                        ops: 0.963,
                        h: 10,
                        ktotal: 17,
                        avg: ".270",
                      },
                      {
                        id: "b27b0dc5-c2c5-401d-b8b9-a6a2dbc225e5",
                        name: "Fenway Park",
                        market: "Boston",
                        surface: "grass",
                        ab: 34,
                        runs: 8,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 3,
                        rbi: 9,
                        bb: 5,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.308,
                        slg: 0.471,
                        ops: 0.779,
                        h: 7,
                        ktotal: 13,
                        avg: ".206",
                      },
                      {
                        id: "302f8dcd-eed6-4b83-8609-81548d51e955",
                        name: "Target Field",
                        market: "Minnesota",
                        surface: "grass",
                        ab: 14,
                        runs: 2,
                        s: 5,
                        d: 3,
                        t: 0,
                        hr: 1,
                        rbi: 4,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.643,
                        slg: 1.071,
                        ops: 1.714,
                        h: 9,
                        ktotal: 2,
                        avg: ".643",
                      },
                      {
                        id: "ef9a5eef-06c6-4963-ac70-7fd02c8c8d42",
                        name: "Comerica Park",
                        market: "Detroit",
                        surface: "grass",
                        ab: 9,
                        runs: 1,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 2,
                        bb: 2,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.444,
                        ops: 0.777,
                        h: 1,
                        ktotal: 6,
                        avg: ".111",
                      },
                      {
                        id: "f0600f04-1386-4c61-a412-804960c46cb1",
                        name: "Minute Maid Park",
                        market: "Houston",
                        surface: "grass",
                        ab: 14,
                        runs: 1,
                        s: 1,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 0.214,
                        ops: 0.464,
                        h: 2,
                        ktotal: 8,
                        avg: ".143",
                      },
                      {
                        id: "0ab45d79-5475-4308-9f94-74b0c185ee6f",
                        name: "PETCO Park",
                        market: "San Diego",
                        surface: "grass",
                        ab: 9,
                        runs: 1,
                        s: 1,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 2,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.3,
                        slg: 0.778,
                        ops: 1.078,
                        h: 3,
                        ktotal: 3,
                        avg: ".333",
                      },
                      {
                        id: "4a550fc1-c03d-4294-b63a-30ff197bedc4",
                        name: "Guaranteed Rate Field",
                        market: "Chicago",
                        surface: "grass",
                        ab: 9,
                        runs: 1,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 4,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.429,
                        slg: 0.444,
                        ops: 0.873,
                        h: 1,
                        ktotal: 5,
                        avg: ".111",
                      },
                    ],
                    surface: [
                      {
                        value: "turf",
                        ab: 66,
                        runs: 11,
                        s: 8,
                        d: 3,
                        t: 0,
                        hr: 6,
                        rbi: 13,
                        bb: 7,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.32,
                        slg: 0.576,
                        ops: 0.896,
                        h: 17,
                        ktotal: 27,
                        avg: ".258",
                      },
                      {
                        value: "grass",
                        ab: 500,
                        runs: 88,
                        s: 58,
                        d: 18,
                        t: 0,
                        hr: 32,
                        rbi: 71,
                        bb: 78,
                        ibb: 3,
                        hbp: 8,
                        sb: 1,
                        cs: 0,
                        obp: 0.334,
                        slg: 0.444,
                        ops: 0.778,
                        h: 108,
                        ktotal: 192,
                        avg: ".216",
                      },
                    ],
                    month: [
                      {
                        value: "7",
                        ab: 85,
                        runs: 9,
                        s: 9,
                        d: 1,
                        t: 0,
                        hr: 3,
                        rbi: 7,
                        bb: 14,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.273,
                        slg: 0.271,
                        ops: 0.544,
                        h: 13,
                        ktotal: 37,
                        avg: ".153",
                      },
                      {
                        value: "5",
                        ab: 100,
                        runs: 15,
                        s: 10,
                        d: 8,
                        t: 0,
                        hr: 3,
                        rbi: 11,
                        bb: 13,
                        ibb: 0,
                        hbp: 2,
                        sb: 0,
                        cs: 0,
                        obp: 0.313,
                        slg: 0.38,
                        ops: 0.693,
                        h: 21,
                        ktotal: 42,
                        avg: ".210",
                      },
                      {
                        value: "4",
                        ab: 85,
                        runs: 19,
                        s: 11,
                        d: 2,
                        t: 0,
                        hr: 7,
                        rbi: 17,
                        bb: 15,
                        ibb: 1,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.356,
                        slg: 0.506,
                        ops: 0.862,
                        h: 20,
                        ktotal: 28,
                        avg: ".235",
                      },
                      {
                        value: "8",
                        ab: 98,
                        runs: 18,
                        s: 9,
                        d: 2,
                        t: 0,
                        hr: 10,
                        rbi: 13,
                        bb: 17,
                        ibb: 0,
                        hbp: 2,
                        sb: 1,
                        cs: 0,
                        obp: 0.342,
                        slg: 0.541,
                        ops: 0.883,
                        h: 21,
                        ktotal: 37,
                        avg: ".214",
                      },
                      {
                        value: "9",
                        ab: 96,
                        runs: 14,
                        s: 14,
                        d: 2,
                        t: 0,
                        hr: 6,
                        rbi: 12,
                        bb: 12,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.321,
                        slg: 0.438,
                        ops: 0.759,
                        h: 22,
                        ktotal: 35,
                        avg: ".229",
                      },
                      {
                        value: "10",
                        ab: 7,
                        runs: 1,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 0.143,
                        ops: 0.393,
                        h: 1,
                        ktotal: 3,
                        avg: ".143",
                      },
                      {
                        value: "6",
                        ab: 95,
                        runs: 23,
                        s: 12,
                        d: 6,
                        t: 0,
                        hr: 9,
                        rbi: 24,
                        bb: 13,
                        ibb: 2,
                        hbp: 3,
                        sb: 0,
                        cs: 0,
                        obp: 0.388,
                        slg: 0.632,
                        ops: 1.02,
                        h: 27,
                        ktotal: 37,
                        avg: ".284",
                      },
                    ],
                    home_away: [
                      {
                        value: "home",
                        ab: 275,
                        runs: 47,
                        s: 28,
                        d: 9,
                        t: 0,
                        hr: 17,
                        rbi: 39,
                        bb: 46,
                        ibb: 3,
                        hbp: 4,
                        sb: 1,
                        cs: 0,
                        obp: 0.326,
                        slg: 0.415,
                        ops: 0.741,
                        h: 54,
                        ktotal: 102,
                        avg: ".196",
                      },
                      {
                        value: "away",
                        ab: 291,
                        runs: 52,
                        s: 38,
                        d: 12,
                        t: 0,
                        hr: 21,
                        rbi: 45,
                        bb: 39,
                        ibb: 0,
                        hbp: 4,
                        sb: 0,
                        cs: 0,
                        obp: 0.338,
                        slg: 0.502,
                        ops: 0.84,
                        h: 71,
                        ktotal: 117,
                        avg: ".244",
                      },
                    ],
                    pitcher_hand: [
                      {
                        value: "L",
                        ab: 176,
                        runs: 25,
                        s: 22,
                        d: 8,
                        t: 0,
                        hr: 8,
                        rbi: 29,
                        bb: 23,
                        ibb: 0,
                        hbp: 2,
                        sb: 1,
                        cs: 0,
                        obp: 0.313,
                        slg: 0.398,
                        ops: 0.711,
                        h: 38,
                        ktotal: 64,
                        avg: ".216",
                      },
                      {
                        value: "B",
                        ab: 1,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.0,
                        slg: 0.0,
                        ops: 0.0,
                        h: 0,
                        ktotal: 0,
                        avg: ".000",
                      },
                      {
                        value: "R",
                        ab: 390,
                        runs: 74,
                        s: 44,
                        d: 13,
                        t: 0,
                        hr: 30,
                        rbi: 55,
                        bb: 62,
                        ibb: 3,
                        hbp: 6,
                        sb: 0,
                        cs: 0,
                        obp: 0.341,
                        slg: 0.487,
                        ops: 0.828,
                        h: 87,
                        ktotal: 155,
                        avg: ".223",
                      },
                    ],
                    opponent: [
                      {
                        id: "d99f919b-1534-4516-8e8a-9cd106c6d8cd",
                        name: "Rangers",
                        market: "Texas",
                        abbr: "TEX",
                        ab: 24,
                        runs: 5,
                        s: 5,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 2,
                        bb: 5,
                        ibb: 0,
                        hbp: 1,
                        sb: 1,
                        cs: 0,
                        obp: 0.4,
                        slg: 0.375,
                        ops: 0.775,
                        h: 6,
                        ktotal: 8,
                        avg: ".250",
                      },
                      {
                        id: "93941372-eb4c-4c40-aced-fe3267174393",
                        name: "Red Sox",
                        market: "Boston",
                        abbr: "BOS",
                        ab: 66,
                        runs: 15,
                        s: 6,
                        d: 0,
                        t: 0,
                        hr: 4,
                        rbi: 10,
                        bb: 12,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.282,
                        slg: 0.333,
                        ops: 0.615,
                        h: 10,
                        ktotal: 23,
                        avg: ".152",
                      },
                      {
                        id: "d89bed32-3aee-4407-99e3-4103641b999a",
                        name: "Nationals",
                        market: "Washington",
                        abbr: "WSH",
                        ab: 15,
                        runs: 4,
                        s: 1,
                        d: 2,
                        t: 0,
                        hr: 1,
                        rbi: 2,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.353,
                        slg: 0.6,
                        ops: 0.953,
                        h: 4,
                        ktotal: 5,
                        avg: ".267",
                      },
                      {
                        id: "bdc11650-6f74-49c4-875e-778aeb7632d9",
                        name: "Rays",
                        market: "Tampa Bay",
                        abbr: "TB",
                        ab: 66,
                        runs: 15,
                        s: 7,
                        d: 4,
                        t: 0,
                        hr: 6,
                        rbi: 14,
                        bb: 6,
                        ibb: 1,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.329,
                        slg: 0.591,
                        ops: 0.92,
                        h: 17,
                        ktotal: 23,
                        avg: ".258",
                      },
                      {
                        id: "833a51a9-0d84-410f-bd77-da08c3e5e26e",
                        name: "Royals",
                        market: "Kansas City",
                        abbr: "KC",
                        ab: 19,
                        runs: 4,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 3,
                        bb: 1,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.579,
                        ops: 0.912,
                        h: 5,
                        ktotal: 8,
                        avg: ".263",
                      },
                      {
                        id: "27a59d3b-ff7c-48ea-b016-4798f560f5e1",
                        name: "Athletics",
                        market: "Oakland",
                        abbr: "OAK",
                        ab: 26,
                        runs: 4,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 2,
                        bb: 2,
                        ibb: 0,
                        hbp: 2,
                        sb: 0,
                        cs: 0,
                        obp: 0.3,
                        slg: 0.308,
                        ops: 0.608,
                        h: 5,
                        ktotal: 7,
                        avg: ".192",
                      },
                      {
                        id: "4f735188-37c8-473d-ae32-1f7e34ccf892",
                        name: "Angels",
                        market: "Los Angeles",
                        abbr: "LAA",
                        ab: 22,
                        runs: 5,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 4,
                        bb: 4,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.346,
                        slg: 0.5,
                        ops: 0.846,
                        h: 5,
                        ktotal: 8,
                        avg: ".227",
                      },
                      {
                        id: "a7723160-10b7-4277-a309-d8dd95a8ae65",
                        name: "Giants",
                        market: "San Francisco",
                        abbr: "SF",
                        ab: 10,
                        runs: 2,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.385,
                        slg: 0.5,
                        ops: 0.885,
                        h: 2,
                        ktotal: 7,
                        avg: ".200",
                      },
                      {
                        id: "eb21dadd-8f10-4095-8bf3-dfb3b779f107",
                        name: "Astros",
                        market: "Houston",
                        abbr: "HOU",
                        ab: 29,
                        runs: 5,
                        s: 1,
                        d: 1,
                        t: 0,
                        hr: 4,
                        rbi: 4,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.281,
                        slg: 0.655,
                        ops: 0.936,
                        h: 6,
                        ktotal: 12,
                        avg: ".207",
                      },
                      {
                        id: "a09ec676-f887-43dc-bbb3-cf4bbaee9a18",
                        name: "Yankees",
                        market: "New York",
                        abbr: "NYY",
                        ab: 55,
                        runs: 9,
                        s: 7,
                        d: 1,
                        t: 0,
                        hr: 4,
                        rbi: 6,
                        bb: 8,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.328,
                        slg: 0.455,
                        ops: 0.783,
                        h: 12,
                        ktotal: 24,
                        avg: ".218",
                      },
                      {
                        id: "47f490cd-2f58-4ef7-9dfd-2ad6ba6c1ae8",
                        name: "White Sox",
                        market: "Chicago",
                        abbr: "CWS",
                        ab: 25,
                        runs: 3,
                        s: 4,
                        d: 1,
                        t: 0,
                        hr: 2,
                        rbi: 5,
                        bb: 4,
                        ibb: 1,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.419,
                        slg: 0.56,
                        ops: 0.979,
                        h: 7,
                        ktotal: 8,
                        avg: ".280",
                      },
                      {
                        id: "25507be1-6a68-4267-bd82-e097d94b359b",
                        name: "Diamondbacks",
                        market: "Arizona",
                        abbr: "ARI",
                        ab: 13,
                        runs: 0,
                        s: 2,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 2,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.267,
                        slg: 0.154,
                        ops: 0.421,
                        h: 2,
                        ktotal: 6,
                        avg: ".154",
                      },
                      {
                        id: "80715d0d-0d2a-450f-a970-1b9a3b18c7e7",
                        name: "Indians",
                        market: "Cleveland",
                        abbr: "CLE",
                        ab: 22,
                        runs: 3,
                        s: 1,
                        d: 2,
                        t: 0,
                        hr: 0,
                        rbi: 2,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.24,
                        slg: 0.227,
                        ops: 0.467,
                        h: 3,
                        ktotal: 11,
                        avg: ".136",
                      },
                      {
                        id: "575c19b7-4052-41c2-9f0a-1c5813d02f99",
                        name: "Tigers",
                        market: "Detroit",
                        abbr: "DET",
                        ab: 20,
                        runs: 5,
                        s: 3,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 4,
                        bb: 6,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.444,
                        slg: 0.45,
                        ops: 0.894,
                        h: 5,
                        ktotal: 10,
                        avg: ".250",
                      },
                      {
                        id: "1d678440-b4b1-4954-9b39-70afb3ebbcfa",
                        name: "Blue Jays",
                        market: "Toronto",
                        abbr: "TOR",
                        ab: 64,
                        runs: 10,
                        s: 7,
                        d: 3,
                        t: 0,
                        hr: 4,
                        rbi: 11,
                        bb: 14,
                        ibb: 1,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.358,
                        slg: 0.453,
                        ops: 0.811,
                        h: 14,
                        ktotal: 24,
                        avg: ".219",
                      },
                      {
                        id: "43a39081-52b4-4f93-ad29-da7f329ea960",
                        name: "Mariners",
                        market: "Seattle",
                        abbr: "SEA",
                        ab: 26,
                        runs: 3,
                        s: 2,
                        d: 1,
                        t: 0,
                        hr: 2,
                        rbi: 4,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.276,
                        slg: 0.462,
                        ops: 0.738,
                        h: 5,
                        ktotal: 9,
                        avg: ".192",
                      },
                      {
                        id: "d52d5339-cbdd-43f3-9dfa-a42fd588b9a3",
                        name: "Padres",
                        market: "San Diego",
                        abbr: "SD",
                        ab: 17,
                        runs: 1,
                        s: 1,
                        d: 2,
                        t: 0,
                        hr: 1,
                        rbi: 3,
                        bb: 0,
                        ibb: 0,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.263,
                        slg: 0.529,
                        ops: 0.792,
                        h: 4,
                        ktotal: 8,
                        avg: ".235",
                      },
                      {
                        id: "29dd9a87-5bcc-4774-80c3-7f50d985068b",
                        name: "Rockies",
                        market: "Colorado",
                        abbr: "COL",
                        ab: 9,
                        runs: 0,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.111,
                        ops: 0.444,
                        h: 1,
                        ktotal: 5,
                        avg: ".111",
                      },
                      {
                        id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                        name: "Twins",
                        market: "Minnesota",
                        abbr: "MIN",
                        ab: 25,
                        runs: 4,
                        s: 6,
                        d: 3,
                        t: 0,
                        hr: 2,
                        rbi: 5,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.481,
                        slg: 0.8,
                        ops: 1.281,
                        h: 11,
                        ktotal: 5,
                        avg: ".440",
                      },
                      {
                        id: "ef64da7f-cfaf-4300-87b0-9313386b977c",
                        name: "Dodgers",
                        market: "Los Angeles",
                        abbr: "LAD",
                        ab: 13,
                        runs: 2,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.2,
                        slg: 0.077,
                        ops: 0.277,
                        h: 1,
                        ktotal: 8,
                        avg: ".077",
                      },
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
      {
        id: "9523f039-070c-49d1-b2e3-5f18b57c5ee3",
        year: 2015,
        type: "REG",
        totals: {
          statistics: {
            hitting: {
              overall: {
                ab: 573,
                lob: 238,
                rbi: 117,
                abhr: 12.191,
                abk: 2.755,
                bip: 323,
                babip: 0.319,
                bbk: 0.404,
                bbpa: 0.125,
                iso: 0.3,
                obp: 0.361,
                ops: 0.923,
                seca: 0.445,
                slg: 0.562,
                xbh: 78,
                pitch_count: 2785,
                ap: 670,
                avg: ".262",
                gofo: 0.93,
                onbase: {
                  s: 72,
                  d: 31,
                  t: 0,
                  hr: 47,
                  tb: 322,
                  bb: 78,
                  ibb: 6,
                  hbp: 8,
                  fc: 8,
                  roe: 4,
                  h: 150,
                  cycle: 0,
                },
                runs: { total: 100 },
                outcome: {
                  klook: 348,
                  kswing: 470,
                  ktotal: 818,
                  ball: 1034,
                  iball: 20,
                  dirtball: 59,
                  foul: 477,
                },
                outs: {
                  po: 18,
                  fo: 72,
                  fidp: 0,
                  lo: 27,
                  lidp: 2,
                  go: 109,
                  gidp: 6,
                  klook: 56,
                  kswing: 152,
                  ktotal: 208,
                  sacfly: 5,
                  sachit: 0,
                },
                steal: { caught: 3, stolen: 2, pct: 0.4, pickoff: 2 },
                games: { start: 159, play: 160, finish: 1, complete: 148 },
              },
            },
            fielding: {
              overall: {
                po: 921,
                a: 55,
                dp: 77,
                tp: 0,
                error: 3,
                tc: 979,
                fpct: 0.997,
                rf: 6.922,
                steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                errors: { throwing: 0, fielding: 0, interference: 0, total: 3 },
                games: { start: 137, play: 141, finish: 4, complete: 3 },
              },
            },
          },
          splits: {
            hitting: {
              overall: [
                {
                  total: [
                    {
                      ab: 573,
                      runs: 100,
                      s: 72,
                      d: 31,
                      t: 0,
                      hr: 47,
                      rbi: 117,
                      bb: 78,
                      ibb: 6,
                      hbp: 8,
                      sb: 2,
                      cs: 3,
                      obp: 0.361,
                      slg: 0.562,
                      ops: 0.923,
                      h: 150,
                      ktotal: 208,
                      avg: ".262",
                    },
                  ],
                  day_night: [
                    {
                      value: "night",
                      ab: 382,
                      runs: 64,
                      s: 48,
                      d: 16,
                      t: 0,
                      hr: 31,
                      rbi: 76,
                      bb: 45,
                      ibb: 5,
                      hbp: 7,
                      sb: 2,
                      cs: 3,
                      obp: 0.344,
                      slg: 0.534,
                      ops: 0.878,
                      h: 95,
                      ktotal: 141,
                      avg: ".249",
                    },
                    {
                      value: "day",
                      ab: 191,
                      runs: 36,
                      s: 24,
                      d: 15,
                      t: 0,
                      hr: 16,
                      rbi: 41,
                      bb: 33,
                      ibb: 1,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.395,
                      slg: 0.618,
                      ops: 1.013,
                      h: 55,
                      ktotal: 67,
                      avg: ".288",
                    },
                  ],
                  venue: [
                    {
                      id: "bb062fb1-e268-412e-b3d2-2ddb6acc1822",
                      name: "Nationals Park",
                      market: "Washington",
                      surface: "grass",
                      ab: 12,
                      runs: 2,
                      s: 0,
                      d: 2,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.286,
                      slg: 0.333,
                      ops: 0.619,
                      h: 2,
                      ktotal: 1,
                      avg: ".167",
                    },
                    {
                      id: "706e9828-6687-4ac8-a409-3fb972e8bae9",
                      name: "Yankee Stadium",
                      market: "New York",
                      surface: "grass",
                      ab: 35,
                      runs: 5,
                      s: 3,
                      d: 2,
                      t: 0,
                      hr: 4,
                      rbi: 7,
                      bb: 5,
                      ibb: 1,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.381,
                      slg: 0.657,
                      ops: 1.038,
                      h: 9,
                      ktotal: 13,
                      avg: ".257",
                    },
                    {
                      id: "b4186004-b6d0-42e7-8dc1-82ef0d946ecc",
                      name: "Citizens Bank Park",
                      market: "Philadelphia",
                      surface: "grass",
                      ab: 8,
                      runs: 0,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 0,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.0,
                      slg: 0.0,
                      ops: 0.0,
                      h: 0,
                      ktotal: 3,
                      avg: ".000",
                    },
                    {
                      id: "434aa63c-1049-4a54-a6bc-3790c7772ee1",
                      name: "Oriole Park at Camden Yards",
                      market: "Baltimore",
                      surface: "grass",
                      ab: 274,
                      runs: 61,
                      s: 36,
                      d: 13,
                      t: 0,
                      hr: 29,
                      rbi: 69,
                      bb: 34,
                      ibb: 3,
                      hbp: 5,
                      sb: 1,
                      cs: 3,
                      obp: 0.376,
                      slg: 0.65,
                      ops: 1.026,
                      h: 78,
                      ktotal: 94,
                      avg: ".285",
                    },
                    {
                      id: "302f8dcd-eed6-4b83-8609-81548d51e955",
                      name: "Target Field",
                      market: "Minnesota",
                      surface: "grass",
                      ab: 12,
                      runs: 1,
                      s: 2,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.667,
                      ops: 1.0,
                      h: 4,
                      ktotal: 4,
                      avg: ".333",
                    },
                    {
                      id: "f0600f04-1386-4c61-a412-804960c46cb1",
                      name: "Minute Maid Park",
                      market: "Houston",
                      surface: "grass",
                      ab: 15,
                      runs: 0,
                      s: 1,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 2,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.188,
                      slg: 0.2,
                      ops: 0.388,
                      h: 2,
                      ktotal: 4,
                      avg: ".133",
                    },
                    {
                      id: "ef9a5eef-06c6-4963-ac70-7fd02c8c8d42",
                      name: "Comerica Park",
                      market: "Detroit",
                      surface: "grass",
                      ab: 13,
                      runs: 1,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.357,
                      slg: 0.308,
                      ops: 0.665,
                      h: 4,
                      ktotal: 4,
                      avg: ".308",
                    },
                    {
                      id: "265c7d6c-427a-4b8a-8def-392c41954bec",
                      name: "Citi Field",
                      market: "New York",
                      surface: "grass",
                      ab: 8,
                      runs: 1,
                      s: 2,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.375,
                      slg: 0.75,
                      ops: 1.125,
                      h: 3,
                      ktotal: 5,
                      avg: ".375",
                    },
                    {
                      id: "6fca95c9-7f2c-4acb-a9f3-02ef96340d2a",
                      name: "Kauffman Stadium",
                      market: "Kansas City",
                      surface: "grass",
                      ab: 14,
                      runs: 1,
                      s: 0,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 2,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.2,
                      slg: 0.429,
                      ops: 0.629,
                      h: 2,
                      ktotal: 9,
                      avg: ".143",
                    },
                    {
                      id: "84d72338-2173-4a90-9d25-99adc6c86f4b",
                      name: "Rogers Centre",
                      market: "Toronto",
                      surface: "turf",
                      ab: 32,
                      runs: 6,
                      s: 5,
                      d: 1,
                      t: 0,
                      hr: 3,
                      rbi: 5,
                      bb: 7,
                      ibb: 1,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.425,
                      slg: 0.594,
                      ops: 1.019,
                      h: 9,
                      ktotal: 12,
                      avg: ".281",
                    },
                    {
                      id: "3f47c1c6-b059-4fa2-9d85-5d37b7000992",
                      name: "Globe Life Park in Arlington",
                      market: "Texas",
                      surface: "grass",
                      ab: 11,
                      runs: 0,
                      s: 2,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 0.182,
                      ops: 0.432,
                      h: 2,
                      ktotal: 5,
                      avg: ".182",
                    },
                    {
                      id: "d5a66fb3-ff26-4f36-910c-3df5cedb36b3",
                      name: "Marlins Park",
                      market: "Miami",
                      surface: "grass",
                      ab: 12,
                      runs: 1,
                      s: 0,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.267,
                      slg: 0.167,
                      ops: 0.434,
                      h: 1,
                      ktotal: 5,
                      avg: ".083",
                    },
                    {
                      id: "48cbd35a-d932-4a75-beab-067bfbacfc26",
                      name: "RingCentral Coliseum",
                      market: "Oakland",
                      surface: "grass",
                      ab: 12,
                      runs: 3,
                      s: 1,
                      d: 1,
                      t: 0,
                      hr: 2,
                      rbi: 7,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.429,
                      slg: 0.917,
                      ops: 1.346,
                      h: 4,
                      ktotal: 5,
                      avg: ".333",
                    },
                    {
                      id: "b27b0dc5-c2c5-401d-b8b9-a6a2dbc225e5",
                      name: "Fenway Park",
                      market: "Boston",
                      surface: "grass",
                      ab: 37,
                      runs: 4,
                      s: 5,
                      d: 3,
                      t: 0,
                      hr: 1,
                      rbi: 4,
                      bb: 5,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.405,
                      ops: 0.738,
                      h: 9,
                      ktotal: 14,
                      avg: ".243",
                    },
                    {
                      id: "2b0ccd49-4d87-4996-ac4d-27ffc7ee4c16",
                      name: "Progressive Field",
                      market: "Cleveland",
                      surface: "grass",
                      ab: 9,
                      runs: 1,
                      s: 1,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.385,
                      slg: 0.333,
                      ops: 0.718,
                      h: 2,
                      ktotal: 4,
                      avg: ".222",
                    },
                    {
                      id: "4a550fc1-c03d-4294-b63a-30ff197bedc4",
                      name: "Guaranteed Rate Field",
                      market: "Chicago",
                      surface: "grass",
                      ab: 11,
                      runs: 1,
                      s: 3,
                      d: 0,
                      t: 0,
                      hr: 0,
                      rbi: 2,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.308,
                      slg: 0.273,
                      ops: 0.581,
                      h: 3,
                      ktotal: 2,
                      avg: ".273",
                    },
                    {
                      id: "3aaaf4af-0f8c-49c1-8bf1-1780bb5a5f5c",
                      name: "Tropicana Field",
                      market: "Tampa Bay",
                      surface: "turf",
                      ab: 38,
                      runs: 5,
                      s: 4,
                      d: 3,
                      t: 0,
                      hr: 2,
                      rbi: 8,
                      bb: 8,
                      ibb: 1,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.396,
                      slg: 0.474,
                      ops: 0.87,
                      h: 9,
                      ktotal: 17,
                      avg: ".237",
                    },
                    {
                      id: "60732da9-ad03-4feb-9a36-aee3e98c7a2b",
                      name: "Angel Stadium of Anaheim",
                      market: "Los Angeles",
                      surface: "grass",
                      ab: 10,
                      runs: 4,
                      s: 1,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 2,
                      ibb: 0,
                      hbp: 1,
                      sb: 1,
                      cs: 0,
                      obp: 0.462,
                      slg: 0.7,
                      ops: 1.162,
                      h: 3,
                      ktotal: 4,
                      avg: ".300",
                    },
                    {
                      id: "f1c03dac-3c0f-437c-a325-8d5702cd321a",
                      name: "T-Mobile Park",
                      market: "Seattle",
                      surface: "grass",
                      ab: 10,
                      runs: 3,
                      s: 2,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 3,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.5,
                      slg: 1.0,
                      ops: 1.5,
                      h: 4,
                      ktotal: 3,
                      avg: ".400",
                    },
                  ],
                  surface: [
                    {
                      value: "turf",
                      ab: 70,
                      runs: 11,
                      s: 9,
                      d: 4,
                      t: 0,
                      hr: 5,
                      rbi: 13,
                      bb: 15,
                      ibb: 2,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.409,
                      slg: 0.529,
                      ops: 0.938,
                      h: 18,
                      ktotal: 29,
                      avg: ".257",
                    },
                    {
                      value: "grass",
                      ab: 503,
                      runs: 89,
                      s: 63,
                      d: 27,
                      t: 0,
                      hr: 42,
                      rbi: 104,
                      bb: 63,
                      ibb: 4,
                      hbp: 7,
                      sb: 2,
                      cs: 3,
                      obp: 0.354,
                      slg: 0.567,
                      ops: 0.921,
                      h: 132,
                      ktotal: 179,
                      avg: ".262",
                    },
                  ],
                  month: [
                    {
                      value: "7",
                      ab: 92,
                      runs: 12,
                      s: 15,
                      d: 3,
                      t: 0,
                      hr: 7,
                      rbi: 21,
                      bb: 6,
                      ibb: 2,
                      hbp: 0,
                      sb: 0,
                      cs: 1,
                      obp: 0.327,
                      slg: 0.533,
                      ops: 0.86,
                      h: 25,
                      ktotal: 30,
                      avg: ".272",
                    },
                    {
                      value: "6",
                      ab: 101,
                      runs: 15,
                      s: 16,
                      d: 4,
                      t: 0,
                      hr: 6,
                      rbi: 19,
                      bb: 15,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.35,
                      slg: 0.475,
                      ops: 0.825,
                      h: 26,
                      ktotal: 29,
                      avg: ".257",
                    },
                    {
                      value: "10",
                      ab: 12,
                      runs: 5,
                      s: 1,
                      d: 3,
                      t: 0,
                      hr: 2,
                      rbi: 5,
                      bb: 5,
                      ibb: 1,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.684,
                      slg: 1.25,
                      ops: 1.934,
                      h: 6,
                      ktotal: 2,
                      avg: ".500",
                    },
                    {
                      value: "9",
                      ab: 95,
                      runs: 22,
                      s: 11,
                      d: 7,
                      t: 0,
                      hr: 10,
                      rbi: 20,
                      bb: 15,
                      ibb: 3,
                      hbp: 4,
                      sb: 0,
                      cs: 1,
                      obp: 0.427,
                      slg: 0.684,
                      ops: 1.111,
                      h: 28,
                      ktotal: 31,
                      avg: ".295",
                    },
                    {
                      value: "5",
                      ab: 97,
                      runs: 14,
                      s: 7,
                      d: 5,
                      t: 0,
                      hr: 7,
                      rbi: 14,
                      bb: 15,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.301,
                      slg: 0.464,
                      ops: 0.765,
                      h: 19,
                      ktotal: 40,
                      avg: ".196",
                    },
                    {
                      value: "4",
                      ab: 71,
                      runs: 12,
                      s: 9,
                      d: 5,
                      t: 0,
                      hr: 5,
                      rbi: 16,
                      bb: 6,
                      ibb: 0,
                      hbp: 2,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.549,
                      ops: 0.882,
                      h: 19,
                      ktotal: 29,
                      avg: ".268",
                    },
                    {
                      value: "8",
                      ab: 105,
                      runs: 20,
                      s: 13,
                      d: 4,
                      t: 0,
                      hr: 10,
                      rbi: 22,
                      bb: 16,
                      ibb: 0,
                      hbp: 1,
                      sb: 2,
                      cs: 1,
                      obp: 0.361,
                      slg: 0.581,
                      ops: 0.942,
                      h: 27,
                      ktotal: 47,
                      avg: ".257",
                    },
                  ],
                  home_away: [
                    {
                      value: "home",
                      ab: 283,
                      runs: 62,
                      s: 38,
                      d: 13,
                      t: 0,
                      hr: 29,
                      rbi: 70,
                      bb: 36,
                      ibb: 3,
                      hbp: 5,
                      sb: 1,
                      cs: 3,
                      obp: 0.376,
                      slg: 0.636,
                      ops: 1.012,
                      h: 80,
                      ktotal: 100,
                      avg: ".283",
                    },
                    {
                      value: "away",
                      ab: 290,
                      runs: 38,
                      s: 34,
                      d: 18,
                      t: 0,
                      hr: 18,
                      rbi: 47,
                      bb: 42,
                      ibb: 3,
                      hbp: 3,
                      sb: 1,
                      cs: 0,
                      obp: 0.347,
                      slg: 0.49,
                      ops: 0.837,
                      h: 70,
                      ktotal: 108,
                      avg: ".241",
                    },
                  ],
                  pitcher_hand: [
                    {
                      value: "L",
                      ab: 189,
                      runs: 24,
                      s: 33,
                      d: 8,
                      t: 0,
                      hr: 9,
                      rbi: 30,
                      bb: 16,
                      ibb: 0,
                      hbp: 2,
                      sb: 1,
                      cs: 0,
                      obp: 0.327,
                      slg: 0.45,
                      ops: 0.777,
                      h: 50,
                      ktotal: 58,
                      avg: ".265",
                    },
                    {
                      value: "R",
                      ab: 381,
                      runs: 75,
                      s: 39,
                      d: 23,
                      t: 0,
                      hr: 37,
                      rbi: 86,
                      bb: 62,
                      ibb: 6,
                      hbp: 6,
                      sb: 1,
                      cs: 3,
                      obp: 0.377,
                      slg: 0.612,
                      ops: 0.989,
                      h: 99,
                      ktotal: 148,
                      avg: ".260",
                    },
                    {
                      value: "B",
                      ab: 1,
                      runs: 1,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 0,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 1.0,
                      slg: 4.0,
                      ops: 5.0,
                      h: 1,
                      ktotal: 0,
                      avg: "1.000",
                    },
                  ],
                  opponent: [
                    {
                      id: "43a39081-52b4-4f93-ad29-da7f329ea960",
                      name: "Mariners",
                      market: "Seattle",
                      abbr: "SEA",
                      ab: 20,
                      runs: 5,
                      s: 3,
                      d: 1,
                      t: 0,
                      hr: 2,
                      rbi: 3,
                      bb: 5,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.44,
                      slg: 0.65,
                      ops: 1.09,
                      h: 6,
                      ktotal: 6,
                      avg: ".300",
                    },
                    {
                      id: "f246a5e5-afdb-479c-9aaa-c68beeda7af6",
                      name: "Mets",
                      market: "New York",
                      abbr: "NYM",
                      ab: 14,
                      runs: 3,
                      s: 2,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 1,
                      cs: 0,
                      obp: 0.375,
                      slg: 0.571,
                      ops: 0.946,
                      h: 4,
                      ktotal: 10,
                      avg: ".286",
                    },
                    {
                      id: "47f490cd-2f58-4ef7-9dfd-2ad6ba6c1ae8",
                      name: "White Sox",
                      market: "Chicago",
                      abbr: "CWS",
                      ab: 21,
                      runs: 4,
                      s: 3,
                      d: 1,
                      t: 0,
                      hr: 2,
                      rbi: 8,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.36,
                      slg: 0.619,
                      ops: 0.979,
                      h: 6,
                      ktotal: 4,
                      avg: ".286",
                    },
                    {
                      id: "03556285-bdbb-4576-a06d-42f71f46ddc5",
                      name: "Marlins",
                      market: "Miami",
                      abbr: "MIA",
                      ab: 12,
                      runs: 1,
                      s: 0,
                      d: 1,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.267,
                      slg: 0.167,
                      ops: 0.434,
                      h: 1,
                      ktotal: 5,
                      avg: ".083",
                    },
                    {
                      id: "575c19b7-4052-41c2-9f0a-1c5813d02f99",
                      name: "Tigers",
                      market: "Detroit",
                      abbr: "DET",
                      ab: 27,
                      runs: 4,
                      s: 7,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 8,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 1,
                      obp: 0.4,
                      slg: 0.556,
                      ops: 0.956,
                      h: 9,
                      ktotal: 9,
                      avg: ".333",
                    },
                    {
                      id: "93941372-eb4c-4c40-aced-fe3267174393",
                      name: "Red Sox",
                      market: "Boston",
                      abbr: "BOS",
                      ab: 72,
                      runs: 13,
                      s: 13,
                      d: 4,
                      t: 0,
                      hr: 3,
                      rbi: 11,
                      bb: 7,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.458,
                      ops: 0.791,
                      h: 20,
                      ktotal: 21,
                      avg: ".278",
                    },
                    {
                      id: "833a51a9-0d84-410f-bd77-da08c3e5e26e",
                      name: "Royals",
                      market: "Kansas City",
                      abbr: "KC",
                      ab: 23,
                      runs: 7,
                      s: 2,
                      d: 2,
                      t: 0,
                      hr: 2,
                      rbi: 4,
                      bb: 4,
                      ibb: 0,
                      hbp: 2,
                      sb: 0,
                      cs: 0,
                      obp: 0.414,
                      slg: 0.609,
                      ops: 1.023,
                      h: 6,
                      ktotal: 14,
                      avg: ".261",
                    },
                    {
                      id: "1d678440-b4b1-4954-9b39-70afb3ebbcfa",
                      name: "Blue Jays",
                      market: "Toronto",
                      abbr: "TOR",
                      ab: 68,
                      runs: 15,
                      s: 9,
                      d: 4,
                      t: 0,
                      hr: 7,
                      rbi: 11,
                      bb: 12,
                      ibb: 1,
                      hbp: 1,
                      sb: 0,
                      cs: 0,
                      obp: 0.415,
                      slg: 0.662,
                      ops: 1.077,
                      h: 20,
                      ktotal: 21,
                      avg: ".294",
                    },
                    {
                      id: "27a59d3b-ff7c-48ea-b016-4798f560f5e1",
                      name: "Athletics",
                      market: "Oakland",
                      abbr: "OAK",
                      ab: 30,
                      runs: 8,
                      s: 5,
                      d: 1,
                      t: 0,
                      hr: 5,
                      rbi: 13,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.424,
                      slg: 0.9,
                      ops: 1.324,
                      h: 11,
                      ktotal: 11,
                      avg: ".367",
                    },
                    {
                      id: "bdc11650-6f74-49c4-875e-778aeb7632d9",
                      name: "Rays",
                      market: "Tampa Bay",
                      abbr: "TB",
                      ab: 59,
                      runs: 9,
                      s: 6,
                      d: 4,
                      t: 0,
                      hr: 6,
                      rbi: 14,
                      bb: 12,
                      ibb: 1,
                      hbp: 2,
                      sb: 0,
                      cs: 1,
                      obp: 0.419,
                      slg: 0.644,
                      ops: 1.063,
                      h: 16,
                      ktotal: 26,
                      avg: ".271",
                    },
                    {
                      id: "a09ec676-f887-43dc-bbb3-cf4bbaee9a18",
                      name: "Yankees",
                      market: "New York",
                      abbr: "NYY",
                      ab: 68,
                      runs: 11,
                      s: 8,
                      d: 5,
                      t: 0,
                      hr: 7,
                      rbi: 19,
                      bb: 9,
                      ibb: 2,
                      hbp: 2,
                      sb: 0,
                      cs: 0,
                      obp: 0.407,
                      slg: 0.676,
                      ops: 1.083,
                      h: 20,
                      ktotal: 27,
                      avg: ".294",
                    },
                    {
                      id: "2142e1ba-3b40-445c-b8bb-f1f8b1054220",
                      name: "Phillies",
                      market: "Philadelphia",
                      abbr: "PHI",
                      ab: 14,
                      runs: 3,
                      s: 1,
                      d: 0,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.25,
                      slg: 0.357,
                      ops: 0.607,
                      h: 2,
                      ktotal: 5,
                      avg: ".143",
                    },
                    {
                      id: "4f735188-37c8-473d-ae32-1f7e34ccf892",
                      name: "Angels",
                      market: "Los Angeles",
                      abbr: "LAA",
                      ab: 20,
                      runs: 4,
                      s: 1,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 2,
                      ibb: 0,
                      hbp: 1,
                      sb: 1,
                      cs: 0,
                      obp: 0.261,
                      slg: 0.35,
                      ops: 0.611,
                      h: 3,
                      ktotal: 8,
                      avg: ".150",
                    },
                    {
                      id: "d99f919b-1534-4516-8e8a-9cd106c6d8cd",
                      name: "Rangers",
                      market: "Texas",
                      abbr: "TEX",
                      ab: 26,
                      runs: 3,
                      s: 4,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 5,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.286,
                      slg: 0.462,
                      ops: 0.748,
                      h: 6,
                      ktotal: 11,
                      avg: ".231",
                    },
                    {
                      id: "12079497-e414-450a-8bf2-29f91de646bf",
                      name: "Braves",
                      market: "Atlanta",
                      abbr: "ATL",
                      ab: 10,
                      runs: 2,
                      s: 0,
                      d: 0,
                      t: 0,
                      hr: 2,
                      rbi: 5,
                      bb: 0,
                      ibb: 2,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.333,
                      slg: 0.8,
                      ops: 1.133,
                      h: 2,
                      ktotal: 4,
                      avg: ".200",
                    },
                    {
                      id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                      name: "Twins",
                      market: "Minnesota",
                      abbr: "MIN",
                      ab: 26,
                      runs: 2,
                      s: 5,
                      d: 1,
                      t: 0,
                      hr: 1,
                      rbi: 1,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 1,
                      obp: 0.345,
                      slg: 0.423,
                      ops: 0.768,
                      h: 7,
                      ktotal: 10,
                      avg: ".269",
                    },
                    {
                      id: "d89bed32-3aee-4407-99e3-4103641b999a",
                      name: "Nationals",
                      market: "Washington",
                      abbr: "WSH",
                      ab: 20,
                      runs: 2,
                      s: 0,
                      d: 2,
                      t: 0,
                      hr: 0,
                      rbi: 1,
                      bb: 2,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.182,
                      slg: 0.2,
                      ops: 0.382,
                      h: 2,
                      ktotal: 4,
                      avg: ".100",
                    },
                    {
                      id: "eb21dadd-8f10-4095-8bf3-dfb3b779f107",
                      name: "Astros",
                      market: "Houston",
                      abbr: "HOU",
                      ab: 22,
                      runs: 2,
                      s: 1,
                      d: 1,
                      t: 0,
                      hr: 2,
                      rbi: 6,
                      bb: 1,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.208,
                      slg: 0.5,
                      ops: 0.708,
                      h: 4,
                      ktotal: 6,
                      avg: ".182",
                    },
                    {
                      id: "80715d0d-0d2a-450f-a970-1b9a3b18c7e7",
                      name: "Indians",
                      market: "Cleveland",
                      abbr: "CLE",
                      ab: 21,
                      runs: 2,
                      s: 2,
                      d: 2,
                      t: 0,
                      hr: 1,
                      rbi: 4,
                      bb: 3,
                      ibb: 0,
                      hbp: 0,
                      sb: 0,
                      cs: 0,
                      obp: 0.32,
                      slg: 0.476,
                      ops: 0.796,
                      h: 5,
                      ktotal: 6,
                      avg: ".238",
                    },
                  ],
                },
              ],
            },
          },
        },
        teams: [
          {
            name: "Orioles",
            market: "Baltimore",
            abbr: "BAL",
            id: "75729d34-bca7-4a0f-b3df-6f26c6ad3719",
            statistics: {
              hitting: {
                overall: {
                  ab: 573,
                  lob: 238,
                  rbi: 117,
                  abhr: 12.191,
                  abk: 2.755,
                  bip: 323,
                  babip: 0.319,
                  bbk: 0.404,
                  bbpa: 0.125,
                  iso: 0.3,
                  obp: 0.361,
                  ops: 0.923,
                  seca: 0.445,
                  slg: 0.562,
                  xbh: 78,
                  pitch_count: 2785,
                  ap: 670,
                  avg: ".262",
                  gofo: 0.93,
                  onbase: {
                    s: 72,
                    d: 31,
                    t: 0,
                    hr: 47,
                    tb: 322,
                    bb: 78,
                    ibb: 6,
                    hbp: 8,
                    fc: 8,
                    roe: 4,
                    h: 150,
                    cycle: 0,
                  },
                  runs: { total: 100 },
                  outcome: {
                    klook: 348,
                    kswing: 470,
                    ktotal: 818,
                    ball: 1034,
                    iball: 20,
                    dirtball: 59,
                    foul: 477,
                  },
                  outs: {
                    po: 18,
                    fo: 72,
                    fidp: 0,
                    lo: 27,
                    lidp: 2,
                    go: 109,
                    gidp: 6,
                    klook: 56,
                    kswing: 152,
                    ktotal: 208,
                    sacfly: 5,
                    sachit: 0,
                  },
                  steal: { caught: 3, stolen: 2, pct: 0.4, pickoff: 2 },
                  games: { start: 159, play: 160, finish: 1, complete: 148 },
                },
              },
              fielding: {
                overall: {
                  po: 921,
                  a: 55,
                  dp: 77,
                  tp: 0,
                  error: 3,
                  tc: 979,
                  fpct: 0.997,
                  rf: 6.922,
                  steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                  errors: {
                    throwing: 0,
                    fielding: 0,
                    interference: 0,
                    total: 3,
                  },
                  games: { start: 137, play: 141, finish: 4, complete: 3 },
                },
              },
            },
            splits: {
              hitting: {
                overall: [
                  {
                    total: [
                      {
                        ab: 573,
                        runs: 100,
                        s: 72,
                        d: 31,
                        t: 0,
                        hr: 47,
                        rbi: 117,
                        bb: 78,
                        ibb: 6,
                        hbp: 8,
                        sb: 2,
                        cs: 3,
                        obp: 0.361,
                        slg: 0.562,
                        ops: 0.923,
                        h: 150,
                        ktotal: 208,
                        avg: ".262",
                      },
                    ],
                    day_night: [
                      {
                        value: "night",
                        ab: 382,
                        runs: 64,
                        s: 48,
                        d: 16,
                        t: 0,
                        hr: 31,
                        rbi: 76,
                        bb: 45,
                        ibb: 5,
                        hbp: 7,
                        sb: 2,
                        cs: 3,
                        obp: 0.344,
                        slg: 0.534,
                        ops: 0.878,
                        h: 95,
                        ktotal: 141,
                        avg: ".249",
                      },
                      {
                        value: "day",
                        ab: 191,
                        runs: 36,
                        s: 24,
                        d: 15,
                        t: 0,
                        hr: 16,
                        rbi: 41,
                        bb: 33,
                        ibb: 1,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.395,
                        slg: 0.618,
                        ops: 1.013,
                        h: 55,
                        ktotal: 67,
                        avg: ".288",
                      },
                    ],
                    venue: [
                      {
                        id: "bb062fb1-e268-412e-b3d2-2ddb6acc1822",
                        name: "Nationals Park",
                        market: "Washington",
                        surface: "grass",
                        ab: 12,
                        runs: 2,
                        s: 0,
                        d: 2,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.286,
                        slg: 0.333,
                        ops: 0.619,
                        h: 2,
                        ktotal: 1,
                        avg: ".167",
                      },
                      {
                        id: "706e9828-6687-4ac8-a409-3fb972e8bae9",
                        name: "Yankee Stadium",
                        market: "New York",
                        surface: "grass",
                        ab: 35,
                        runs: 5,
                        s: 3,
                        d: 2,
                        t: 0,
                        hr: 4,
                        rbi: 7,
                        bb: 5,
                        ibb: 1,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.381,
                        slg: 0.657,
                        ops: 1.038,
                        h: 9,
                        ktotal: 13,
                        avg: ".257",
                      },
                      {
                        id: "b4186004-b6d0-42e7-8dc1-82ef0d946ecc",
                        name: "Citizens Bank Park",
                        market: "Philadelphia",
                        surface: "grass",
                        ab: 8,
                        runs: 0,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 0,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.0,
                        slg: 0.0,
                        ops: 0.0,
                        h: 0,
                        ktotal: 3,
                        avg: ".000",
                      },
                      {
                        id: "434aa63c-1049-4a54-a6bc-3790c7772ee1",
                        name: "Oriole Park at Camden Yards",
                        market: "Baltimore",
                        surface: "grass",
                        ab: 274,
                        runs: 61,
                        s: 36,
                        d: 13,
                        t: 0,
                        hr: 29,
                        rbi: 69,
                        bb: 34,
                        ibb: 3,
                        hbp: 5,
                        sb: 1,
                        cs: 3,
                        obp: 0.376,
                        slg: 0.65,
                        ops: 1.026,
                        h: 78,
                        ktotal: 94,
                        avg: ".285",
                      },
                      {
                        id: "302f8dcd-eed6-4b83-8609-81548d51e955",
                        name: "Target Field",
                        market: "Minnesota",
                        surface: "grass",
                        ab: 12,
                        runs: 1,
                        s: 2,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.667,
                        ops: 1.0,
                        h: 4,
                        ktotal: 4,
                        avg: ".333",
                      },
                      {
                        id: "f0600f04-1386-4c61-a412-804960c46cb1",
                        name: "Minute Maid Park",
                        market: "Houston",
                        surface: "grass",
                        ab: 15,
                        runs: 0,
                        s: 1,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 2,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.188,
                        slg: 0.2,
                        ops: 0.388,
                        h: 2,
                        ktotal: 4,
                        avg: ".133",
                      },
                      {
                        id: "ef9a5eef-06c6-4963-ac70-7fd02c8c8d42",
                        name: "Comerica Park",
                        market: "Detroit",
                        surface: "grass",
                        ab: 13,
                        runs: 1,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.357,
                        slg: 0.308,
                        ops: 0.665,
                        h: 4,
                        ktotal: 4,
                        avg: ".308",
                      },
                      {
                        id: "265c7d6c-427a-4b8a-8def-392c41954bec",
                        name: "Citi Field",
                        market: "New York",
                        surface: "grass",
                        ab: 8,
                        runs: 1,
                        s: 2,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.375,
                        slg: 0.75,
                        ops: 1.125,
                        h: 3,
                        ktotal: 5,
                        avg: ".375",
                      },
                      {
                        id: "6fca95c9-7f2c-4acb-a9f3-02ef96340d2a",
                        name: "Kauffman Stadium",
                        market: "Kansas City",
                        surface: "grass",
                        ab: 14,
                        runs: 1,
                        s: 0,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 2,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.2,
                        slg: 0.429,
                        ops: 0.629,
                        h: 2,
                        ktotal: 9,
                        avg: ".143",
                      },
                      {
                        id: "84d72338-2173-4a90-9d25-99adc6c86f4b",
                        name: "Rogers Centre",
                        market: "Toronto",
                        surface: "turf",
                        ab: 32,
                        runs: 6,
                        s: 5,
                        d: 1,
                        t: 0,
                        hr: 3,
                        rbi: 5,
                        bb: 7,
                        ibb: 1,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.425,
                        slg: 0.594,
                        ops: 1.019,
                        h: 9,
                        ktotal: 12,
                        avg: ".281",
                      },
                      {
                        id: "3f47c1c6-b059-4fa2-9d85-5d37b7000992",
                        name: "Globe Life Park in Arlington",
                        market: "Texas",
                        surface: "grass",
                        ab: 11,
                        runs: 0,
                        s: 2,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 0.182,
                        ops: 0.432,
                        h: 2,
                        ktotal: 5,
                        avg: ".182",
                      },
                      {
                        id: "d5a66fb3-ff26-4f36-910c-3df5cedb36b3",
                        name: "Marlins Park",
                        market: "Miami",
                        surface: "grass",
                        ab: 12,
                        runs: 1,
                        s: 0,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.267,
                        slg: 0.167,
                        ops: 0.434,
                        h: 1,
                        ktotal: 5,
                        avg: ".083",
                      },
                      {
                        id: "48cbd35a-d932-4a75-beab-067bfbacfc26",
                        name: "RingCentral Coliseum",
                        market: "Oakland",
                        surface: "grass",
                        ab: 12,
                        runs: 3,
                        s: 1,
                        d: 1,
                        t: 0,
                        hr: 2,
                        rbi: 7,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.429,
                        slg: 0.917,
                        ops: 1.346,
                        h: 4,
                        ktotal: 5,
                        avg: ".333",
                      },
                      {
                        id: "b27b0dc5-c2c5-401d-b8b9-a6a2dbc225e5",
                        name: "Fenway Park",
                        market: "Boston",
                        surface: "grass",
                        ab: 37,
                        runs: 4,
                        s: 5,
                        d: 3,
                        t: 0,
                        hr: 1,
                        rbi: 4,
                        bb: 5,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.405,
                        ops: 0.738,
                        h: 9,
                        ktotal: 14,
                        avg: ".243",
                      },
                      {
                        id: "2b0ccd49-4d87-4996-ac4d-27ffc7ee4c16",
                        name: "Progressive Field",
                        market: "Cleveland",
                        surface: "grass",
                        ab: 9,
                        runs: 1,
                        s: 1,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.385,
                        slg: 0.333,
                        ops: 0.718,
                        h: 2,
                        ktotal: 4,
                        avg: ".222",
                      },
                      {
                        id: "4a550fc1-c03d-4294-b63a-30ff197bedc4",
                        name: "Guaranteed Rate Field",
                        market: "Chicago",
                        surface: "grass",
                        ab: 11,
                        runs: 1,
                        s: 3,
                        d: 0,
                        t: 0,
                        hr: 0,
                        rbi: 2,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.308,
                        slg: 0.273,
                        ops: 0.581,
                        h: 3,
                        ktotal: 2,
                        avg: ".273",
                      },
                      {
                        id: "3aaaf4af-0f8c-49c1-8bf1-1780bb5a5f5c",
                        name: "Tropicana Field",
                        market: "Tampa Bay",
                        surface: "turf",
                        ab: 38,
                        runs: 5,
                        s: 4,
                        d: 3,
                        t: 0,
                        hr: 2,
                        rbi: 8,
                        bb: 8,
                        ibb: 1,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.396,
                        slg: 0.474,
                        ops: 0.87,
                        h: 9,
                        ktotal: 17,
                        avg: ".237",
                      },
                      {
                        id: "60732da9-ad03-4feb-9a36-aee3e98c7a2b",
                        name: "Angel Stadium of Anaheim",
                        market: "Los Angeles",
                        surface: "grass",
                        ab: 10,
                        runs: 4,
                        s: 1,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 2,
                        ibb: 0,
                        hbp: 1,
                        sb: 1,
                        cs: 0,
                        obp: 0.462,
                        slg: 0.7,
                        ops: 1.162,
                        h: 3,
                        ktotal: 4,
                        avg: ".300",
                      },
                      {
                        id: "f1c03dac-3c0f-437c-a325-8d5702cd321a",
                        name: "T-Mobile Park",
                        market: "Seattle",
                        surface: "grass",
                        ab: 10,
                        runs: 3,
                        s: 2,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 3,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.5,
                        slg: 1.0,
                        ops: 1.5,
                        h: 4,
                        ktotal: 3,
                        avg: ".400",
                      },
                    ],
                    surface: [
                      {
                        value: "turf",
                        ab: 70,
                        runs: 11,
                        s: 9,
                        d: 4,
                        t: 0,
                        hr: 5,
                        rbi: 13,
                        bb: 15,
                        ibb: 2,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.409,
                        slg: 0.529,
                        ops: 0.938,
                        h: 18,
                        ktotal: 29,
                        avg: ".257",
                      },
                      {
                        value: "grass",
                        ab: 503,
                        runs: 89,
                        s: 63,
                        d: 27,
                        t: 0,
                        hr: 42,
                        rbi: 104,
                        bb: 63,
                        ibb: 4,
                        hbp: 7,
                        sb: 2,
                        cs: 3,
                        obp: 0.354,
                        slg: 0.567,
                        ops: 0.921,
                        h: 132,
                        ktotal: 179,
                        avg: ".262",
                      },
                    ],
                    month: [
                      {
                        value: "7",
                        ab: 92,
                        runs: 12,
                        s: 15,
                        d: 3,
                        t: 0,
                        hr: 7,
                        rbi: 21,
                        bb: 6,
                        ibb: 2,
                        hbp: 0,
                        sb: 0,
                        cs: 1,
                        obp: 0.327,
                        slg: 0.533,
                        ops: 0.86,
                        h: 25,
                        ktotal: 30,
                        avg: ".272",
                      },
                      {
                        value: "6",
                        ab: 101,
                        runs: 15,
                        s: 16,
                        d: 4,
                        t: 0,
                        hr: 6,
                        rbi: 19,
                        bb: 15,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.35,
                        slg: 0.475,
                        ops: 0.825,
                        h: 26,
                        ktotal: 29,
                        avg: ".257",
                      },
                      {
                        value: "10",
                        ab: 12,
                        runs: 5,
                        s: 1,
                        d: 3,
                        t: 0,
                        hr: 2,
                        rbi: 5,
                        bb: 5,
                        ibb: 1,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.684,
                        slg: 1.25,
                        ops: 1.934,
                        h: 6,
                        ktotal: 2,
                        avg: ".500",
                      },
                      {
                        value: "9",
                        ab: 95,
                        runs: 22,
                        s: 11,
                        d: 7,
                        t: 0,
                        hr: 10,
                        rbi: 20,
                        bb: 15,
                        ibb: 3,
                        hbp: 4,
                        sb: 0,
                        cs: 1,
                        obp: 0.427,
                        slg: 0.684,
                        ops: 1.111,
                        h: 28,
                        ktotal: 31,
                        avg: ".295",
                      },
                      {
                        value: "5",
                        ab: 97,
                        runs: 14,
                        s: 7,
                        d: 5,
                        t: 0,
                        hr: 7,
                        rbi: 14,
                        bb: 15,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.301,
                        slg: 0.464,
                        ops: 0.765,
                        h: 19,
                        ktotal: 40,
                        avg: ".196",
                      },
                      {
                        value: "4",
                        ab: 71,
                        runs: 12,
                        s: 9,
                        d: 5,
                        t: 0,
                        hr: 5,
                        rbi: 16,
                        bb: 6,
                        ibb: 0,
                        hbp: 2,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.549,
                        ops: 0.882,
                        h: 19,
                        ktotal: 29,
                        avg: ".268",
                      },
                      {
                        value: "8",
                        ab: 105,
                        runs: 20,
                        s: 13,
                        d: 4,
                        t: 0,
                        hr: 10,
                        rbi: 22,
                        bb: 16,
                        ibb: 0,
                        hbp: 1,
                        sb: 2,
                        cs: 1,
                        obp: 0.361,
                        slg: 0.581,
                        ops: 0.942,
                        h: 27,
                        ktotal: 47,
                        avg: ".257",
                      },
                    ],
                    home_away: [
                      {
                        value: "home",
                        ab: 283,
                        runs: 62,
                        s: 38,
                        d: 13,
                        t: 0,
                        hr: 29,
                        rbi: 70,
                        bb: 36,
                        ibb: 3,
                        hbp: 5,
                        sb: 1,
                        cs: 3,
                        obp: 0.376,
                        slg: 0.636,
                        ops: 1.012,
                        h: 80,
                        ktotal: 100,
                        avg: ".283",
                      },
                      {
                        value: "away",
                        ab: 290,
                        runs: 38,
                        s: 34,
                        d: 18,
                        t: 0,
                        hr: 18,
                        rbi: 47,
                        bb: 42,
                        ibb: 3,
                        hbp: 3,
                        sb: 1,
                        cs: 0,
                        obp: 0.347,
                        slg: 0.49,
                        ops: 0.837,
                        h: 70,
                        ktotal: 108,
                        avg: ".241",
                      },
                    ],
                    pitcher_hand: [
                      {
                        value: "L",
                        ab: 189,
                        runs: 24,
                        s: 33,
                        d: 8,
                        t: 0,
                        hr: 9,
                        rbi: 30,
                        bb: 16,
                        ibb: 0,
                        hbp: 2,
                        sb: 1,
                        cs: 0,
                        obp: 0.327,
                        slg: 0.45,
                        ops: 0.777,
                        h: 50,
                        ktotal: 58,
                        avg: ".265",
                      },
                      {
                        value: "R",
                        ab: 381,
                        runs: 75,
                        s: 39,
                        d: 23,
                        t: 0,
                        hr: 37,
                        rbi: 86,
                        bb: 62,
                        ibb: 6,
                        hbp: 6,
                        sb: 1,
                        cs: 3,
                        obp: 0.377,
                        slg: 0.612,
                        ops: 0.989,
                        h: 99,
                        ktotal: 148,
                        avg: ".260",
                      },
                      {
                        value: "B",
                        ab: 1,
                        runs: 1,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 0,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 1.0,
                        slg: 4.0,
                        ops: 5.0,
                        h: 1,
                        ktotal: 0,
                        avg: "1.000",
                      },
                    ],
                    opponent: [
                      {
                        id: "43a39081-52b4-4f93-ad29-da7f329ea960",
                        name: "Mariners",
                        market: "Seattle",
                        abbr: "SEA",
                        ab: 20,
                        runs: 5,
                        s: 3,
                        d: 1,
                        t: 0,
                        hr: 2,
                        rbi: 3,
                        bb: 5,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.44,
                        slg: 0.65,
                        ops: 1.09,
                        h: 6,
                        ktotal: 6,
                        avg: ".300",
                      },
                      {
                        id: "f246a5e5-afdb-479c-9aaa-c68beeda7af6",
                        name: "Mets",
                        market: "New York",
                        abbr: "NYM",
                        ab: 14,
                        runs: 3,
                        s: 2,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 1,
                        cs: 0,
                        obp: 0.375,
                        slg: 0.571,
                        ops: 0.946,
                        h: 4,
                        ktotal: 10,
                        avg: ".286",
                      },
                      {
                        id: "47f490cd-2f58-4ef7-9dfd-2ad6ba6c1ae8",
                        name: "White Sox",
                        market: "Chicago",
                        abbr: "CWS",
                        ab: 21,
                        runs: 4,
                        s: 3,
                        d: 1,
                        t: 0,
                        hr: 2,
                        rbi: 8,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.36,
                        slg: 0.619,
                        ops: 0.979,
                        h: 6,
                        ktotal: 4,
                        avg: ".286",
                      },
                      {
                        id: "03556285-bdbb-4576-a06d-42f71f46ddc5",
                        name: "Marlins",
                        market: "Miami",
                        abbr: "MIA",
                        ab: 12,
                        runs: 1,
                        s: 0,
                        d: 1,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.267,
                        slg: 0.167,
                        ops: 0.434,
                        h: 1,
                        ktotal: 5,
                        avg: ".083",
                      },
                      {
                        id: "575c19b7-4052-41c2-9f0a-1c5813d02f99",
                        name: "Tigers",
                        market: "Detroit",
                        abbr: "DET",
                        ab: 27,
                        runs: 4,
                        s: 7,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 8,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 1,
                        obp: 0.4,
                        slg: 0.556,
                        ops: 0.956,
                        h: 9,
                        ktotal: 9,
                        avg: ".333",
                      },
                      {
                        id: "93941372-eb4c-4c40-aced-fe3267174393",
                        name: "Red Sox",
                        market: "Boston",
                        abbr: "BOS",
                        ab: 72,
                        runs: 13,
                        s: 13,
                        d: 4,
                        t: 0,
                        hr: 3,
                        rbi: 11,
                        bb: 7,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.458,
                        ops: 0.791,
                        h: 20,
                        ktotal: 21,
                        avg: ".278",
                      },
                      {
                        id: "833a51a9-0d84-410f-bd77-da08c3e5e26e",
                        name: "Royals",
                        market: "Kansas City",
                        abbr: "KC",
                        ab: 23,
                        runs: 7,
                        s: 2,
                        d: 2,
                        t: 0,
                        hr: 2,
                        rbi: 4,
                        bb: 4,
                        ibb: 0,
                        hbp: 2,
                        sb: 0,
                        cs: 0,
                        obp: 0.414,
                        slg: 0.609,
                        ops: 1.023,
                        h: 6,
                        ktotal: 14,
                        avg: ".261",
                      },
                      {
                        id: "1d678440-b4b1-4954-9b39-70afb3ebbcfa",
                        name: "Blue Jays",
                        market: "Toronto",
                        abbr: "TOR",
                        ab: 68,
                        runs: 15,
                        s: 9,
                        d: 4,
                        t: 0,
                        hr: 7,
                        rbi: 11,
                        bb: 12,
                        ibb: 1,
                        hbp: 1,
                        sb: 0,
                        cs: 0,
                        obp: 0.415,
                        slg: 0.662,
                        ops: 1.077,
                        h: 20,
                        ktotal: 21,
                        avg: ".294",
                      },
                      {
                        id: "27a59d3b-ff7c-48ea-b016-4798f560f5e1",
                        name: "Athletics",
                        market: "Oakland",
                        abbr: "OAK",
                        ab: 30,
                        runs: 8,
                        s: 5,
                        d: 1,
                        t: 0,
                        hr: 5,
                        rbi: 13,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.424,
                        slg: 0.9,
                        ops: 1.324,
                        h: 11,
                        ktotal: 11,
                        avg: ".367",
                      },
                      {
                        id: "bdc11650-6f74-49c4-875e-778aeb7632d9",
                        name: "Rays",
                        market: "Tampa Bay",
                        abbr: "TB",
                        ab: 59,
                        runs: 9,
                        s: 6,
                        d: 4,
                        t: 0,
                        hr: 6,
                        rbi: 14,
                        bb: 12,
                        ibb: 1,
                        hbp: 2,
                        sb: 0,
                        cs: 1,
                        obp: 0.419,
                        slg: 0.644,
                        ops: 1.063,
                        h: 16,
                        ktotal: 26,
                        avg: ".271",
                      },
                      {
                        id: "a09ec676-f887-43dc-bbb3-cf4bbaee9a18",
                        name: "Yankees",
                        market: "New York",
                        abbr: "NYY",
                        ab: 68,
                        runs: 11,
                        s: 8,
                        d: 5,
                        t: 0,
                        hr: 7,
                        rbi: 19,
                        bb: 9,
                        ibb: 2,
                        hbp: 2,
                        sb: 0,
                        cs: 0,
                        obp: 0.407,
                        slg: 0.676,
                        ops: 1.083,
                        h: 20,
                        ktotal: 27,
                        avg: ".294",
                      },
                      {
                        id: "2142e1ba-3b40-445c-b8bb-f1f8b1054220",
                        name: "Phillies",
                        market: "Philadelphia",
                        abbr: "PHI",
                        ab: 14,
                        runs: 3,
                        s: 1,
                        d: 0,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.25,
                        slg: 0.357,
                        ops: 0.607,
                        h: 2,
                        ktotal: 5,
                        avg: ".143",
                      },
                      {
                        id: "4f735188-37c8-473d-ae32-1f7e34ccf892",
                        name: "Angels",
                        market: "Los Angeles",
                        abbr: "LAA",
                        ab: 20,
                        runs: 4,
                        s: 1,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 2,
                        ibb: 0,
                        hbp: 1,
                        sb: 1,
                        cs: 0,
                        obp: 0.261,
                        slg: 0.35,
                        ops: 0.611,
                        h: 3,
                        ktotal: 8,
                        avg: ".150",
                      },
                      {
                        id: "d99f919b-1534-4516-8e8a-9cd106c6d8cd",
                        name: "Rangers",
                        market: "Texas",
                        abbr: "TEX",
                        ab: 26,
                        runs: 3,
                        s: 4,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 5,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.286,
                        slg: 0.462,
                        ops: 0.748,
                        h: 6,
                        ktotal: 11,
                        avg: ".231",
                      },
                      {
                        id: "12079497-e414-450a-8bf2-29f91de646bf",
                        name: "Braves",
                        market: "Atlanta",
                        abbr: "ATL",
                        ab: 10,
                        runs: 2,
                        s: 0,
                        d: 0,
                        t: 0,
                        hr: 2,
                        rbi: 5,
                        bb: 0,
                        ibb: 2,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.333,
                        slg: 0.8,
                        ops: 1.133,
                        h: 2,
                        ktotal: 4,
                        avg: ".200",
                      },
                      {
                        id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                        name: "Twins",
                        market: "Minnesota",
                        abbr: "MIN",
                        ab: 26,
                        runs: 2,
                        s: 5,
                        d: 1,
                        t: 0,
                        hr: 1,
                        rbi: 1,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 1,
                        obp: 0.345,
                        slg: 0.423,
                        ops: 0.768,
                        h: 7,
                        ktotal: 10,
                        avg: ".269",
                      },
                      {
                        id: "d89bed32-3aee-4407-99e3-4103641b999a",
                        name: "Nationals",
                        market: "Washington",
                        abbr: "WSH",
                        ab: 20,
                        runs: 2,
                        s: 0,
                        d: 2,
                        t: 0,
                        hr: 0,
                        rbi: 1,
                        bb: 2,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.182,
                        slg: 0.2,
                        ops: 0.382,
                        h: 2,
                        ktotal: 4,
                        avg: ".100",
                      },
                      {
                        id: "eb21dadd-8f10-4095-8bf3-dfb3b779f107",
                        name: "Astros",
                        market: "Houston",
                        abbr: "HOU",
                        ab: 22,
                        runs: 2,
                        s: 1,
                        d: 1,
                        t: 0,
                        hr: 2,
                        rbi: 6,
                        bb: 1,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.208,
                        slg: 0.5,
                        ops: 0.708,
                        h: 4,
                        ktotal: 6,
                        avg: ".182",
                      },
                      {
                        id: "80715d0d-0d2a-450f-a970-1b9a3b18c7e7",
                        name: "Indians",
                        market: "Cleveland",
                        abbr: "CLE",
                        ab: 21,
                        runs: 2,
                        s: 2,
                        d: 2,
                        t: 0,
                        hr: 1,
                        rbi: 4,
                        bb: 3,
                        ibb: 0,
                        hbp: 0,
                        sb: 0,
                        cs: 0,
                        obp: 0.32,
                        slg: 0.476,
                        ops: 0.796,
                        h: 5,
                        ktotal: 6,
                        avg: ".238",
                      },
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
      {
        id: "8f4e3a30-8444-11e3-808c-22000a904a71",
        year: 2014,
        type: "REG",
        totals: {
          statistics: {
            hitting: {
              overall: {
                ab: 450,
                lob: 205,
                rbi: 72,
                abhr: 17.308,
                abk: 2.601,
                bip: 256,
                babip: 0.242,
                bbk: 0.347,
                bbpa: 0.114,
                iso: 0.208,
                obp: 0.3,
                ops: 0.704,
                seca: 0.344,
                slg: 0.404,
                xbh: 42,
                pitch_count: 2190,
                ap: 525,
                avg: ".196",
                gofo: 0.88,
                onbase: {
                  s: 46,
                  d: 16,
                  t: 0,
                  hr: 26,
                  tb: 182,
                  bb: 51,
                  ibb: 9,
                  hbp: 9,
                  fc: 6,
                  roe: 4,
                  h: 88,
                  cycle: 0,
                },
                runs: { total: 65 },
                outcome: {
                  klook: 255,
                  kswing: 249,
                  ktotal: 504,
                  ball: 729,
                  iball: 21,
                  dirtball: 48,
                  foul: 0,
                },
                outs: {
                  po: 17,
                  fo: 59,
                  fidp: 0,
                  lo: 24,
                  lidp: 0,
                  go: 92,
                  gidp: 2,
                  klook: 56,
                  kswing: 117,
                  ktotal: 173,
                  sacfly: 5,
                  sachit: 1,
                },
                steal: { caught: 1, stolen: 2, pct: 0.667, pickoff: 0 },
                games: { start: 123, play: 127, finish: 4, complete: 114 },
              },
            },
            fielding: {
              overall: {
                po: 918,
                a: 74,
                dp: 100,
                tp: 0,
                error: 8,
                tc: 1000,
                fpct: 0.992,
                rf: 7.294,
                steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                errors: { throwing: 0, fielding: 0, interference: 0, total: 8 },
                games: { start: 123, play: 136, finish: 13, complete: 11 },
              },
            },
          },
        },
        teams: [
          {
            name: "Orioles",
            market: "Baltimore",
            abbr: "BAL",
            id: "75729d34-bca7-4a0f-b3df-6f26c6ad3719",
            statistics: {
              hitting: {
                overall: {
                  ab: 450,
                  lob: 205,
                  rbi: 72,
                  abhr: 17.308,
                  abk: 2.601,
                  bip: 256,
                  babip: 0.242,
                  bbk: 0.347,
                  bbpa: 0.114,
                  iso: 0.208,
                  obp: 0.3,
                  ops: 0.704,
                  seca: 0.344,
                  slg: 0.404,
                  xbh: 42,
                  pitch_count: 2190,
                  ap: 525,
                  avg: ".196",
                  gofo: 0.88,
                  onbase: {
                    s: 46,
                    d: 16,
                    t: 0,
                    hr: 26,
                    tb: 182,
                    bb: 51,
                    ibb: 9,
                    hbp: 9,
                    fc: 6,
                    roe: 4,
                    h: 88,
                    cycle: 0,
                  },
                  runs: { total: 65 },
                  outcome: {
                    klook: 255,
                    kswing: 249,
                    ktotal: 504,
                    ball: 729,
                    iball: 21,
                    dirtball: 48,
                    foul: 0,
                  },
                  outs: {
                    po: 17,
                    fo: 59,
                    fidp: 0,
                    lo: 24,
                    lidp: 0,
                    go: 92,
                    gidp: 2,
                    klook: 56,
                    kswing: 117,
                    ktotal: 173,
                    sacfly: 5,
                    sachit: 1,
                  },
                  steal: { caught: 1, stolen: 2, pct: 0.667, pickoff: 0 },
                  games: { start: 123, play: 127, finish: 4, complete: 114 },
                },
              },
              fielding: {
                overall: {
                  po: 918,
                  a: 74,
                  dp: 100,
                  tp: 0,
                  error: 8,
                  tc: 1000,
                  fpct: 0.992,
                  rf: 7.294,
                  steal: { caught: 0, stolen: 0, pickoff: 0, pct: 0 },
                  errors: {
                    throwing: 0,
                    fielding: 0,
                    interference: 0,
                    total: 8,
                  },
                  games: { start: 123, play: 136, finish: 13, complete: 11 },
                },
              },
            },
          },
        ],
      },
      {
        id: "58dd9a81-4a38-4a66-8cd7-32ae4d567ba8",
        year: 2013,
        type: "REG",
        totals: {
          statistics: {
            hitting: {
              overall: {
                ab: 584,
                lob: 222,
                rbi: 138,
                abhr: 11.019,
                abk: 2.935,
                bip: 339,
                babip: 0.336,
                bbk: 0.362,
                bbpa: 0.107,
                iso: 0.348,
                obp: 0.37,
                ops: 1.004,
                seca: 0.476,
                slg: 0.634,
                xbh: 96,
                pitch_count: 2678,
                ap: 673,
                avg: ".286",
                gofo: 0.89,
                onbase: {
                  s: 71,
                  d: 42,
                  t: 1,
                  hr: 53,
                  tb: 370,
                  bb: 66,
                  ibb: 6,
                  hbp: 10,
                  fc: 4,
                  roe: 5,
                  h: 167,
                  cycle: 0,
                },
                runs: { total: 103 },
                outcome: {
                  klook: 253,
                  kswing: 283,
                  ktotal: 536,
                  ball: 915,
                  iball: 16,
                  dirtball: 40,
                  foul: 0,
                },
                outs: {
                  po: 21,
                  fo: 73,
                  fidp: 0,
                  lo: 15,
                  lidp: 1,
                  go: 103,
                  gidp: 4,
                  klook: 48,
                  kswing: 151,
                  ktotal: 199,
                  sacfly: 7,
                  sachit: 0,
                },
                steal: { caught: 1, stolen: 4, pct: 0.8, pickoff: 0 },
                games: { start: 160, play: 160, finish: 154, complete: 154 },
              },
            },
          },
        },
        teams: [
          {
            name: "Orioles",
            market: "Baltimore",
            abbr: "BAL",
            id: "75729d34-bca7-4a0f-b3df-6f26c6ad3719",
            statistics: {
              hitting: {
                overall: {
                  ab: 584,
                  lob: 222,
                  rbi: 138,
                  abhr: 11.019,
                  abk: 2.935,
                  bip: 339,
                  babip: 0.336,
                  bbk: 0.362,
                  bbpa: 0.107,
                  iso: 0.348,
                  obp: 0.37,
                  ops: 1.004,
                  seca: 0.476,
                  slg: 0.634,
                  xbh: 96,
                  pitch_count: 2678,
                  ap: 673,
                  avg: ".286",
                  gofo: 0.89,
                  onbase: {
                    s: 71,
                    d: 42,
                    t: 1,
                    hr: 53,
                    tb: 370,
                    bb: 66,
                    ibb: 6,
                    hbp: 10,
                    fc: 4,
                    roe: 5,
                    h: 167,
                    cycle: 0,
                  },
                  runs: { total: 103 },
                  outcome: {
                    klook: 253,
                    kswing: 283,
                    ktotal: 536,
                    ball: 915,
                    iball: 16,
                    dirtball: 40,
                    foul: 0,
                  },
                  outs: {
                    po: 21,
                    fo: 73,
                    fidp: 0,
                    lo: 15,
                    lidp: 1,
                    go: 103,
                    gidp: 4,
                    klook: 48,
                    kswing: 151,
                    ktotal: 199,
                    sacfly: 7,
                    sachit: 0,
                  },
                  steal: { caught: 1, stolen: 4, pct: 0.8, pickoff: 0 },
                  games: { start: 160, play: 160, finish: 154, complete: 154 },
                },
              },
            },
          },
        ],
      },
    ],
  },
  _comment:
    "Generation started @ 2020-07-26 17:51:40 UTC ended @ 2020-07-26 17:51:41 UTC",
}
