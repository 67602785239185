export const standings = {
  league: {
    alias: "MLB",
    name: "Major League Baseball",
    id: "2fa448bc-fc17-4d3d-be03-e60e080fdc26",
    season: {
      id: "d790e0e3-a022-4da3-9507-e127cae8dea2",
      year: 2020,
      type: "REG",
      leagues: [
        {
          alias: "NL",
          name: "National League",
          id: "fbe91704-36df-4e7c-864a-06d236425999",
          divisions: [
            {
              alias: "W",
              name: "West",
              id: "dd76b332-7cfa-45f0-bed0-a6944d924a61",
              teams: [
                {
                  name: "Padres",
                  market: "San Diego",
                  abbr: "SD",
                  id: "d52d5339-cbdd-43f3-9dfa-a42fd588b9a3",
                  away_loss: 0,
                  away_win: 0,
                  elimination_number: 0,
                  games_back: 0,
                  home_loss: 0,
                  home_win: 2,
                  last_10_lost: 0,
                  last_10_won: 2,
                  loss: 0,
                  streak: "W2",
                  wild_card_back: "0.0",
                  win: 2,
                  win_p: 1.0,
                  al_loss: 0,
                  al_win: 0,
                  c_loss: 0,
                  c_win: 0,
                  w_loss: 0,
                  w_win: 2,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 0,
                  rank: {
                    division: 1,
                    league: 1,
                  },
                },
                {
                  name: "Dodgers",
                  market: "Los Angeles",
                  abbr: "LAD",
                  id: "ef64da7f-cfaf-4300-87b0-9313386b977c",
                  away_loss: 0,
                  away_win: 0,
                  elimination_number: 0,
                  games_back: 0.5,
                  home_loss: 1,
                  home_win: 2,
                  last_10_lost: 1,
                  last_10_won: 2,
                  loss: 1,
                  streak: "L1",
                  wild_card_back: "+0.5",
                  win: 2,
                  win_p: 0.667,
                  al_loss: 0,
                  al_win: 0,
                  c_loss: 0,
                  c_win: 0,
                  w_loss: 1,
                  w_win: 2,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 57,
                  rank: {
                    division: 2,
                    league: 2,
                  },
                },
                {
                  name: "Rockies",
                  market: "Colorado",
                  abbr: "COL",
                  id: "29dd9a87-5bcc-4774-80c3-7f50d985068b",
                  away_loss: 1,
                  away_win: 1,
                  elimination_number: 0,
                  games_back: 1.0,
                  home_loss: 0,
                  home_win: 0,
                  last_10_lost: 1,
                  last_10_won: 1,
                  loss: 1,
                  streak: "W1",
                  wild_card_back: "0.0",
                  win: 1,
                  win_p: 0.5,
                  al_loss: 1,
                  al_win: 1,
                  c_loss: 0,
                  c_win: 0,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 57,
                  rank: {
                    division: 3,
                    league: 7,
                  },
                },
                {
                  name: "Giants",
                  market: "San Francisco",
                  abbr: "SF",
                  id: "a7723160-10b7-4277-a309-d8dd95a8ae65",
                  away_loss: 2,
                  away_win: 1,
                  elimination_number: 57,
                  games_back: 1.5,
                  home_loss: 0,
                  home_win: 0,
                  last_10_lost: 2,
                  last_10_won: 1,
                  loss: 2,
                  streak: "W1",
                  wild_card_back: "0.5",
                  win: 1,
                  win_p: 0.333,
                  al_loss: 0,
                  al_win: 0,
                  c_loss: 0,
                  c_win: 0,
                  w_loss: 2,
                  w_win: 1,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 56,
                  rank: {
                    division: 4,
                    league: 13,
                  },
                },
                {
                  name: "Diamondbacks",
                  market: "Arizona",
                  abbr: "ARI",
                  id: "25507be1-6a68-4267-bd82-e097d94b359b",
                  away_loss: 2,
                  away_win: 0,
                  elimination_number: 57,
                  games_back: 2.0,
                  home_loss: 0,
                  home_win: 0,
                  last_10_lost: 2,
                  last_10_won: 0,
                  loss: 2,
                  streak: "L2",
                  wild_card_back: "1.0",
                  win: 0,
                  win_p: 0.0,
                  al_loss: 0,
                  al_win: 0,
                  c_loss: 0,
                  c_win: 0,
                  w_loss: 2,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 56,
                  rank: {
                    division: 5,
                    league: 15,
                  },
                },
              ],
            },
            {
              alias: "C",
              name: "Central",
              id: "95e2d773-d899-4751-b61a-b9463e5aa874",
              teams: [
                {
                  name: "Cardinals",
                  market: "St. Louis",
                  abbr: "STL",
                  id: "44671792-dc02-4fdd-a5ad-f5f17edaa9d7",
                  away_loss: 0,
                  away_win: 0,
                  elimination_number: 0,
                  games_back: 0,
                  home_loss: 1,
                  home_win: 2,
                  last_10_lost: 1,
                  last_10_won: 2,
                  loss: 1,
                  streak: "L1",
                  wild_card_back: "0.0",
                  win: 2,
                  win_p: 0.667,
                  al_loss: 0,
                  al_win: 0,
                  c_loss: 1,
                  c_win: 2,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 0,
                  rank: {
                    division: 1,
                    league: 4,
                  },
                },
                {
                  name: "Cubs",
                  market: "Chicago",
                  abbr: "CHC",
                  id: "55714da8-fcaf-4574-8443-59bfb511a524",
                  away_loss: 0,
                  away_win: 0,
                  elimination_number: 0,
                  games_back: 0.5,
                  home_loss: 1,
                  home_win: 1,
                  last_10_lost: 1,
                  last_10_won: 1,
                  loss: 1,
                  streak: "L1",
                  wild_card_back: "0.0",
                  win: 1,
                  win_p: 0.5,
                  al_loss: 0,
                  al_win: 0,
                  c_loss: 1,
                  c_win: 1,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 57,
                  rank: {
                    division: 2,
                    league: 6,
                  },
                },
                {
                  name: "Brewers",
                  market: "Milwaukee",
                  abbr: "MIL",
                  id: "dcfd5266-00ce-442c-bc09-264cd20cf455",
                  away_loss: 1,
                  away_win: 1,
                  elimination_number: 0,
                  games_back: 0.5,
                  home_loss: 0,
                  home_win: 0,
                  last_10_lost: 1,
                  last_10_won: 1,
                  loss: 1,
                  streak: "W1",
                  wild_card_back: "0.0",
                  win: 1,
                  win_p: 0.5,
                  al_loss: 0,
                  al_win: 0,
                  c_loss: 1,
                  c_win: 1,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 57,
                  rank: {
                    division: 3,
                    league: 8,
                  },
                },
                {
                  name: "Reds",
                  market: "Cincinnati",
                  abbr: "CIN",
                  id: "c874a065-c115-4e7d-b0f0-235584fb0e6f",
                  away_loss: 0,
                  away_win: 0,
                  elimination_number: 57,
                  games_back: 1.0,
                  home_loss: 2,
                  home_win: 1,
                  last_10_lost: 2,
                  last_10_won: 1,
                  loss: 2,
                  streak: "L2",
                  wild_card_back: "0.5",
                  win: 1,
                  win_p: 0.333,
                  al_loss: 2,
                  al_win: 1,
                  c_loss: 0,
                  c_win: 0,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 56,
                  rank: {
                    division: 4,
                    league: 10,
                  },
                },
                {
                  name: "Pirates",
                  market: "Pittsburgh",
                  abbr: "PIT",
                  id: "481dfe7e-5dab-46ab-a49f-9dcc2b6e2cfd",
                  away_loss: 2,
                  away_win: 1,
                  elimination_number: 57,
                  games_back: 1.0,
                  home_loss: 0,
                  home_win: 0,
                  last_10_lost: 2,
                  last_10_won: 1,
                  loss: 2,
                  streak: "W1",
                  wild_card_back: "0.5",
                  win: 1,
                  win_p: 0.333,
                  al_loss: 0,
                  al_win: 0,
                  c_loss: 2,
                  c_win: 1,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 56,
                  rank: {
                    division: 5,
                    league: 12,
                  },
                },
              ],
            },
            {
              alias: "E",
              name: "East",
              id: "e354a39b-2632-4b35-9581-7c8edd758a75",
              teams: [
                {
                  name: "Marlins",
                  market: "Miami",
                  abbr: "MIA",
                  id: "03556285-bdbb-4576-a06d-42f71f46ddc5",
                  away_loss: 1,
                  away_win: 2,
                  elimination_number: 0,
                  games_back: 0,
                  home_loss: 0,
                  home_win: 0,
                  last_10_lost: 1,
                  last_10_won: 2,
                  loss: 1,
                  streak: "W1",
                  wild_card_back: "0.0",
                  win: 2,
                  win_p: 0.667,
                  al_loss: 0,
                  al_win: 0,
                  c_loss: 0,
                  c_win: 0,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 1,
                  e_win: 2,
                  division_elimination_number: 0,
                  rank: {
                    division: 1,
                    league: 3,
                  },
                },
                {
                  name: "Braves",
                  market: "Atlanta",
                  abbr: "ATL",
                  id: "12079497-e414-450a-8bf2-29f91de646bf",
                  away_loss: 1,
                  away_win: 1,
                  elimination_number: 0,
                  games_back: 0.5,
                  home_loss: 0,
                  home_win: 0,
                  last_10_lost: 1,
                  last_10_won: 1,
                  loss: 1,
                  streak: "W1",
                  wild_card_back: "0.0",
                  win: 1,
                  win_p: 0.5,
                  al_loss: 0,
                  al_win: 0,
                  c_loss: 0,
                  c_win: 0,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 1,
                  e_win: 1,
                  division_elimination_number: 57,
                  rank: {
                    division: 2,
                    league: 5,
                  },
                },
                {
                  name: "Mets",
                  market: "New York",
                  abbr: "NYM",
                  id: "f246a5e5-afdb-479c-9aaa-c68beeda7af6",
                  away_loss: 0,
                  away_win: 0,
                  elimination_number: 0,
                  games_back: 0.5,
                  home_loss: 1,
                  home_win: 1,
                  last_10_lost: 1,
                  last_10_won: 1,
                  loss: 1,
                  streak: "L1",
                  wild_card_back: "0.0",
                  win: 1,
                  win_p: 0.5,
                  al_loss: 0,
                  al_win: 0,
                  c_loss: 0,
                  c_win: 0,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 1,
                  e_win: 1,
                  division_elimination_number: 57,
                  rank: {
                    division: 3,
                    league: 9,
                  },
                },
                {
                  name: "Phillies",
                  market: "Philadelphia",
                  abbr: "PHI",
                  id: "2142e1ba-3b40-445c-b8bb-f1f8b1054220",
                  away_loss: 0,
                  away_win: 0,
                  elimination_number: 57,
                  games_back: 1.0,
                  home_loss: 2,
                  home_win: 1,
                  last_10_lost: 2,
                  last_10_won: 1,
                  loss: 2,
                  streak: "L1",
                  wild_card_back: "0.5",
                  win: 1,
                  win_p: 0.333,
                  al_loss: 0,
                  al_win: 0,
                  c_loss: 0,
                  c_win: 0,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 2,
                  e_win: 1,
                  division_elimination_number: 56,
                  rank: {
                    division: 4,
                    league: 11,
                  },
                },
                {
                  name: "Nationals",
                  market: "Washington",
                  abbr: "WSH",
                  id: "d89bed32-3aee-4407-99e3-4103641b999a",
                  away_loss: 0,
                  away_win: 0,
                  elimination_number: 57,
                  games_back: 1.0,
                  home_loss: 2,
                  home_win: 1,
                  last_10_lost: 2,
                  last_10_won: 1,
                  loss: 2,
                  streak: "L1",
                  wild_card_back: "0.5",
                  win: 1,
                  win_p: 0.333,
                  al_loss: 2,
                  al_win: 1,
                  c_loss: 0,
                  c_win: 0,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 56,
                  rank: {
                    division: 5,
                    league: 14,
                  },
                },
              ],
            },
          ],
        },
        {
          alias: "AL",
          name: "American League",
          id: "2ea6efe7-2e21-4f29-80a2-0a24ad1f5f85",
          divisions: [
            {
              alias: "E",
              name: "East",
              id: "1d74e8e9-7faf-4cdb-b613-3944fa5aa739",
              teams: [
                {
                  name: "Orioles",
                  market: "Baltimore",
                  abbr: "BAL",
                  id: "75729d34-bca7-4a0f-b3df-6f26c6ad3719",
                  away_loss: 1,
                  away_win: 2,
                  elimination_number: 0,
                  games_back: 0,
                  home_loss: 0,
                  home_win: 0,
                  last_10_lost: 1,
                  last_10_won: 2,
                  loss: 1,
                  streak: "W2",
                  wild_card_back: "0.0",
                  win: 24,
                  win_p: 0.667,
                  c_loss: 0,
                  c_win: 0,
                  nl_loss: 0,
                  nl_win: 0,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 1,
                  e_win: 2,
                  division_elimination_number: 0,
                  rank: {
                    division: 1,
                    league: 2,
                  },
                },
                {
                  name: "Yankees",
                  market: "New York",
                  abbr: "NYY",
                  id: "a09ec676-f887-43dc-bbb3-cf4bbaee9a18",
                  away_loss: 1,
                  away_win: 2,
                  elimination_number: 0,
                  games_back: 0,
                  home_loss: 0,
                  home_win: 0,
                  last_10_lost: 1,
                  last_10_won: 2,
                  loss: 1,
                  streak: "W1",
                  wild_card_back: "0.0",
                  win: 2,
                  win_p: 0.667,
                  c_loss: 0,
                  c_win: 0,
                  nl_loss: 1,
                  nl_win: 2,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 0,
                  rank: {
                    division: 2,
                    league: 5,
                  },
                },
                {
                  name: "Rays",
                  market: "Tampa Bay",
                  abbr: "TB",
                  id: "bdc11650-6f74-49c4-875e-778aeb7632d9",
                  away_loss: 0,
                  away_win: 0,
                  elimination_number: 0,
                  games_back: 0,
                  home_loss: 1,
                  home_win: 2,
                  last_10_lost: 1,
                  last_10_won: 2,
                  loss: 1,
                  streak: "W2",
                  wild_card_back: "0.0",
                  win: 2,
                  win_p: 0.667,
                  c_loss: 0,
                  c_win: 0,
                  nl_loss: 0,
                  nl_win: 0,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 1,
                  e_win: 2,
                  division_elimination_number: 0,
                  rank: {
                    division: 3,
                    league: 6,
                  },
                },
                {
                  name: "Red Sox",
                  market: "Boston",
                  abbr: "BOS",
                  id: "93941372-eb4c-4c40-aced-fe3267174393",
                  away_loss: 0,
                  away_win: 0,
                  elimination_number: 56,
                  games_back: 1.0,
                  home_loss: 2,
                  home_win: 1,
                  last_10_lost: 2,
                  last_10_won: 1,
                  loss: 2,
                  streak: "L2",
                  wild_card_back: "1.0",
                  win: 1,
                  win_p: 0.333,
                  c_loss: 0,
                  c_win: 0,
                  nl_loss: 0,
                  nl_win: 0,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 2,
                  e_win: 1,
                  division_elimination_number: 56,
                  rank: {
                    division: 4,
                    league: 12,
                  },
                },
                {
                  name: "Blue Jays",
                  market: "Toronto",
                  abbr: "TOR",
                  id: "1d678440-b4b1-4954-9b39-70afb3ebbcfa",
                  away_loss: 2,
                  away_win: 1,
                  elimination_number: 56,
                  games_back: 1.0,
                  home_loss: 0,
                  home_win: 0,
                  last_10_lost: 2,
                  last_10_won: 1,
                  loss: 2,
                  streak: "L2",
                  wild_card_back: "1.0",
                  win: 1,
                  win_p: 0.333,
                  c_loss: 0,
                  c_win: 0,
                  nl_loss: 0,
                  nl_win: 0,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 2,
                  e_win: 1,
                  division_elimination_number: 56,
                  rank: {
                    division: 5,
                    league: 14,
                  },
                },
              ],
            },
            {
              alias: "C",
              name: "Central",
              id: "255fadc6-367e-4238-8ece-d0cddabf7d72",
              teams: [
                {
                  name: "Indians",
                  market: "Cleveland",
                  abbr: "CLE",
                  id: "80715d0d-0d2a-450f-a970-1b9a3b18c7e7",
                  away_loss: 0,
                  away_win: 0,
                  elimination_number: 0,
                  games_back: 0,
                  home_loss: 1,
                  home_win: 2,
                  last_10_lost: 1,
                  last_10_won: 2,
                  loss: 1,
                  streak: "W1",
                  wild_card_back: "0.0",
                  win: 2,
                  win_p: 0.667,
                  c_loss: 1,
                  c_win: 2,
                  nl_loss: 0,
                  nl_win: 0,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 0,
                  rank: {
                    division: 1,
                    league: 3,
                  },
                },
                {
                  name: "Tigers",
                  market: "Detroit",
                  abbr: "DET",
                  id: "575c19b7-4052-41c2-9f0a-1c5813d02f99",
                  away_loss: 1,
                  away_win: 2,
                  elimination_number: 0,
                  games_back: 0,
                  home_loss: 0,
                  home_win: 0,
                  last_10_lost: 1,
                  last_10_won: 2,
                  loss: 1,
                  streak: "W2",
                  wild_card_back: "0.0",
                  win: 2,
                  win_p: 0.667,
                  c_loss: 0,
                  c_win: 0,
                  nl_loss: 1,
                  nl_win: 2,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 0,
                  rank: {
                    division: 2,
                    league: 4,
                  },
                },
                {
                  name: "White Sox",
                  market: "Chicago",
                  abbr: "CWS",
                  id: "47f490cd-2f58-4ef7-9dfd-2ad6ba6c1ae8",
                  away_loss: 0,
                  away_win: 0,
                  elimination_number: 57,
                  games_back: 0.5,
                  home_loss: 1,
                  home_win: 1,
                  last_10_lost: 1,
                  last_10_won: 1,
                  loss: 1,
                  streak: "W1",
                  wild_card_back: "0.5",
                  win: 1,
                  win_p: 0.5,
                  c_loss: 1,
                  c_win: 1,
                  nl_loss: 0,
                  nl_win: 0,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 57,
                  rank: {
                    division: 3,
                    league: 7,
                  },
                },
                {
                  name: "Twins",
                  market: "Minnesota",
                  abbr: "MIN",
                  id: "aa34e0ed-f342-4ec6-b774-c79b47b60e2d",
                  away_loss: 1,
                  away_win: 1,
                  elimination_number: 57,
                  games_back: 0.5,
                  home_loss: 0,
                  home_win: 0,
                  last_10_lost: 1,
                  last_10_won: 1,
                  loss: 1,
                  streak: "L1",
                  wild_card_back: "0.5",
                  win: 1,
                  win_p: 0.5,
                  c_loss: 1,
                  c_win: 1,
                  nl_loss: 0,
                  nl_win: 0,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 57,
                  rank: {
                    division: 4,
                    league: 9,
                  },
                },
                {
                  name: "Royals",
                  market: "Kansas City",
                  abbr: "KC",
                  id: "833a51a9-0d84-410f-bd77-da08c3e5e26e",
                  away_loss: 2,
                  away_win: 1,
                  elimination_number: 56,
                  games_back: 1.0,
                  home_loss: 0,
                  home_win: 0,
                  last_10_lost: 2,
                  last_10_won: 1,
                  loss: 2,
                  streak: "L1",
                  wild_card_back: "1.0",
                  win: 1,
                  win_p: 0.333,
                  c_loss: 2,
                  c_win: 1,
                  nl_loss: 0,
                  nl_win: 0,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 56,
                  rank: {
                    division: 5,
                    league: 13,
                  },
                },
              ],
            },
            {
              alias: "W",
              name: "West",
              id: "59d3a9b1-30a8-4a6a-b4e6-0fe63257d01b",
              teams: [
                {
                  name: "Astros",
                  market: "Houston",
                  abbr: "HOU",
                  id: "eb21dadd-8f10-4095-8bf3-dfb3b779f107",
                  away_loss: 0,
                  away_win: 0,
                  elimination_number: 0,
                  games_back: 0,
                  home_loss: 0,
                  home_win: 2,
                  last_10_lost: 0,
                  last_10_won: 2,
                  loss: 0,
                  streak: "W2",
                  wild_card_back: "0.0",
                  win: 2,
                  win_p: 1.0,
                  c_loss: 0,
                  c_win: 0,
                  nl_loss: 0,
                  nl_win: 0,
                  w_loss: 0,
                  w_win: 2,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 0,
                  rank: {
                    division: 1,
                    league: 1,
                  },
                },
                {
                  name: "Angels",
                  market: "Los Angeles",
                  abbr: "LAA",
                  id: "4f735188-37c8-473d-ae32-1f7e34ccf892",
                  away_loss: 1,
                  away_win: 1,
                  elimination_number: 57,
                  games_back: 1.0,
                  home_loss: 0,
                  home_win: 0,
                  last_10_lost: 1,
                  last_10_won: 1,
                  loss: 1,
                  streak: "W1",
                  wild_card_back: "0.5",
                  win: 1,
                  win_p: 0.5,
                  c_loss: 0,
                  c_win: 0,
                  nl_loss: 0,
                  nl_win: 0,
                  w_loss: 1,
                  w_win: 1,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 57,
                  rank: {
                    division: 2,
                    league: 8,
                  },
                },
                {
                  name: "Athletics",
                  market: "Oakland",
                  abbr: "OAK",
                  id: "27a59d3b-ff7c-48ea-b016-4798f560f5e1",
                  away_loss: 0,
                  away_win: 0,
                  elimination_number: 57,
                  games_back: 1.0,
                  home_loss: 1,
                  home_win: 1,
                  last_10_lost: 1,
                  last_10_won: 1,
                  loss: 1,
                  streak: "L1",
                  wild_card_back: "0.5",
                  win: 1,
                  win_p: 0.5,
                  c_loss: 0,
                  c_win: 0,
                  nl_loss: 0,
                  nl_win: 0,
                  w_loss: 1,
                  w_win: 1,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 57,
                  rank: {
                    division: 3,
                    league: 10,
                  },
                },
                {
                  name: "Rangers",
                  market: "Texas",
                  abbr: "TEX",
                  id: "d99f919b-1534-4516-8e8a-9cd106c6d8cd",
                  away_loss: 0,
                  away_win: 0,
                  elimination_number: 57,
                  games_back: 1.0,
                  home_loss: 1,
                  home_win: 1,
                  last_10_lost: 1,
                  last_10_won: 1,
                  loss: 1,
                  streak: "L1",
                  wild_card_back: "0.5",
                  win: 1,
                  win_p: 0.5,
                  c_loss: 0,
                  c_win: 0,
                  nl_loss: 1,
                  nl_win: 1,
                  w_loss: 0,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 57,
                  rank: {
                    division: 4,
                    league: 11,
                  },
                },
                {
                  name: "Mariners",
                  market: "Seattle",
                  abbr: "SEA",
                  id: "43a39081-52b4-4f93-ad29-da7f329ea960",
                  away_loss: 2,
                  away_win: 0,
                  elimination_number: 56,
                  games_back: 2.0,
                  home_loss: 0,
                  home_win: 0,
                  last_10_lost: 2,
                  last_10_won: 0,
                  loss: 2,
                  streak: "L2",
                  wild_card_back: "1.5",
                  win: 0,
                  win_p: 0.0,
                  c_loss: 0,
                  c_win: 0,
                  nl_loss: 0,
                  nl_win: 0,
                  w_loss: 2,
                  w_win: 0,
                  e_loss: 0,
                  e_win: 0,
                  division_elimination_number: 56,
                  rank: {
                    division: 5,
                    league: 15,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  },
  _comment:
    "Generation started @ 2020-07-26 21:18:30 UTC ended @ 2020-07-26 21:18:30 UTC",
}
